import React from "react";
import { Grid, Typography } from "@mui/material";
import ErrorIcon from "../assets/error.png";

const ErrorComponent = () => {
  return (
    <Grid container direction="column" width="50vw">
      <Grid item display="flex" alignItems="center">
        <img src={ErrorIcon} alt="" style={{ marginRight: "4px" }} />
        <Typography
          variant="body2"
          sx={{ color: "#fff", fontWeight: 700, fontSize: "14px" }}
        >
          UNABLE TO PUBLISH DUE TO MISSING FIELDS
        </Typography>
      </Grid>
      <Grid
        item
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#A7A7A7",
            fontWeight: 400,
            fontSize: "14px",
            alignItems: "center",
          }}
        >
          Please fill in the required fields marked with an asterisk
          <span style={{ color: "#E74338", margin: "0 4px" }}>*</span> under
          error sign
          <img src={ErrorIcon} alt="" style={{ marginLeft: "4px" }} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ErrorComponent;
