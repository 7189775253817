/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useCallback } from "react";
import JSONPretty from "react-json-pretty";
import {
  AppBar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
  Grid,
  MenuItem,
  Collapse,
  Autocomplete,
  Divider,
  Modal,
  Chip,
  Snackbar,
  CircularProgress,
  FormLabel,
} from "@mui/material";
import AddLinkIcon from "@mui/icons-material/AddLink";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import SaveIcon from "@mui/icons-material/Save";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";

import { FirebaseApi, storage } from "../../config/firebase-config";

import { MentionsInput, Mention } from "react-mentions";
// import defaultMentionsStyle from "../../Recipes-Old/reactMentionsStyles";

import {
  useForm,
  useController,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import ImagesDropzone from "./images-drop-zone-old";
import ImageElement from "./image-element-old";
import { useParams, useNavigate, useLocation, Form } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Check, CheckBox } from "@mui/icons-material";
import useEntity from "../entities/use-entity";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SwitchAccessShortcutIcon from "@mui/icons-material/SwitchAccessShortcut";
import mentionsStyle from "./mention-input-styles-old";
import { useAuth } from "../auth/auth-context";

import { uuid } from "uuidv4";
import ReactJson from "react-json-view";

import winkNLP from "wink-nlp";
import model from "wink-eng-lite-web-model";
import { set } from "date-fns";
import { fi, tr } from "date-fns/locale";
const nlp = winkNLP(model, ["sbd"]);

let renderCount = 0;

// import {
//     ref,
//     uploadBytesResumable,
//     // getDownloadURL
// } from "firebase/storage";
// End of imports

// generate a human readable string from a timestamp to auto-generate a recipe name
const generateRecipeName = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `Awesome Recipe ${month}-${day}-${year}-${hours}-${minutes}`;
};

function SemiScreens(props) {
  const {
    instruction,
    targetTemperature,
    control,
    childName,
    parentName,
    howToStepFormValues,
  } = props;
  // useFieldArray
  const { fields, append, remove, name } = useFieldArray({
    defaultValues: [
      {
        "STEP-ID": "1",
        "INFO-TXT": howToStepFormValues ? howToStepFormValues.title : "",
        TEMP: 0,
      },
    ],
    control,
    name: `${parentName}.${childName}`,
  });

  const semiStepFormValues = useWatch({
    control,
    name: `${parentName}.${childName}`,
  });
  return (
    <Box padding={2}>
      {fields.map((semiStep, semiStepIndex) => {
        return (
          <SemiScreen
            key={semiStep.id}
            howToStepFormValues={howToStepFormValues}
            instruction={semiStep["INFO-TXT"]}
            targetTemperature={semiStep["TEMP"]}
            control={control}
            childName={`${childName}[${semiStepIndex}]`}
            parentName={parentName}
          />
        );
      })}
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          append({
            "STEP-ID": "1",
            "INFO-TXT": "This is a test",
            TEMP: 0,
          })
        }
      >
        Add Semi Screen
      </Button>
    </Box>
  );
}

function SemiScreen(props) {
  // const { control, childName} = props
  const borderHeight = 20;
  const screenHeight = 150;
  const screenWidth = screenHeight * 1.5;
  const peekingScreenWidth = 20;
  const screenSpacing = 10;
  const getBackgroundColorByScreenType = (instruction) => {};

  const {
    selectedActions,
    targetTemperature,
    control,
    childName,
    parentName,
    debugMode,
  } = props;

  // console.log(parentName, childName);
  // useFieldArray
  const { fields, append, remove, name, replace, update, setValue } =
    useFieldArray({
      defaultValues: [
        {
          "STEP-ID": "1",
          "INFO-TXT": "",
          TEMP: 0,
        },
      ],
      control,
      name: `${parentName}.${childName}`,
    });

  const semiStepFormValues = useWatch({
    control,
    name: `${parentName}.${childName}`,
  });

  const howToStepFormValues = useWatch({
    control,
    name: `${parentName}`,
  });

  // When a new action in recognized we find the sentence in which the action is identified and use that on the semi screen.
  // Regex match for a word and them use the sentence that contains that word
  // Pass a string a substring and return the sentence that contains the substring
  const getSentenceFromText = (text, substring) => {
    let selectedSentence = "";
    let sentences = text.split(".");
    sentences.every((sentence) => {
      if (sentence.includes(substring)) {
        selectedSentence = sentence;
        return false;
      }
      return true;
    });
    return selectedSentence;
  };

  // Update the fields based on the actions identified in the parent form
  // useEffect(() => {
  //     // Get the list of actions identified in the parent form and compare it to the current list of semi screens
  //     // If the number of actions is greater than the number of semi screens we need to add a new semi screen
  //     // Not cathing the removing of actions yet

  //     // console.log(howtToStepDescription)
  //     // console.log("Updating semi step based on instruction actions idenitified")
  //     // console.log(selectedActions)

  //     if (howToStepFormValues?.title && howToStepFormValues.selectedActions.length > 0) {
  //         let howtToStepDescription = howToStepFormValues.title
  //         let uniqueActions = [...new Set(howToStepFormValues.selectedActions)]
  //         let semiScreens = uniqueActions.map((action, index) => {
  //             let sentence = getSentenceFromText(howtToStepDescription, action)
  //             console.log(sentence)
  //             if(action == "Add"){
  //                 return {
  //                     "STEP-ID": 1,
  //                     "INFO-TXT": sentence,
  //                     "TEMP": howToStepFormValues ? howToStepFormValues.cookingParameters.temperature : 0
  //                 }
  //             }else if(action == "Saute"){
  //                 return {
  //                     "STEP-ID": 1,
  //                     "INFO-TXT": sentence,
  //                     "TEMP": howToStepFormValues ? howToStepFormValues.cookingParameters.temperature : 0
  //                 }
  //             }
  //             else if(action == "Remove"){
  //                 return {
  //                     "STEP-ID": 1,
  //                     "INFO-TXT": sentence,
  //                     "TEMP": howToStepFormValues ? howToStepFormValues.cookingParameters.temperature : 0
  //                 }
  //             }else{
  //                 return {
  //                     "STEP-ID": 1,
  //                     "INFO-TXT": sentence,
  //                     "TEMP": howToStepFormValues ? howToStepFormValues.cookingParameters.temperature : 0
  //                 }
  //             }
  //         })
  //         console.log(semiScreens)
  //         // setValue(`${parentName}.${childName}`, semiScreens)
  //         replace(semiScreens)
  //     }

  //     return () => {
  //     }
  // }, [howToStepFormValues.title])

  const [screenType, setScreenType] = useState(1);
  const handleSelectScreenType = (event) => {
    setScreenType(event.target.value);
  };

  return (
    <Box padding={2}>
      <Box padding={2}>
        <Select
          style={{ width: 200 }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          value={screenType}
          onChange={handleSelectScreenType}
        >
          <MenuItem value={1}>INFO</MenuItem>
          <MenuItem value={3}>DISPENSE SINGLE</MenuItem>
          <MenuItem value={4}>DISPENSE MULTI</MenuItem>
          <MenuItem value={5}>PRESET</MenuItem>
          <MenuItem value={6}>TITLE</MenuItem>
        </Select>
        <Button
          variant="text"
          color="primary"
          onClick={() =>
            append({
              "STEP-ID": "1",
              "INFO-TXT":
                "This is a sample semi screen on which I can fit upto 60 char",
              TEMP: 0,
            })
          }
        >
          Add Semi Screen
        </Button>
      </Box>
      <Box padding={2}>
        {fields.map((semiStep, semiStepIndex) => {
          console.log(`${parentName}.${childName}.${semiStepIndex}.INFO-TXT`);
          return (
            <Grid container spacing={2} key={semiStep.id}>
              <Grid item xs={6}>
                <Box
                  style={{
                    width: screenWidth + screenSpacing * 2 + peekingScreenWidth,
                    height: screenHeight + 2 * borderHeight,
                    background: "white",
                    border: "1px solid #8A8A8A",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} style={{ height: borderHeight }}></Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        height: screenHeight,
                        width: peekingScreenWidth + screenWidth + 20,
                      }}
                    >
                      <Grid
                        container
                        justifyContent={"space-between"}
                        style={{
                          height: screenHeight,
                          width: peekingScreenWidth + screenWidth + 20,
                        }}
                      >
                        <Grid
                          item
                          style={{
                            width: peekingScreenWidth,
                            height: screenHeight,
                            background: "#D9D9D9",
                            borderTopRightRadius: 4,
                            borderBottomRightRadius: 4,
                          }}
                        ></Grid>
                        <Grid
                          item
                          style={{
                            width: screenWidth - screenSpacing * 2,
                            height: screenHeight,
                            borderRadius: 4,
                            background: "#FF5A3C",
                          }}
                        >
                          <Box style={{ verticalAlign: "center" }}>
                            <ModifiedTextField
                              style={{ fontSize: 4, color: "white" }}
                              control={control}
                              // placeholder={howToStepFormValues.title}
                              name={`${parentName}.${childName}.${semiStepIndex}.INFO-TXT`}
                              variant="outlined"
                              color="secondary"
                              size="small"
                              fullWidth
                              multiline
                              rows={4}
                            />
                          </Box>
                          <Box padding={2}>
                            <Typography variant="caption" color="white">
                              #characters:
                              {semiStepFormValues[semiStepIndex]?.["INFO-TXT"]
                                ? semiStepFormValues[semiStepIndex]["INFO-TXT"]
                                    .length
                                : 0}{" "}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          style={{
                            width: peekingScreenWidth,
                            borderTopLeftRadius: 4,
                            borderBottomLeftRadius: 4,
                            height: screenHeight,
                            background: "#D9D9D9",
                          }}
                        ></Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ height: borderHeight }}>
                      <Grid
                        container
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Grid item>
                          <ArrowLeftIcon />
                        </Grid>
                        <Grid item style={{ textAlign: "center" }}>
                          <Typography variant="button" color="initial">
                            {semiStep["TEMP"] ? semiStep["TEMP"] : 0} °C
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ArrowRightIcon />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <ModifiedSelect
                  control={control}
                  name={`${parentName}.${childName}.${semiStepIndex}.STEP-ID`}
                  label="Step ID"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  fullWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>INFO</MenuItem>
                  <MenuItem value={3}>DISPENSE SINGLE</MenuItem>
                  <MenuItem value={4}>DISPENSE MULTI</MenuItem>
                  <MenuItem value={5}>PRESET</MenuItem>
                  <MenuItem value={6}>TITLE</MenuItem>
                </ModifiedSelect>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => remove(semiStepIndex)}
                >
                  Remove Semi Screen
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
}

function ModifiedMentionsInput(props) {
  const {
    currentIndex,
    actions,
    ingredients,
    selectedActions,
    setSelectedActions,
    selectedIngredients,
    setSelectedIngredients,
    rows,
    selectedModeSets,
    setSelectedModeSets,
    modeSets,
    setDisplayTitle,
  } = props;

  // console.log(props, "ModifiedMentionsInput");

  const { control, name, mentions } = props;
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  const actionsForMentions = actions.map((action) => ({
    id: action.name,
    display: action.name,
  }));

  const ingredientForMentions = ingredients.map((ingredient) => ({
    id: ingredient.name,
    display: ingredient.name,
  }));

  const modeSetsForMentions = modeSets.map((modeSet) => ({
    id: modeSet.name,
    display: modeSet.name,
  }));
  // console.log(selectedActions)
  // console.log(selectedIngredients);

  const heightByRows = rows * 20;

  const handleMentionsInputChange = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    // Loop through the mentions and see if they are actions or ingredients or modeSets

    let ingredientNames = ingredients.map((ing) => ing.name);
    let actionNames = actions.map((act) => act.name);
    let modeSetNames = modeSets.map((modeSet) => modeSet.name);

    let selectedActions = [];
    let selectionIngredients = [];
    let selectedModeSets = [];

    mentions.forEach((mention) => {
      if (modeSetNames.includes(mention.display)) {
        selectedModeSets.push(mention.display);
      }
      if (ingredientNames.includes(mention.display)) {
        selectionIngredients.push(mention.display);
      }
      if (actionNames.includes(mention.display)) {
        selectedActions.push(mention.display);
      }
    });

    setSelectedIngredients(selectionIngredients, currentIndex);
    setSelectedActions(selectedActions, currentIndex);
    setSelectedModeSets(selectedModeSets, currentIndex);

    field.onChange(newValue);
    setDisplayTitle(newPlainTextValue, currentIndex);
  };

  return (
    <MentionsInput
      value={field.value}
      onBlur={field.onBlur}
      onChange={handleMentionsInputChange}
      inputRef={field.ref}
      name={field.name}
      style={mentionsStyle}
    >
      <Mention
        trigger="/"
        data={actionsForMentions}
        // onAdd={onAddAction}
        appendSpaceOnAdd
        renderSuggestion={(suggestion, search, highlightedDisplay) => (
          <div style={{ padding: 2 }}>{highlightedDisplay}</div>
        )}
      />
      <Mention
        trigger="@"
        data={ingredientForMentions}
        // onAdd={onAddIngredient}
        renderSuggestion={(suggestion, search, highlightedDisplay) => (
          <div>{highlightedDisplay}</div>
        )}
      />
      <Mention
        trigger="#"
        data={modeSetsForMentions}
        // onAdd={onAddModeSet}
        renderSuggestion={(suggestion, search, highlightedDisplay) => (
          <div>{highlightedDisplay}</div>
        )}
      />
    </MentionsInput>
  );
}

// function ModifiedAutoComplete(props) {
//   const units = ["GMS", "KGS", "L", "ML"];
//   const { control, name } = props;
//   const {
//     field,
//     fieldState: { invalid, isTouched, isDirty },
//     formState: { touchedFields, dirtyFields },
//   } = useController({
//     name,
//     control,
//     rules: { required: true },
//   });
//   return (
//     <Autocomplete
//       size={props.size ? props.size : "small"}
//       disablePortal
//       label={field.name}
//       id={field.name}
//       onChange={(evt, newValue) => field.onChange(newValue)}
//       onBlur={field.onBlur}
//       // onChange={field.onChange}
//       name={field.name}
//       inputRef={field.ref}
//       freeSolo
//       options={units.map((unit) => unit)}
//       renderInput={(params) => <TextField {...params} fullWidth label="" />}
//     />
//   );
// }

function ModifiedAutoCompleteWithOptions(props) {
  const { control, name, options, multiple } = props;
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });
  // console.log("options", options);
  return (
    <Autocomplete
      size={props.size ? props.size : "small"}
      multiple={multiple ? multiple : false}
      disablePortal
      label={field.name}
      id={field.name}
      onChange={(evt, newValue) => field.onChange(newValue)}
      onBlur={field.onBlur}
      // onChange={field.onChange}
      name={field.name}
      ref={field.ref}
      freeSolo
      value={field.value}
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} fullWidth label="" />}
    />
  );
}

function ModifiedAutoCompleteWithOptionsObject(props) {
  const { control, name, options, multiple } = props;
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <Autocomplete
      size={props.size ? props.size : "small"}
      multiple={multiple ? multiple : false}
      disablePortal
      label={field.name}
      id={field.name}
      onChange={(evt, newValue) => field.onChange(newValue)}
      onBlur={field.onBlur}
      // onChange={field.onChange}
      name={field.name}
      ref={field.ref}
      freeSolo
      value={field.value}
      options={options.map((option) => option.name)}
      renderInput={(params) => <TextField {...params} fullWidth label="" />}
    />
  );
}

function ModifiedTextField(props) {
  const { control, name, placeholder, label } = props;
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <TextField
      label={label}
      size={props.size ? props.size : "small"}
      fullWidth={props.fullWidth}
      multiline={props.multiline}
      rows={props.rows}
      style={props.style}
      placeholder={placeholder}
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
    />
  );
}

function ModifiedSelect(props) {
  const { name, control } = props;
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });
  // console.log("fieldss",fields)
  return (
    <Select
      size={props.size ? props.size : "small"}
      fullWidth
      labelId={name}
      id={name}
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      style={{ minWidth: 120, ...props.style }}
    >
      {props.children}
    </Select>
  );
}
function ModifiedSelectForPod(props) {
  const { name, control, multiple } = props;
  console.log("props", props);
  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <Select
      size={props.size ? props.size : "small"}
      multiple={multiple}
      fullWidth
      labelId={name}
      id={name}
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={Array.isArray(field.value) ? field.value : []} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      style={{ minWidth: 120, ...props.style }}
    >
      {props.children}
    </Select>
  );
}

function IngredientRowInput(props) {
  const { name, control } = props;
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  // console.log("fields",fields)
  useEffect(() => {
    if (props.arraySize > fields.length) {
      let newRowCount = props.arraySize - fields.length;
      for (let i = newRowCount; i > 0; i--) {
        append({
          id: "",
          ingredientType: "",
          quantity: "",
          ingredient: "",
          prepStyle: "",
          unit: "",
          schedulingLimit: "",
          isOutput: false,
          isRecipeGenerated: false,
          isUserGenerated: false,
          loadingPosition: "",
          podType: "",
          podPosition: [],
          categoryInContextOfRiku: "",
        });
      }
    }
    return () => {};
  }, [props.arraySize]);

  // When the ingredient is selected in the IngredientRowInput, this function will be called
  // and it will update the ingredientId in the IngredientRowInput
  // Update the form with the ingredientId

  return (
    <Grid container spacing={0.5}>
      {fields.map((item, index) => {
        return (
          <Grid key={item.id} item xs={12}>
            <Grid container spacing={1} key={index}>
              <Grid item xs={0.75}>
                <ModifiedTextField
                  size={"small"}
                  control={control}
                  name={`recipeIngredients.${index}.quantity`}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={1.25}>
                <ModifiedAutoCompleteWithOptionsObject
                  options={props.units}
                  control={control}
                  size={"small"}
                  name={`recipeIngredients.${index}.units`}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={2}>
                <ModifiedAutoCompleteWithOptionsObject
                  size={"small"}
                  options={props.ingredientsFromDb}
                  control={control}
                  name={`recipeIngredients.${index}.name`}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={1.25}>
                <ModifiedAutoCompleteWithOptionsObject
                  size={"small"}
                  options={props.prepStyles}
                  control={control}
                  name={`recipeIngredients.${index}.prepStyle`}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={1.25}>
                <ModifiedSelect
                  style={{ minWidth: 120 }}
                  size={"small"}
                  control={control}
                  name={`recipeIngredients.${index}.loadingPosition`}
                  label="Derived"
                >
                  <MenuItem value={""}>N/A</MenuItem>
                  <MenuItem value={"spiceRack"}>Spice Rack</MenuItem>
                  <MenuItem value={"ingredientRack"}>Ingredient Rack</MenuItem>
                  <MenuItem value={"liquidRack"}>Liquid Rack</MenuItem>
                </ModifiedSelect>
              </Grid>
              <Grid item xs={1.25}>
                <ModifiedSelect
                  style={{ minWidth: 120 }}
                  size={"small"}
                  control={control}
                  name={`recipeIngredients.${index}.categoryInContextOfRiku`}
                  label="Derived"
                >
                  <MenuItem value="">select</MenuItem>
                  {/* <MenuItem value="oil">Oil</MenuItem>
                  <MenuItem value="water">Water</MenuItem> */}
                  <MenuItem value="spiceOrHerb">Spice/Herb</MenuItem>
                  <MenuItem value="sauce">Sauce Ingredient</MenuItem>
                  <MenuItem value="flavoring">Flavor</MenuItem>
                  <MenuItem value="main">Main Ingredient</MenuItem>
                  <MenuItem value="seasoning">Seasoning</MenuItem>
                  <MenuItem value="garnish">Garnish</MenuItem>
                </ModifiedSelect>
              </Grid>
              {/* TODO: Pod Type should be filtered based on the loadingPosition */}
              <Grid item xs={1.25}>
                <ModifiedSelect
                  style={{ minWidth: 120 }}
                  size={"small"}
                  control={control}
                  name={`recipeIngredients.${index}.podType`}
                  label="Derived"
                >
                  <MenuItem value={""}>N/A</MenuItem>
                  <MenuItem value={"powderedSpicePod"}>
                    Powdered Spices Pod
                  </MenuItem>
                  <MenuItem value={"pelletSpicePod"}>
                    Pellets Spice Pod
                  </MenuItem>
                  <MenuItem value={"solidIngredientPod"}>
                    Solid ing. Pod
                  </MenuItem>
                  <MenuItem value={"pasteIngredientPod"}>
                    Paste ing. Pod
                  </MenuItem>
                  <MenuItem value={"oilPod"}>Oil Pod</MenuItem>
                  <MenuItem value={"waterPod"}>Water Pod</MenuItem>
                </ModifiedSelect>
              </Grid>

              <Grid item xs={0.75}>
                <ModifiedSelectForPod
                  multiple={true}
                  style={{ minWidth: 60 }}
                  size={"small"}
                  control={control}
                  name={`recipeIngredients.${index}.podPosition`}
                  label="Derived"
                >
                  {["N/A", 1, 2, 3, 4, 5, 6, 7].map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </ModifiedSelectForPod>
              </Grid>
              <Grid item xs={0.75}>
                <ModifiedTextField
                  size={"small"}
                  control={control}
                  name={`recipeIngredients.${index}.schedulingLimit`}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={0.75}>
                <ModifiedSelect
                  style={{ minWidth: 75 }}
                  size={"small"}
                  control={control}
                  name={`recipeIngredients.${index}.isOutput`}
                  label="Derived"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </ModifiedSelect>
              </Grid>

              <Grid item xs={0.5}>
                <IconButton
                  size="small"
                  onClick={() => remove(index)}
                  style={{ marginLeft: 5 }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

function InstructionRowInput(props) {
  const {
    control,
    parentName,
    parentIndex,
    childName,
    openModalToSelectImage,
  } = props;
  const fieldArrayValues = useWatch({
    control,
    name: `${parentName}.${parentIndex}.${childName}`,
  });
  const { fields, append, remove, name } = useFieldArray({
    defaultValues: {
      action: "",
      heatLevel: "",
      title: "",
      tip: "",
      stepUrl: "",
    },
    control,
    name: `${parentName}.${parentIndex}.${childName}`,
  });

  // useEffect(() => {
  //     if(props.arraySize > fields.length){
  //     let newRowCount = props.arraySize - fields.length
  //     for (let i = newRowCount; i > 0; i--) {
  //         append({act: "", units: "", ingredient: "",  prepStyle: "", })
  //     }
  //     }
  //     return () => {
  //     }
  // },[props.arraySize])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {fields.map((howToStep, howToStepIndex) => {
          return (
            <Grid
              key={howToStep.id}
              container
              spacing={2}
              style={{
                background: "#e9e9e9",
                padding: 5,
                borderRadius: 4,
                marginTop: 10,
              }}
            >
              <Grid item xs={0.5}>
                {howToStepIndex + 1}
              </Grid>
              <Grid item xs={10}>
                {/* <ModifiedTextField multiline={true} rows={4} control={control} name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.title`} fullWidth={true} /> */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ModifiedMentionsInput
                          multiline={true}
                          ModifiedTextField
                          rows={4}
                          control={control}
                          name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.title`}
                          fullWidth={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container style={{ marginLeft: -10 }}>
                      <Grid item xs={2}>
                        <FormControl
                          fullWidth
                          sx={{ m: 1, minWidth: 150 }}
                          size="small"
                        >
                          <InputLabel id="heatLevel">Heat</InputLabel>
                          <ModifiedSelect
                            control={control}
                            name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.heatLevel`}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="high">High</MenuItem>
                            <MenuItem value="medium">Medium</MenuItem>
                            <MenuItem value="low">Low</MenuItem>
                          </ModifiedSelect>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl
                          fullWidth
                          sx={{ m: 1, minWidth: 150 }}
                          size="small"
                        >
                          <ModifiedTextField
                            control={control}
                            name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.duration`}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl
                          fullWidth
                          sx={{ m: 1, minWidth: 150 }}
                          size="small"
                        >
                          <ModifiedTextField
                            control={control}
                            name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.tip`}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={1}>
                {fieldArrayValues[howToStepIndex] &&
                fieldArrayValues[howToStepIndex].stepUrl ? (
                  <img
                    src={fieldArrayValues[howToStepIndex].stepUrl}
                    control={control}
                    name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.stepUrl`}
                    style={{
                      display: "block",
                      maxHeight: 100,
                    }}
                  />
                ) : (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() =>
                      openModalToSelectImage(
                        `${parentName}.${parentIndex}.${childName}.${howToStepIndex}.stepUrl`
                      )
                    }
                  >
                    Select Image
                  </Button>
                )}
              </Grid>
              <Grid item xs={0.5}>
                <IconButton size="small" onClick={() => remove(howToStepIndex)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() =>
            append({ title: "", action: "", stepUrl: null, tip: "" })
          }
        >
          Add Instruction
        </Button>
      </Grid>
    </Grid>
  );
}

// Form Structure
// Recipe {}
// - Ingredients <FieldArray>
//    - Ingredient {}
// - Sections <FieldArray>
//    - Section {}
//      - Instructions <FieldArray>
//         - Instruction {}
//             - SemiScreens <FieldArray>
//               - SemiScreen {}

function InstructionRowInputMobile(props) {
  // Will show the instruction row in mobile view format
  // The instruction title will be captured using mentions input
  // Instructions and ingredients for the step will be selected either by autocomplete on a '/' or a '@' keypress
  // Mentioned Actions will collected in a mentionedActions array
  // Mentioned Ingredients will be collected in a mentionedIngredients array
  // Semi Screen will autogenerate on the basis of the mentioned actions and ingredients
  // Each actions will be a seperate semi screen
  // The title for the semi screen will be the based on splitting the sentence of the mentionTitle based on the mentioned action and the sentense completion criteria in the current case a period '.'
  // We will use a generate semi screens button which when clicked will generate the semi screens based on the mentioned actions and ingredients

  // If any of the mentioned actions is a heating Preset then the heat level will be set to based on the preset and the duration will be set to the preset duration if the duration is not already set

  // The instruction can have a derived ingredient which will be then added into the ingredientsInRecipe array

  // Based on the preset auto assign the image to the step as a default option.

  // TODO
  // Isloate the text fields to only be pure
  // Bring all the business logic into this component only.

  // const [mentionsInputActive, setMentionsInputActive] = useState(true);

  // const [mentionsInputActive, setMentionsInputActive] = useState(true);

  const {
    control,
    setValue,
    parentName,
    parentIndex,
    childName,
    openModalToSelectImage,
    sectionTitle,
    recipeName,
    cookingActions,
    ingredientsInRecipe,
    modeSets,
    debugMode,
  } = props;

  const actionsForMentions = cookingActions.map((action) => ({
    id: action.name,
    display: action.name,
  }));

  const ingredientForMentions = ingredientsInRecipe.map((ingredient) => ({
    id: ingredient.name,
    display: ingredient.name,
  }));

  const { fields, append, remove, replace, name } = useFieldArray({
    defaultValues: {
      "@type": "HowToStep",
      cookingParameters: {
        action: "",
        controlActionType: "",
        controlType: "",
        duration: "",
        temperature: "",
        modeSet: "",
        isLidOpen: false,
        stirring: "",
        power: "",
        heatLevel: "",
        powerLevel: "",
      },
      title: "",
      mentionsTitle: "",
      tip: "",
      stepUrl: "",
      semiSteps: [{ "STEP-ID": 1, "INFO-TEXT": "", TEMP: 0 }],
      ingredients: [],
      selectedActions: [],
      selectedIngredients: [],
      action: "",
      modeSet: "",
      mentionsInputActive: true,
    },
    control,
    name: `${parentName}.${parentIndex}.${childName}`,
  });

  const howToStepFormValues = useWatch({
    control,
    name: `${parentName}.${parentIndex}.${childName}`,
    defaultValue: fields,
  });

  // const [selectedIngredients, setSelectedIngredients] = useState([])
  // const [selectedActions, setSelectedActions] = useState([])
  // update mentionsTitle if the mentions title.

  const setSelectedIngredients = (selectedIngredients, index) => {
    setValue(
      `${parentName}.${parentIndex}.${childName}.${index}.selectedIngredients`,
      selectedIngredients
    );

    let ingredients = ingredientsInRecipe.filter((ingredient) =>
      selectedIngredients.includes(ingredient.name)
    );
    setValue(
      `${parentName}.${parentIndex}.${childName}.${index}.ingredients`,
      ingredients
    );
  };

  const setSelectedActions = (selectedActions, currentStepIndex) => {
    // alert(selectedActions)

    setValue(
      `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.selectedActions`,
      selectedActions
    );
    setValue(
      `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.action`,
      selectedActions[0]
    );
    setValue(
      `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.cookingParameters.action`,
      selectedActions[0]
    );
    // Update the power, temperature, duration and modeSet based on the selected action
    let action = cookingActions.find(
      (action) => action.name === selectedActions[0]
    );
    if (action) {
      if ("controlType" in action && action["controlType"]) {
        setValue(
          `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.cookingParameters.controlType`,
          action["controlType"]
        );
      }
      if ("duration" in action && action["duration"]) {
        setValue(
          `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.cookingParameters.duration`,
          action["duration"]
        );
      }
      if ("temperature" in action && action["temperature"]) {
        setValue(
          `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.cookingParameters.temperature`,
          action["temperature"]
        );
      }
      if ("power" in action && action["power"]) {
        setValue(
          `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.cookingParameters.power`,
          action["power"]
        );
      }
      if (
        "controlType" in action &&
        action["controlType"] === "customControl"
      ) {
        setValue(
          `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.cookingParameters.modeSet`,
          action["name"]
        );
      }
    } else {
      setValue(
        `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.cookingParameters.controlType`,
        ""
      );
      setValue(
        `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.cookingParameters.duration`,
        ""
      );
      setValue(
        `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.cookingParameters.temperature`,
        ""
      );
      setValue(
        `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.cookingParameters.power`,
        ""
      );
      setValue(
        `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.cookingParameters.modeSet`,
        ""
      );
    }
  };

  const setSelectedModeSets = (selectedModeSets, currentStepIndex) => {
    // alert(selectedActions)

    setValue(
      `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.selectedModeSets`,
      selectedModeSets
    );
    setValue(
      `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.modeSet`,
      selectedModeSets[0]
    );
  };

  const setDisplayTitle = (displayTitle, currentStepIndex) => {
    // if(!mentionsTitle){
    //     return
    // }
    // let displayTitle = mentionsTitle.replace(/@\[(\w+)\]\((\w+)\)/g, '$1');

    setValue(
      `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.mentionsInputActive`,
      true
    );

    setValue(
      `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.title`,
      displayTitle
    );
  };

  // update the title when the mentions title is updated
  // We will have onChange on the mentionsI
  // const onAddAction = (event, selectedActions, currentIndex) => {
  //     setSelectedActions([...selectedActions, event], currentIndex)
  // }

  const onAddIngredient = (event, selectedIngredients, currentIndex) => {
    setSelectedIngredients([...selectedIngredients, event], currentIndex);
  };

  const [visibleTabIndex, setVisibleTabIndex] = useState(0);
  const [derivedIngredient, setDerivedIngredient] = useState("");
  const addDerivedIngredientToMainList = (event) => {
    // add the derived ingredient to the main list of ingredients
    let ingredient = {
      name: derivedIngredient,
      quantity: 0,
      unit: "",
      isOutput: true,
      isRecipeGenerated: true,
      isUserGenerated: false,
    };
    props.addIngredientToRecipeIngredientsList(ingredient);
  };

  // Will generate a normal string for display in a non mentions input.
  // useEffect(() => {
  //     // extract the title from the mentionsTitle
  //     let title = howToStepFormValues[visibleTabIndex].mentionsTitle
  //     if(title){
  //         let titleWithoutMentions = ""
  //         howToStepFormValues[visibleTabIndex].selectedActions.forEach((action) => {
  //             title = title.replace(`@[${action}](${action})`, action)
  //         })
  //         howToStepFormValues[visibleTabIndex].selectedIngredients.forEach((ingredient) => {
  //             title = title.replace(`@[${ingredient}](${ingredient})`, ingredient)
  //         })
  //         titleWithoutMentions = title
  //         setValue(`${parentName}.${parentIndex}.${childName}.${visibleTabIndex}.title`, titleWithoutMentions)
  //     }
  // }, [howToStepFormValues[visibleTabIndex].mentionsTitle])

  // Will return the sub-string that will go onto the semi screen.
  // const getSentenceFromText = (text, substring) => {
  //   let selectedSentence = "";
  //   let sentences = text.split(".");
  //   sentences.every((sentence) => {
  //     if (sentence.includes(substring)) {
  //       selectedSentence = sentence;
  //       return false;
  //     }
  //     return true;
  //   });
  //   return selectedSentence;
  // };

  // Will generate the semi screens bsaed on the step.title input field
  const generateSemiScreens = (currentStepIndex) => {
    // generate the semi screens based on the mentioned actions and ingredients
    let semiSteps = [];
    let semiStep = {};
    let semiStepIndex = 1;
    let semiStepInfoText = "";
    let semiStepTemp = 0;
    let semiStepDuration = 0;
    let semiStepPower = 0;

    // Split the title into sentences and then find the sentence which contains the action and then use that sentence as the info text
    // if the action is a heat step then set the temp and duration based on the preset and the STEP-ID is set accordingly
    // If add or dispense add with ingredient is found
    // If only one ingredient is found then set the STEP-ID approporiately
    // If more than one ingredient is found then set the STEP-ID approporiately

    const doc = nlp.readDoc(howToStepFormValues[currentStepIndex].title);
    let sentences = doc.sentences().out();
    // let sentences = howToStepFormValues[currentStepIndex].title.split(".")

    sentences.forEach((sentence) => {
      // Find the action
      // Check if the sentence contains the action
      // if(sentence.length == 0) {
      //     return
      // }
      let action = null;
      if (
        howToStepFormValues[currentStepIndex].selectedActions &&
        howToStepFormValues[currentStepIndex].selectedActions.length > 0
      ) {
        action = howToStepFormValues[currentStepIndex].selectedActions.find(
          (action) => sentence.includes(action)
        );
      }
      if (action) {
        let actionObject = cookingActions.find(
          (cookingAction) => cookingAction.name == action
        );
        if (actionObject) {
          if (actionObject.isHeatingPreset) {
            semiStepTemp = actionObject.temperature;
            semiStepDuration = actionObject.duration;
            semiStepPower = actionObject.power;
          }
        }
      }

      semiStep = {
        "STEP-ID": semiStepIndex,
        "INFO-TXT": sentence,
        TEMP: semiStepTemp,
        DURATION: semiStepDuration,
        POWER: semiStepPower,
      };
      semiSteps.push(semiStep);
      semiStepIndex = semiStepIndex + 1;
    });

    // howToStepFormValues[currentStepIndex].selectedActions.forEach((action) => {
    //     let actionObject = cookingActions.find((cookingAction) => cookingAction.name == action)
    //     if(actionObject){
    //         if(actionObject.isHeatingPreset){
    //             semiStepTemp = actionObject.temperature
    //             semiStepDuration = actionObject.duration
    //             semiStepPower = actionObject.power
    //         }
    //         semiStepInfoText = getSentenceFromText(howToStepFormValues[currentStepIndex].title, actionObject.name) + ". "
    //     }

    //     semiStep = {
    //         "STEP-ID": semiStepIndex,
    //         "INFO-TXT": semiStepInfoText,
    //         "TEMP": semiStepTemp,
    //         "DURATION": semiStepDuration,
    //         "POWER": semiStepPower
    //     }
    //     semiSteps.push(semiStep)
    //     semiStepIndex = semiStepIndex + 1
    // })
    // howToStepFormValues[currentStepIndex].selectedIngredients.forEach((ingredient) => {
    //     let ingredientObject = ingredientsInRecipe.find((ingredientInRecipe) => ingredientInRecipe.name == ingredient)
    //     if(ingredientObject){
    //         semiStepInfoText = semiStepInfoText + ingredientObject.name + ". "
    //     }
    // })

    setValue(
      `${parentName}.${parentIndex}.${childName}.${currentStepIndex}.semiSteps`,
      semiSteps
    );
  };

  // Clone a step when the clone button is clicked
  // Add the step to the next index
  const cloneInstruction = (index) => {
    let clonedInstruction = howToStepFormValues[index];
    // clonedSection.title = clonedSection.title
    // clonedSection.id = uuid()
    let newFields = [...howToStepFormValues];
    newFields.splice(index + 1, 0, clonedInstruction);
    setValue(`${parentName}.${parentIndex}.${childName}`, newFields);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          spacing={2}
          style={{
            background: "white",
            borderRadius: 4,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              whiteSpace: "nowrap",
              overflowX: "scroll",
              height: 125,
              width: "70vw",
            }}
          >
            {howToStepFormValues &&
              howToStepFormValues.map((howToStep, howToStepIndex) => {
                return (
                  <Button
                    key={howToStepIndex}
                    variant="outlined"
                    onClick={() => setVisibleTabIndex(howToStepIndex)}
                    style={{
                      background:
                        visibleTabIndex == howToStepIndex ? "green" : "",
                      height: 100,
                      width: 100,
                      display: "inline-block",
                      flexDirection: "row",
                      borderRadius: 4,
                      margin: 5,
                      padding: 0,
                    }}
                  >
                    <img
                      src={
                        howToStep.stepUrl
                          ? howToStep.stepUrl
                          : "https://placehold.co/100x80"
                      }
                      style={{ width: "100%", height: "95%" }}
                    />
                    <Typography width={"100%"} variant="body2" color="initial">
                      Step:{howToStepIndex + 1}
                    </Typography>
                  </Button>
                );
              })}
          </Box>
          <Button
            variant="outlined"
            onClick={() =>
              append({
                title: "",
                selectedActions: [],
                selectedIngredients: [],
                action: "",
                stepUrl: null,
                tip: "",
                mentionsInputActive: true,
              })
            }
            style={{
              height: 100,
              width: 100,
              borderRadius: 4,
              margin: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AddCircleOutlineIcon />
            <Typography variant="body1" color="initial">
              Step
            </Typography>
          </Button>
        </Box>
      </Grid>
      {fields.map((howToStep, howToStepIndex) => {
        return (
          <Box
            key={howToStepIndex}
            padding={2}
            style={{
              display: visibleTabIndex == howToStepIndex ? "flex" : "none",
              background: "#F2F2F2",
              padding: 5,
              flexDirection: "column",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid container>
                  <Box
                    style={{
                      borderRadius: 4,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControl>
                      <FormLabel>Mentions Active ?</FormLabel>
                      <Switch
                        checked={
                          howToStepFormValues[howToStepIndex] &&
                          howToStepFormValues[howToStepIndex]
                            .mentionsInputActive
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setValue(
                            `${parentName}.${parentIndex}.${childName}.${howToStepIndex}.mentionsInputActive`,
                            e.target.checked
                          );
                        }}
                        name="Mention Input Active ?"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid key={howToStep.id} container style={iphone}>
                  <Grid item xs={12} style={{ margin: 0, padding: 0 }}>
                    {howToStepFormValues[howToStepIndex] &&
                    howToStepFormValues[howToStepIndex].stepUrl ? (
                      <img
                        src={howToStepFormValues[howToStepIndex].stepUrl}
                        control={control}
                        name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.stepUrl`}
                        style={{
                          display: "block",
                          width: "100%",
                          height: 200,
                          objectFit: "cover",
                          borderTopLeftRadius: 30,
                          borderTopRightRadius: 30,
                        }}
                      />
                    ) : (
                      <Button
                        style={{ width: "100%", height: 200 }}
                        variant="text"
                        color="primary"
                        onClick={() =>
                          openModalToSelectImage(
                            `${parentName}.${parentIndex}.${childName}.${howToStepIndex}.stepUrl`
                          )
                        }
                      >
                        Select Image
                      </Button>
                    )}
                    <Box
                      padding={2}
                      style={{
                        position: "relative",
                        bottom: 0,
                        background: "#000000",
                        opacity: 0.75,
                        color: "white",
                        height: 50,
                      }}
                    >
                      <Typography variant="body1" color="white">
                        Section {parentIndex + 1}: {sectionTitle}
                      </Typography>
                      <Typography variant="caption" color="white">
                        {recipeName} - Step {howToStepIndex + 1}/
                        {howToStepFormValues.length}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} padding={1}>
                      <Grid item xs={4}>
                        <FormControl fullWidth size="small">
                          <ModifiedTextField
                            control={control}
                            name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.cookingParameters.temperature`}
                            label="Temp(C)"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl fullWidth size="small">
                          <ModifiedTextField
                            control={control}
                            name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.cookingParameters.power`}
                            label="PWR"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl fullWidth size="small">
                          <ModifiedTextField
                            control={control}
                            name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.cookingParameters.duration`}
                            label="Time(Min)"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="heatLevel">Lid</InputLabel>
                          <ModifiedSelect
                            control={control}
                            name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.cookingParameters.isLidOpen`}
                          >
                            <MenuItem value={true}>Open</MenuItem>
                            <MenuItem value={false}>Closed</MenuItem>
                          </ModifiedSelect>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="heatLevel">Stirring</InputLabel>
                          <ModifiedSelect
                            control={control}
                            name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.cookingParameters.stirring`}
                          >
                            <MenuItem value="">
                              <em>Low</em>
                            </MenuItem>
                            <MenuItem value="low">Low</MenuItem>
                            <MenuItem value="medium">Medium</MenuItem>
                            <MenuItem value="high">High</MenuItem>
                            <MenuItem value="reverse">Reverse</MenuItem>
                          </ModifiedSelect>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                  >
                    {howToStepFormValues[howToStepIndex] &&
                    howToStepFormValues[howToStepIndex].mentionsInputActive ? (
                      <ModifiedMentionsInput
                        actions={cookingActions}
                        ingredients={ingredientsInRecipe}
                        modeSets={modeSets}
                        setSelectedModeSets={setSelectedModeSets}
                        selectedActions={
                          howToStepFormValues[howToStepIndex]
                            ? howToStepFormValues[howToStepIndex]
                                .selectedActions
                            : []
                        }
                        selectedIngredients={
                          howToStepFormValues[howToStepIndex]
                            ? howToStepFormValues[howToStepIndex]
                                .selectedIngredients
                            : []
                        }
                        setSelectedActions={setSelectedActions}
                        setSelectedIngredients={setSelectedIngredients}
                        multiline={true}
                        currentIndex={howToStepIndex}
                        rows={3}
                        setValue={setValue}
                        control={control}
                        setDisplayTitle={setDisplayTitle}
                        name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.mentionsTitle`}
                        fullWidth={true}
                      />
                    ) : (
                      <FormControl fullWidth size="small">
                        <ModifiedTextField
                          control={control}
                          multiline={true}
                          rows={2}
                          name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.title`}
                          label="Title"
                          onChange={(e) => {
                            // setDisplayTitle(e.target.value);
                            // setValue(
                            //   `${parentName}.${parentIndex}.${childName}.${howToStepIndex}.mentionsTitle`,
                            //   e.target.value
                            // );
                            setValue(
                              `${parentName}.${parentIndex}.${childName}.${howToStepIndex}.title`,
                              e.target.value
                            );
                          }}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Box style={{height: 5}}>
                                            {   howToStep.selectedIngredients.map((ingredient, index) => {
                                                    return(
                                                        <Chip
                                                            key={index}
                                                            label={ingredient}
                                                            size="small"
                                                        />
                                                    )
                                                }
                                                )
                                            }
                                            </Box> */}
                  </Grid>
                  <Grid item xs={12} padding={2}>
                    <FormControl
                      fullWidth
                      size="small"
                      style={{
                        background: "rgb(255 186 0 / 32%)",
                        color: "black",
                      }}
                    >
                      <ModifiedTextField
                        placeholder="💡 an awesome tip from the creator"
                        control={control}
                        multiline
                        rows={2}
                        name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.tip`}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                {/* Derived Ingredients */}
                <Grid container spacing={2} padding={2}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onClick={() => cloneInstruction(howToStepIndex)}
                    >
                      {" "}
                      <ContentCopyIcon /> Step{" "}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => generateSemiScreens(howToStepIndex)}
                    >
                      <SwitchAccessShortcutIcon /> Semi Screens
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={() => remove(howToStepIndex)}
                    >
                      {" "}
                      <DeleteIcon /> Step{" "}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                  >
                    <Grid container spacing={1} padding={1}>
                      <Grid item xs={12}>
                        <FormControl fullWidth size="small">
                          <FormLabel>Ingredients in Step</FormLabel>
                          <ModifiedAutoCompleteWithOptions
                            multiple={true}
                            options={ingredientsInRecipe}
                            control={control}
                            name={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}.ingredients`}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={10}>
                            <FormControl fullWidth size="small">
                              <FormLabel>Derived Ingredients</FormLabel>
                              <TextField
                                size="small"
                                name="Derived Ingredient"
                                placeholder="Derived Ingredient"
                                value={derivedIngredient}
                                onChange={(e) =>
                                  setDerivedIngredient(e.target.value)
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={addDerivedIngredientToMainList}
                            >
                              + Add
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} padding={2}>
                    <SemiScreen
                      debugMode={debugMode}
                      howToStepFormValues={howToStepFormValues}
                      control={control}
                      parentName={`${parentName}.${parentIndex}.${childName}.${howToStepIndex}`}
                      childName={"semiSteps"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              {debugMode && (
                <ReactJson
                  src={{
                    index: howToStepIndex,
                    formValues: howToStepFormValues[howToStepIndex],
                  }}
                  theme="monokai"
                />
              )}
            </Grid>
          </Box>
        );
      })}
    </Grid>
  );
}

function InstructionCollectionRowInput(props) {
  const {
    control,
    name,
    openModalToSelectImage,
    recipeName,
    cookingActions,
    ingredientsInRecipe,
    setValue,
    modeSets,
    debugMode,
  } = props;
  const { fields, append, remove } = useFieldArray({
    initialValues: [
      {
        title: "",
        sectionType: "",
        itemListElement: [
          {
            "@type": "HowToStep",
            title: "",
            stepImageUrl: "",
            tip: "",
            ingredients: [
              {
                isRecipeGenerated: false,
                isUserGenerated: false,
                isOutput: false,
                name: "",
                id: "",
                quantity: "",
                unit: "",
                ingredient: "",
              },
            ],
            selectedActions: [],
            selectedIngredients: [],
            cookingParameters: {
              stirring: false,
              isLidOpen: false,
              action: "",
              power: "",
              temperature: "",
              weight: "",
              duration: "",
            },
            semiSteps: [],
            tools: [],
            mentionsInputActive: true,
          },
        ],
      },
    ],
    control,
    name,
  });

  const filedArrayValues = useWatch({
    control,
    name,
  });
  // Handle open/close of section
  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);
  const [isSectionOpen, setIsSectionOpen] = React.useState(true);

  const toggleSectionCollapse = (index) => {
    if (selectedSectionIndex == index) {
      setIsSectionOpen(!isSectionOpen);
      return;
    } else {
      setIsSectionOpen(true);
    }
    setSelectedSectionIndex(index);
  };

  // Add new section if array size is increased
  useEffect(() => {
    if (props.arraySize > fields.length) {
      let newRowCount = props.arraySize - fields.length;
      for (let i = newRowCount; i > 0; i--) {
        append({
          title: "",
          sectionType: "",
          itemListElement: [
            {
              "@type": "HowToStep",
              title: "",
              stepImageUrl: "",
              tip: "",
              ingredients: [
                {
                  isRecipeGenerated: false,
                  isUserGenerated: false,
                  isOutput: false,
                  name: "",
                  id: "",
                  quantity: "",
                  unit: "",
                  ingredient: "",
                },
              ],
              selectedActions: [],
              selectedIngredients: [],
              cookingParameters: {
                stirring: false,
                isLidOpen: false,
                action: "",
                power: "",
                temperature: "",
                weight: "",
                duration: "",
              },
              semiSteps: [],
              tools: [],
              mentionsInputActive: true,
            },
          ],
        });
      }
    }
    return () => {};
  }, []);

  // duplicate a section which the clone section button is clicked
  // set the cloned section title to "<title> X 2"
  // set the cloned section id to a new id
  // insert the cloned section after the section which the clone section button is clicked

  const cloneSection = (index) => {
    let clonedSection = filedArrayValues[index];
    // clonedSection.title = clonedSection.title
    // clonedSection.id = uuid()
    let newFields = [...filedArrayValues];
    newFields.splice(index + 1, 0, clonedSection);
    setValue(name, newFields);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {fields.map((recipeInstructionSection, sectionIndex) => {
          return (
            <Grid
              key={recipeInstructionSection.id}
              container
              style={{
                marginTop: 10,
                background: "#F4F4F4",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 4,
                padding: 5,
              }}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  padding={2}
                >
                  <Grid item xs={0.5}>
                    <IconButton
                      size="small"
                      onClick={() => toggleSectionCollapse(sectionIndex)}
                    >
                      {isSectionOpen && sectionIndex == selectedSectionIndex ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </IconButton>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth size="small">
                      <FormLabel>Section Title</FormLabel>
                      <ModifiedTextField
                        fullWidth
                        control={control}
                        name={`${name}.${sectionIndex}.title`}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth size="small">
                      <FormLabel>Section Type</FormLabel>
                      <ModifiedSelect
                        style={{ minWidth: 120 }}
                        size={"small"}
                        control={control}
                        placeholder="section type"
                        name={`${name}.${sectionIndex}.sectionType`}
                        label="Section Type"
                      >
                        <MenuItem value={"manual"}>Manual</MenuItem>
                        <MenuItem value={"hardware"}>Hardware</MenuItem>
                      </ModifiedSelect>
                    </FormControl>
                  </Grid>

                  <Grid item xs={0.5}>
                    <FormControl fullWidth size="small">
                      <IconButton
                        size="small"
                        onClick={() => cloneSection(sectionIndex)}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={0.5}>
                    <FormControl fullWidth size="small">
                      <IconButton
                        size="small"
                        onClick={() => remove(sectionIndex)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Collapse
                  in={
                    isSectionOpen && sectionIndex == selectedSectionIndex
                      ? true
                      : false
                  }
                  timeout="auto"
                >
                  <Box padding={2}>
                    <InstructionRowInputMobile
                      debugMode={debugMode}
                      addIngredientToRecipeIngredientsList={
                        props.addIngredientToRecipeIngredientsList
                      }
                      setValue={setValue}
                      ingredientsInRecipe={ingredientsInRecipe}
                      cookingActions={cookingActions}
                      modeSets={modeSets}
                      recipeName={recipeName}
                      sectionTitle={
                        filedArrayValues && filedArrayValues[sectionIndex]
                          ? filedArrayValues[sectionIndex].title
                          : ""
                      }
                      openModalToSelectImage={openModalToSelectImage}
                      control={control}
                      parentName={name}
                      parentIndex={sectionIndex}
                      childName="itemListElement"
                    />
                  </Box>
                </Collapse>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

// Users will be coming to this form from the menu page, then we are creating a new recipe
// User will be coming to this form by directly typing the url, if no recipeid is found then we are creating a new recipe
// Users will be clicking on the edit button on the recipes page, then we are editing an existing recipe, in this scenario we can pass the recipe data as state in the location object
// Users will directly come to this page from the url with the recipe id, then we are editing an existing recipe, we need to fetch the recipe from the db

export default function CreateRecipePro(props) {
  // Stub is used to edit an existing recipe
  // Also need for autosave function
  const { recipeId } = useParams();
  const location = useLocation();

  const [debugMode, setDebugMode] = useState(false);
  const [formMode, setFormMode] = useState("create");

  // alert(recipeId)
  const navigate = useNavigate();
  const {
    loading,
    loadingMessage,
    getEntity,
    getEntities,
    addEntity,
    updateEntity,
    getEntitiesByKey,
  } = useEntity();
  const entityName = "testRecipes";
  console.log(renderCount);
  renderCount = renderCount + 1;
  // const AppState = useContext(AppContext);

  const { user } = useAuth();
  // console.log("user",user)
  const [recipe, setRecipe] = useState(location.state ? location.state : null);
  console.log("recipe", recipe);
  const [ingredientsFromDb, setIngredientsFromDb] = useState([]);
  console.log("ingredientsFromDb", ingredientsFromDb);
  const [prepStyles, setPrepStyles] = useState([]);
  const [units, setUnits] = useState([]);
  const [cookingActions, setCookingActions] = useState([]);
  const [modeSets, setModeSets] = useState([]);
  const [cookingPresets, setCookingPresets] = useState([]);
  const [ingredientOptions, setIngredientOptions] = useState([]);

  // Dropdown options
  const [tools, setTools] = useState([]);
  const [tags, setTags] = useState([]);
  const [courses, setCourses] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [diets, setDiets] = useState([]);
  const [productReleases, setProductReleases] = useState([]);

  const [lastSavedTimeStamp, setLastSavedTimeStamp] = useState(null);

  useEffect(() => {
    if (recipeId) {
      setFormMode("edit");
    }
    getEntity(entityName, recipeId, setRecipe);

    return () => {};
  }, [recipeId]);

  // Fetching all the dropdown options
  useEffect(() => {
    // Object.keys(recipe).forEach(key => {
    //     console.log(key)
    //     setFormValue(key, recipe[key])
    // })
    resetFormValue(recipe);
    return () => {};
  }, [recipe]);

  // Fetching all the dropdown options
  useEffect(() => {
    getEntities("tools", setTools);
    getEntities("courses", setCourses);
    getEntities("cuisines", setCuisines);
    getEntities("allergies", setAllergies);
    getEntities("diets", setDiets);
    getEntities("tags", setTags);

    getEntities("ingredients", setIngredientsFromDb);
    getEntities("prepStyles", setPrepStyles);
    getEntities("units", setUnits);
    getEntities("productReleases", setProductReleases);

    // getEntities('cookingActions', setCookingActions)
    getEntities("cookingPresets", setCookingPresets);
    getEntitiesByKey("cookingPresets", "type", "customControl", setModeSets);
    return () => {};
  }, []);

  const getUniqueObjects = (myObjArray) => {
    return [
      ...new Map(myObjArray.map((item) => [item["name"], item])).values(),
    ];
  };

  const getAuthorDetails = () => {
    return {
      name: user.displayName,
      id: user.uid,
      photoURL: user.photoURL,
      email: user.email,
    };
  };

  useEffect(() => {
    setFormValue("author", getAuthorDetails());
    return () => {};
  }, [user]);

  const generateDefaultValueForTheForm = () => {
    return {
      title: generateRecipeName(),
      "@type": "Recipe",
      author: {
        name: user.displayName,
        id: user.uid,
        photoURL: user.photoURL,
        email: user.email,
      },
      recipeImages: [],
      profileUrl: "",

      recipeDescription: "",
      difficultyLevel: "",
      prepTime: 0,
      cookTime: 0,
      servings: 0,
      productReleases: [],
      schedulingLimit: null,

      recipeIngredients: [
        {
          id: "",
          name: "",
          ingredientType: "",
          quantity: "",
          ingredient: "",
          prepStyle: "",
          unit: "",
          schedulingLimit: "",
          isOutput: false,
          isRecipeGenerated: false,
          isUserGenerated: false,
          loadingPosition: "",
          podType: "",
          podPosition: [],
          categoryInContextOfRiku: "",
        },
      ],
      recipeInstructions: [
        {
          sectionName: "",
          title: "",
          sectionType: "",
          "@type": "HowToSection",
          itemListElement: [
            {
              "@type": "HowToStep",
              title: "",
              stepImageUrl: "",
              tip: "",
              ingredients: [
                {
                  isRecipeGenerated: false,
                  isUserGenerated: false,
                  isOutput: false,
                  name: "",
                  id: "",
                  quantity: "",
                  units: "",
                },
              ],
              selectedActions: [],
              selectedIngredients: [],
              cookingParameters: {
                stirring: "",
                isLidOpen: false,
                action: "",
                power: "",
                temperature: "",
                weight: "",
                duration: "",
              },
              semiSteps: [],
              tools: [],
              mentionsInputActive: true,
            },
          ],
        },
      ],
      notes: "",
      tags: [],

      recipeUrl: "",
      imageUrl: "",
    };
  };

  // Recipe Form Setup
  const {
    formState: { isDirty, dirtyFields },
    handleSubmit,
    watch,
    control,
    setValue: setFormValue,
    reset: resetFormValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: recipe ? recipe : generateDefaultValueForTheForm(),
  });

  useEffect(() => {
    console.log(recipe);
    // Object.keys(recipe).forEach(key => {
    //     console.log(key)
    //     setFormValue(key, recipe[key])
    // })
    resetFormValue(recipe);
    return () => {};
  }, [recipe]);

  // const submitRecipe = async (data) => {
  //   if (formMode === "edit") {
  //     await updateEntity(entityName, data);
  //   } else {
  //     await addEntity(entityName, data, setRecipe);
  //     navigate(`/create-recipe-pro/${recipe.id}`);
  //   }
  //   // upload recipe to the databse
  //   // saveEntityToDb(entityName, data, setRecipe)
  // };

  const formValues = watch();

  const ingredientWatcher = watch("recipeIngredients");

  // Function to autosave the form if it is dirty
  // useEffect(() => {
  //     if(isDirty){
  //         submitRecipe(formValues)
  //     }
  //     return () => {
  //     }
  //   }, [isDirty])

  const {
    fields: ingredientFields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "ingredients", // unique name for your Field Array
  });
  // when the ingredient name is update we will also update the id of the ingredient with the id from the database
  useEffect(() => {
    if (ingredientWatcher) {
      ingredientWatcher.forEach((field, index) => {
        if (field.id && field.id !== "") {
          return;
        }
        if (field.name) {
          const ingredient = ingredientsFromDb.find(
            (ingredient) => ingredient.name === field.name.toLowerCase()
          );

          const matchingIngredient = ingredientsFromDb.filter((dbIngredient) =>
            ingredientWatcher.some(
              (watcherIngredient) =>
                watcherIngredient?.name === dbIngredient?.name
            )
          );
          let newValue = matchingIngredient.map((val) => val?.category?.name);

          // console.log("matchingIngredient", matchingIngredient?.catogory?.name);

          if (ingredient) {
            setFormValue(`recipeIngredients[${index}].id`, ingredient.id);
          }
          if (matchingIngredient) {
            setFormValue(
              `recipeIngredients[${index}].ingredientType`,
              newValue[index]
            );
          }
        }
      });
    }
    return () => {};
  }, [ingredientWatcher]);

  const [ingredientsRowsCount, setIngredientsRowsCount] = useState(1);
  const [instructionSectionsCount, setInstructionSectionsCount] = useState(1);

  const [imageList, setImageList] = useState([]);
  const [imageToVariableMapping, setImageToVariableMapping] = useState({});

  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
  const toggleImagesModal = () => {
    setIsImagesModalOpen(!isImagesModalOpen);
  };
  const submitRecipe = async (data) => {
    // console.log("submitRecipe",data)
    if (formMode === "edit") {
      await updateEntity(entityName, data);
    } else {
      await addEntity(entityName, data, setRecipe);
      navigate(`/create-recipe-pro/${recipe.id}`);
    }
    // upload recipe to the databse
    // saveEntityToDb(entityName, data, setRecipe)
  };

  console.log("formValues", formValues);

  const changeImageField = (index, parameter, value) => {
    const newArray = [...imageList];
    newArray[index][parameter] = value;
    setImageList(newArray);
    setFormValue(
      "recipeImages",
      newArray.map((image) => image.downloadURL),
      { shouldDirty: true },
      { shouldDirty: true }
    );
  };

  useEffect(() => {
    imageList.forEach((image, index) => {
      if (image.status === "FINISH" || image.status === "UPLOADING") return;
      changeImageField(index, "status", "UPLOADING");
      const uploadTask = image.storageRef.put(image.file);
      uploadTask.on(
        "state_changed",
        null,
        function error(err) {
          console.log("Error Image Upload:", err);
        },
        async function complete() {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          changeImageField(index, "downloadURL", downloadURL);
          changeImageField(index, "status", "FINISH");
        }
      );
    });
  }, [changeImageField, imageList]);

  // Fields like Profile image will trigger the modal to select an image, once the selection is done the imageUrl is assigned to the field
  const [currentFiledWaitingForImageUrl, setCurrentFiledWaitingForImageUrl] =
    useState(null);
  const openModalToSelectImage = (field) => {
    toggleImagesModal();
    setCurrentFiledWaitingForImageUrl(field);
  };

  const addIngredientToRecipeIngredientsList = (ingredient) => {
    setFormValue("recipeIngredients", [
      ...formValues.recipeIngredients,
      ingredient,
    ]);
    updateRecipeScheduleLimit();
  };

  const updateRecipeScheduleLimit = (limit) => {
    if (formValues.recipeIngredients) {
      const schedulingLimitOfRecipeIngredients = formValues.recipeIngredients
        .filter((ing) => ing.schedulingLimit)
        .map((ing) => parseInt(ing.schedulingLimit));
      const schedulingLimitOfRecipe = Math.min(
        ...schedulingLimitOfRecipeIngredients
      );

      setFormValue("schedulingLimit", schedulingLimitOfRecipe);
    }
  };

  // Watch for changes in the recipeIngredients fieldArray
  useEffect(() => {
    updateRecipeScheduleLimit();
  }, [formValues.recipeIngredients]);

  // Handle the selection of an image in the modal
  const handleImageSelection = (imageUrl) => {
    // assign the image url to the field that triggered that image modal
    if (imageToVariableMapping[imageUrl]) {
      // delete the image from the field that triggered the modal
      setFormValue(imageToVariableMapping[imageUrl], null, {
        shouldDirty: true,
      });
      const newImageToVariableMapping = { ...imageToVariableMapping };
      delete newImageToVariableMapping[imageUrl];
      setImageToVariableMapping(newImageToVariableMapping);
    } else {
      // Make a new entry
      setFormValue(currentFiledWaitingForImageUrl, imageUrl, {
        shouldDirty: true,
      });
      setFormValue(currentFiledWaitingForImageUrl, imageUrl, {
        shouldDirty: true,
      });
      setImageToVariableMapping({
        ...imageToVariableMapping,
        [imageUrl]: currentFiledWaitingForImageUrl,
      });
    }
    // setFormValue(currentFiledWaitingForImageUrl, imageUrl)
    // Reset the field that triggered the modal
    setCurrentFiledWaitingForImageUrl(null);
  };
  return (
    <Box style={{ flexGrow: 1, background: "#F0F2F4", overflow: "scroll" }}>
      {/* Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={loading}
        autoHideDuration={6000}
        message={loadingMessage}
      />
      {/* Images upload and select modal */}
      <Modal
        open={isImagesModalOpen}
        onClose={toggleImagesModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ImagesDropzone setImageList={setImageList} />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
                style={{ height: "80vh", overflow: "auto" }}
              >
                {imageList.map((image, index) => (
                  <Grid item xs={3} key={index}>
                    <ImageElement
                      image={image}
                      index={index}
                      imageToVariableMapping={imageToVariableMapping}
                      handleImageSelection={handleImageSelection}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* App Bar */}
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Manual Recipe Creation (Mobile & Semi)
          </Typography>
          <Typography variant="caption" color="initial">
            {" "}
            {lastSavedTimeStamp ? "Last Saved : " + lastSavedTimeStamp : ""}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={debugMode}
                  onChange={() => setDebugMode(!debugMode)}
                  color="warning"
                />
              }
              label="Debug"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={<Switch />} label="Pro" />
          </FormGroup>
          <IconButton
            edge="start"
            color="inherit"
            sx={{ mr: 2, marginRight: 2 }}
          >
            <AddLinkIcon />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            sx={{ mr: 2, marginRight: 2 }}
            onClick={toggleImagesModal}
          >
            <PhotoLibraryIcon />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            sx={{ mr: 2, marginRight: 2 }}
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            sx={{ mr: 2, marginRight: 2 }}
            disabled={isDirty ? false : true}
            onClick={() => navigate(`/recipes/${recipe.id}`)}
          >
            <PreviewIcon />
          </IconButton>
          {isDirty ? (
            <Button
              color="secondary"
              onClick={() => submitRecipe(formValues)}
              variant="contained"
            >
              Save
            </Button>
          ) : (
            <Button color="secondary" disabled variant="text">
              Saved
            </Button>
          )}
          <Button
            style={{ marginLeft: 5 }}
            color="secondary"
            variant="contained"
          >
            Publish
          </Button>
        </Toolbar>
      </AppBar>
      {recipe ? (
        <Box
          style={{
            marginTop: 20,
            width: "95%",
            marginLeft: "auto",
            marginRight: "auto",
            height: "auto",
          }}
        >
          <form onSubmit={handleSubmit(submitRecipe)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* Meta Data */}
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box
                      padding={2}
                      style={{
                        background: "#FFFFFF",
                        borderRadius: 2,
                        height: 450,
                      }}
                    >
                      {formValues.profileUrl ? (
                        <img
                          alt=""
                          src={formValues.profileUrl}
                          style={{
                            display: "block",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      ) : (
                        <Button
                          variant="text"
                          color="primary"
                          style={{ width: "100%", height: "100%" }}
                          onClick={() => openModalToSelectImage("profileUrl")}
                        >
                          <AddPhotoAlternateIcon size="large" />
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box
                      padding={2}
                      style={{ background: "#FFFFFF", borderRadius: 2 }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <FormGroup>
                            <InputLabel> Recipe Name</InputLabel>
                            <ModifiedTextField control={control} name="title" />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <InputLabel> Author</InputLabel>
                            <ModifiedTextField
                              control={control}
                              name="author.name"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <InputLabel> Description</InputLabel>
                            <ModifiedTextField
                              control={control}
                              name="recipeDescription"
                              multiline={true}
                              rows={2}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <FormGroup>
                            <InputLabel>Difficulty Level</InputLabel>
                            <ModifiedSelect
                              control={control}
                              name={"difficultyLevel"}
                            >
                              <MenuItem value=""></MenuItem>
                              <MenuItem value="easy">Easy</MenuItem>
                              <MenuItem value="medium">Medium</MenuItem>
                              <MenuItem value="hard">Hard</MenuItem>
                            </ModifiedSelect>
                          </FormGroup>
                          <FormGroup>
                            <InputLabel>Servings</InputLabel>
                            <ModifiedTextField
                              control={control}
                              name="servings"
                              fullWidth={true}
                            />
                          </FormGroup>
                          <FormGroup>
                            <InputLabel>Prep Time (mins)</InputLabel>
                            <ModifiedTextField
                              control={control}
                              name="prepTime"
                              fullWidth={true}
                            />
                          </FormGroup>
                          <FormGroup>
                            <InputLabel>Cook Time (mins)</InputLabel>
                            <ModifiedTextField
                              control={control}
                              name="cookTime"
                              fullWidth={true}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormGroup>
                                <InputLabel>
                                  Compatible With Products
                                </InputLabel>
                                <ModifiedAutoCompleteWithOptionsObject
                                  control={control}
                                  name="productReleases"
                                  options={productReleases}
                                  multiple={true}
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormGroup>
                                <InputLabel>Tools</InputLabel>
                                <ModifiedAutoCompleteWithOptionsObject
                                  control={control}
                                  name="tools"
                                  options={tools}
                                  multiple={true}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                              <FormGroup>
                                <InputLabel>Tags</InputLabel>
                                <ModifiedAutoCompleteWithOptionsObject
                                  control={control}
                                  name="tags"
                                  options={tags}
                                  multiple={true}
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormGroup>
                                <InputLabel>Courses</InputLabel>
                                <ModifiedAutoCompleteWithOptionsObject
                                  control={control}
                                  name="courses"
                                  options={courses}
                                  multiple={true}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                              <FormGroup>
                                <InputLabel>Cuisines</InputLabel>
                                <ModifiedAutoCompleteWithOptionsObject
                                  control={control}
                                  name="cuisines"
                                  options={cuisines}
                                  multiple={true}
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormGroup>
                                <InputLabel>Allergies</InputLabel>
                                <ModifiedAutoCompleteWithOptionsObject
                                  control={control}
                                  name="allergies"
                                  options={allergies}
                                  multiple={true}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                              <FormGroup>
                                <InputLabel>Diets</InputLabel>
                                <ModifiedAutoCompleteWithOptionsObject
                                  control={control}
                                  name="diets"
                                  options={diets}
                                  multiple={true}
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormGroup>
                                <InputLabel>Scheduling Limit(mins)</InputLabel>
                                <ModifiedTextField
                                  control={control}
                                  name="schedulingLimit"
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* Ingredients */}
              <Grid item xs={12}>
                <Box
                  padding={2}
                  style={{ background: "#FFFFFF", borderRadius: 4 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography variant="h5" color="initial">
                        Ingredients :{" "}
                        {formValues.recipeIngredients
                          ? formValues.recipeIngredients.length
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      justifyItems="flex-end"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() =>
                          setIngredientsRowsCount(
                            formValues.recipeIngredients.length + 1
                          )
                        }
                      >
                        + Ingredient
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={0.75}>
                          <Typography variant="body1" color="initial">
                            Qty.
                          </Typography>
                        </Grid>
                        <Grid item xs={1.25}>
                          <Typography variant="body1" color="initial">
                            Units
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body1" color="initial">
                            Ingredient
                          </Typography>
                        </Grid>
                        <Grid item xs={1.25}>
                          <Typography variant="body1" color="initial">
                            Prep Style
                          </Typography>
                        </Grid>
                        <Grid item xs={1.25}>
                          <Typography variant="body1" color="initial">
                            Loading Position
                          </Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Typography variant="body1" color="initial">
                            Riku's Context
                          </Typography>
                        </Grid>
                        <Grid item xs={1.25}>
                          <Typography variant="body1" color="initial">
                            Pod Type
                          </Typography>
                        </Grid>
                        <Grid item xs={0.75}>
                          <Typography variant="body1" color="initial">
                            IngPodPos
                          </Typography>
                        </Grid>
                        <Grid item xs={0.75}>
                          <Typography variant="body1" color="initial">
                            Exp.(min)
                          </Typography>
                        </Grid>
                        <Grid item xs={0.75}>
                          <Typography variant="body1" color="initial">
                            Derived?
                          </Typography>
                        </Grid>
                        <Grid item xs={0.5}>
                          <Typography
                            variant="body1"
                            color="initial"
                          ></Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <IngredientRowInput
                        control={control}
                        ingredientsFromDb={getUniqueObjects(ingredientsFromDb)}
                        arraySize={ingredientsRowsCount}
                        units={units}
                        prepStyles={prepStyles}
                        name={"recipeIngredients"}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* Recipe Sections */}
              <Grid item xs={12}>
                <Box padding={2} style={{ background: "#FFFFFF" }}>
                  <Grid
                    container
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <Typography variant="h5" color="initial">
                            Sections :{" "}
                            {formValues.recipeInstructions
                              ? formValues.recipeInstructions.length
                              : 0}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{ justifyContent: "flex-end" }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() =>
                              setInstructionSectionsCount(
                                formValues.recipeInstructions.length + 1
                              )
                            }
                          >
                            + Section
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <InstructionCollectionRowInput
                        debugMode={debugMode}
                        setValue={setFormValue}
                        modeSets={modeSets}
                        recipeName={formValues ? formValues.title : ""}
                        openModalToSelectImage={openModalToSelectImage}
                        control={control}
                        name={"recipeInstructions"}
                        cookingActions={cookingPresets}
                        addIngredientToRecipeIngredientsList={
                          addIngredientToRecipeIngredientsList
                        }
                        ingredientsInRecipe={
                          formValues.recipeIngredients
                            ? formValues.recipeIngredients
                            : []
                        }
                        arraySize={instructionSectionsCount}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      ) : (
        <Box style={{ marginLeft: "auto", marginRigh: "auto", top: "20%" }}>
          <CircularProgress />
        </Box>
      )}
      {/* Form Starts */}

      {/* Form Ends */}

      {/* debug mode*/}
      {debugMode ? (
        <Box padding={2}>
          <ReactJson src={formValues} theme="monokai" />
        </Box>
      ) : null}
    </Box>
  );
}

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  overflow: "scroll",
  // flexWrap: '',
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

const style = {
  position: "absolute",
  right: 0,
  height: "100vh",
  width: "30%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Picked up the iphone css from here https://codepen.io/adielhercules/pen/dJJGBQ/
const scaling = 0.75;
const iphone = {
  position: "relative",
  margin: 40 * scaling,
  width: 360 * scaling,
  height: 780 * scaling,
  // backgroundColor: '#7371ee',
  // backgroundImage: 'linear-gradient(60deg, #7371ee 1%,#a1d9d6 100%)',
  borderRadius: 40 * scaling,
  boxShadow:
    "0px 0px 0px 5px #1f1f1f, 0px 0px 0px 6px #191919, 0px 0px 0px 10px #111",
};
