import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Box,
} from "@mui/material";
import { useController } from "react-hook-form";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../../config/firebase-config";

const ControlledMediaSelect = (props) => {
  const {
    control,
    name,
    options = [],
    multiple,
    label,
    handleMediaUpload,
    isCollectionSelect,
    schema,
    watchAllFields,
  } = props;
  const { field } = useController({ name, control });

  const [mediaFiles, setMediaFiles] = useState({});
  const [selectedOptions, setSelectedOptions] = useState(field.value || []);
  const [editingOption, setEditingOption] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setSelectedOptions(field.value || []);
  }, [field.value]);

  const handleFileChange = async (e, option) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const uuid = uuidv4();
        const storageRef = storage
          .ref()
          .child(
            `${schema.collectionName}/${watchAllFields.id}/${uuid}_${file.name}`
          );

        const snapshot = await storageRef.put(file);
        const downloadURL = await snapshot.ref.getDownloadURL();

        setMediaFiles((prev) => ({
          ...prev,
          [option]: { file, url: downloadURL },
        }));

        handleMediaUpload &&
          handleMediaUpload((prevMediaFiles) => ({
            ...prevMediaFiles,
            [option]: downloadURL,
          }));

        setEditingOption(null);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOptions(Array.isArray(value) ? value : [value]);
    field.onChange(value);
  };

  const handleDelete = (option) => {
    setMediaFiles((prevMediaFiles) => {
      const newMediaFiles = { ...prevMediaFiles };
      delete newMediaFiles[option];
      return newMediaFiles;
    });
    setEditingOption(null);
  };

  const handleEditClick = (option) => {
    setEditingOption(option);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <FormControl fullWidth>
      <FormLabel>{label}</FormLabel>
      <Select
        size={props.size ? props.size : "small"}
        label={label}
        id={field.name}
        onChange={handleChange}
        onBlur={field.onBlur}
        name={field.name}
        inputRef={field.ref}
        value={selectedOptions}
        fullWidth
        multiple={multiple}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              width: 250,
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>{option}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ControlledMediaSelect.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  handleMediaUpload: PropTypes.func,
  isCollectionSelect: PropTypes.bool,
  schema: PropTypes.object,
  watchAllFields: PropTypes.object,
};

ControlledMediaSelect.defaultProps = {
  multiple: false,
  label: "",
  handleMediaUpload: () => {},
  isCollectionSelect: false,
  schema: {},
  watchAllFields: {},
};

export default ControlledMediaSelect;
