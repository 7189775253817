// A react dropzone compoent which will upload multiple files to the firebase storage.
// used for uploadi
import React from "react";
import { useDropzone } from "react-dropzone";
import { Grid, Typography, Button } from "@mui/material";
import { storage } from "../../config/firebase-config";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { v4 as uuidv4 } from "uuid";

export default function ImagesDropzone({ setImageList, storageLocation }) {
  const uuid = uuidv4();
  const onDrop = (acceptedFiles) => {
    let fileLocation = storageLocation ? storageLocation : "testImages";
    let fileLocationId = fileLocation.split("/");
    if (acceptedFiles.length > 0) {
      const newImages = Array.from(acceptedFiles).map((file) => {
        return {
          file: file,
          fileName: file.name,
          status: "CREATED",
          // TODO: Pass storage ref to the upload function
          storageRef: storage
            .ref()
            .child(
              fileLocation +
                "/original/" +
                fileLocationId[1] +
                "--" +
                uuid +
                "--" +
                file.name
            ),
          downloadURL: "",
          description: "",
        };
      });

      setImageList((prevState) => [...prevState, ...newImages]);
    }
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg",
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Grid container direction="column" spacing={2}>
        <Grid item container direction="column" alignItems="center" spacing={1}>
          <Button
            onClick={open}
            variant="outline"
            color="primary"
            style={{ width: "100%", height: 200, background: "#F0F7FF" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography align="center">
                  {isDragActive
                    ? "Drop Images here ..."
                    : "Drag 'n' drop images here"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AddPhotoAlternateIcon fontSize="48" />
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
