import { v4 as uuidv4 } from "uuid";

const metaDataObject = [
  "authorId",
  "authorName",
  "authorThumbnail",
  "recipeId",
  "id",
  "title",
  "recipeDescription",
  "difficultyLevel",
  "thumbnailUrl",
  "prepTimeInMins",
  "cookTimeInMins",
  "restTimeInMins",
  "totalTimeInMins",
  "sourceUrl",
  "isVerifiedByKlynk",
  "servings",
  "productReleases",
  "schedulingLimit",
  "prepImage",
  "allergies",
  "courses",
  "cuisines",
  "diets",
  "tags",
  "tools",
  "createdAt",
  "updatedAt",
  "recipeIngredientsLength",
  "recipeIngredientNames",
  "imageUrl",
  "notes",
  "recipeImage",
  "isRecipeStatus",
  "isRecipeProSelected",
  "isRikuSelected",
  "isSemiSelected",
  "recipeServeDescription",
  "recipeImages",
  "isPublished",
  "isPublic",
  "isCreatedByKlynk",
];

const changedMetaDataKeys = [
  { newKey: "thumbnailUrl", oldKey: "profileUrl" },
  { newKey: "prepTimeInMins", oldKey: "prepTime" },
  { newKey: "cookTimeInMins", oldKey: "cookTime" },
  { newKey: "restTimeInMins", oldKey: "restTime" },
  { newKey: "totalTimeInMins", oldKey: "totalTimeInMins" },
  { newKey: "sourceUrl", oldKey: "recipeUrl" },
  { newKey: "isVerifiedByKlynk", oldKey: "verifiedByKlynk" },
];

export const recipeStructureChangingFromOldToNew = (recipe) => {
  const ourObject = {
    recipeId: "",
    isRecipeStatus: false,
    isRecipeProSelected: false,
    isRikuSelected: false,
    isSemiSelected: false,
    metadata: {},
    recipeIngredients: [],
    recipeSections: [],
    recipeInstructions: [],
    cookingParameters: [],
  };

  if (Object.keys(recipe).length !== Object.keys(ourObject).length) {
    return recipeFormatting(recipe, ourObject);
  } else {
    return recipe;
  }
};

export const recipeFormatting = (args, args1) => {
  const ourObjectKeys = Object.keys(args1);
  const ourObjectValues = Object.values(args1);
  const recipesItem = args;
  const recipesItemKeys = Object.keys(args);
  const entireRecipeModalObject = args1;
  let recipeIds = recipesItem["id"] || recipesItem["recipeId"];

  ourObjectKeys.forEach((recipeItem, recipeIndex) => {
    if (recipeItem === "recipeSections") {
      entireRecipeModalObject[recipeItem] = instructionsFormatting({
        recipeId: recipeIds,
        recipeInstructions: recipesItem.recipeInstructions,
        recipeIngredients: recipesItem.recipeIngredients,
        entireRecipeModalObject,
        sectionType: recipesItem.productReleases,
      });
    } else if (recipeItem === "recipeIngredients") {
      entireRecipeModalObject[recipeItem] = ingredientFormatting({
        recipeIngredients: recipesItem.recipeIngredients,
        instructionId: "",
        sectionId: "",
        recipeId: recipeIds,
      });
    } else if (recipeItem === "recipeId") {
      entireRecipeModalObject[recipeItem] = recipeIds;
    } else if (recipeItem === "isRecipeStatus") {
      entireRecipeModalObject[recipeItem] =
        recipesItem[recipeItem] || ourObjectValues[recipeIndex];
    } else if (recipeItem === "isRecipeProSelected") {
      entireRecipeModalObject[recipeItem] =
        recipesItem[recipeItem] ||
        (recipesItem["productReleases"]?.length > 0 ? true : false);
    } else if (recipeItem === "isRikuSelected") {
      entireRecipeModalObject[recipeItem] =
        recipesItem[recipeItem] ||
        (recipesItem["productReleases"]?.includes("riku") ||
        recipesItem["productReleases"]?.includes("Riku[EP1]")
          ? true
          : false);
    } else if (recipeItem === "isSemiSelected") {
      entireRecipeModalObject[recipeItem] =
        recipesItem[recipeItem] ||
        (recipesItem["productReleases"]?.includes("semi") ||
        recipesItem["productReleases"]?.includes("Semi[DVT]") ||
        recipesItem["productReleases"]?.includes("Semi[EVT]")
          ? true
          : false);
    } else if (recipeItem === "metadata") {
      entireRecipeModalObject[recipeItem] = metaDataFormatting(
        recipesItem,
        recipesItemKeys
      );
    }
  });
  return entireRecipeModalObject;
};

export const metaDataFormatting = (recipes, recipesKeys) => {
  let metaDataStores = {};
  let newKeys = [];
  let oldKeys = [];

  changedMetaDataKeys.map((item) => {
    newKeys.push(item.newKey);
    oldKeys.push(item.oldKey);
  });

  recipesKeys.forEach((item) => {
    if (item === "author") {
      metaDataStores["authorId"] = recipes[item]["id"];
      metaDataStores["authorName"] = recipes[item]["name"];
      metaDataStores["authorThumbnail"] = recipes[item]["photoURL"];
    } else if (oldKeys.includes(item)) {
      metaDataStores[newKeys[oldKeys.indexOf(item)]] =
        recipes[newKeys[oldKeys.indexOf(item)]] || recipes[item];
    } else if (metaDataObject.includes(item)) {
      if (["updatedAt", "createdAt"].includes(item)) {
        metaDataStores[item] =
          typeof recipes[item] === "object"
            ? new Date(
                recipes[item].seconds * 1000 || recipes._seconds * 1000
              ).toISOString()
            : recipes[item];
      } else if (item !== "productReleases") {
        metaDataStores[item] = recipes[item];
      } else {
        metaDataStores[item] = recipes[item];
        metaDataStores["isRecipeProSelected"] =
          recipes["productReleases"]?.length > 0 ? true : false;
        metaDataStores["isRikuSelected"] =
          recipes["productReleases"]?.includes("riku") ||
          recipes["productReleases"]?.includes("Riku[EP1]")
            ? true
            : false;
        metaDataStores["isSemiSelected"] =
          recipes["productReleases"]?.includes("semi") ||
          recipes["productReleases"]?.includes("Semi[EVT]") ||
          recipes["productReleases"]?.includes("Semi[DVT]")
            ? true
            : false;
      }
    }
  });
  return metaDataStores;
};

export const ingredientFormatting = (args) => {
  let ingredientList = [];

  let recipeIdx = args?.recipeId;
  let recipeIngredientItems = args?.recipeIngredients;

  if (
    recipeIngredientItems !== "undefined" &&
    recipeIngredientItems?.length > 0
  ) {
    recipeIngredientItems.forEach((recipeIngredient) => {
      let ingredientObject = {
        id: "",
        isScaleIngredient: false,
        name: "",
        quantity: "",
        quantityPerStep: "",
        ingredient: "",
        ingredientType: "",
        ingredientImage: "",
        prepStyles: "",
        units: "",
        unitsPerStep: "",
        schedulingLimit: "",
        isOutput: false,
        isRecipeGenerated: false,
        isUserGenerated: false,
        loadingPosition: "",
        podType: "",
        podPosition: [],
        categoryInContextOfRiku: "",
        sectionId: "",
        instructionId: "",
        recipeId: "",
      };

      const ingredientObjectKeys = Object.keys(ingredientObject);
      const ingredientObjectValues = Object.values(ingredientObject);

      if (recipeIngredient.name !== "") {
        ingredientObjectKeys.forEach(
          (ingredientItems, ingredientItemsIndex) => {
            if (ingredientItems === "podPosition") {
              if (Array.isArray(recipeIngredient[ingredientItems])) {
                ingredientObject[ingredientItems] =
                  recipeIngredient[ingredientItems] ||
                  ingredientObjectValues[ingredientItemsIndex];
              } else if (
                recipeIngredient?.[ingredientItems] !== undefined &&
                recipeIngredient?.[ingredientItems] !== ""
              ) {
                ingredientObject[ingredientItems] = [
                  Number(recipeIngredient?.[ingredientItems]),
                ];
              }
            } else if (ingredientItems === "recipeId") {
              ingredientObject[ingredientItems] = recipeIdx;
            } else if (ingredientItems === "ingredientId") {
              ingredientObject[ingredientItems] =
                recipeIngredient[ingredientItems] || "";
            } else if (ingredientItems === "id") {
              ingredientObject[ingredientItems] =
                recipeIngredient[ingredientItems] || uuidv4();
            } else if (ingredientItems === "sectionId") {
              ingredientObject[ingredientItems] =
                recipeIngredient[ingredientItems] || args?.sectionId;
            } else if (ingredientItems === "prepStyles") {
              console.log(
                recipeIngredient[ingredientItems],
                recipeIngredient["prepStyle"],
                "prep styles"
              );
              ingredientObject[ingredientItems] =
                recipeIngredient[ingredientItems] ||
                recipeIngredient["prepStyle"];
            } else if (ingredientItems === "instructionId") {
              ingredientObject[ingredientItems] =
                recipeIngredient[ingredientItems] || args?.instructionId;
            } else {
              ingredientObject[ingredientItems] =
                recipeIngredient[ingredientItems] ||
                ingredientObjectValues[ingredientItemsIndex];
            }
          }
        );
      }
      ingredientList.push(ingredientObject);
    });
  }
  return ingredientList;
};

export const addingSectionId = (args) => {
  let recipeIngredients = args?.recipeIngredient;
  let selectedIngredient = args?.selectedIngredients;
  let entireRecipeModalObject = args.entireRecipeModalObject;
  let count = 0;

  if (selectedIngredient?.length > 0) {
    selectedIngredient.forEach((argsItem) => {
      let ingredientItems = recipeIngredients.filter((item) => {
        let argsElements = argsItem.split(",");
        if (argsElements.length > 1) {
          return (
            item?.name?.toLowerCase() ===
              argsItem.split(",")[0]?.toLowerCase() &&
            item?.quantity === argsItem.split(",")?.[1] &&
            item?.units?.toLowerCase() ===
              argsItem.split(",")?.[2]?.toLowerCase()
          );
        } else {
          return item?.name?.toLowerCase() === argsItem?.toLowerCase();
        }
      });
      count = 0;
      (ingredientItems?.length > 0 || ingredientItems !== undefined) &&
        ingredientItems.map((ingredientItem) => {
          let recipeIngredientIndex = recipeIngredients.findIndex(
            (item) => item === ingredientItem
          );
          if (
            (ingredientItem?.sectionId === "" ||
              ingredientItem?.instructionId === "") &&
            count === 0
          ) {
            entireRecipeModalObject.recipeIngredients[recipeIngredientIndex] = {
              ...entireRecipeModalObject.recipeIngredients[
                recipeIngredientIndex
              ],
              sectionId: args?.sectionId,
              instructionId: args?.instructionId,
            };
            count++;
          } else if (
            (ingredientItem?.sectionId === undefined ||
              ingredientItem?.instructionId === undefined) &&
            count === 0
          ) {
            entireRecipeModalObject.recipeIngredients[recipeIngredientIndex] = {
              ...entireRecipeModalObject.recipeIngredients[
                recipeIngredientIndex
              ],
              sectionId: args?.sectionId,
              instructionId: args?.instructionId,
            };
            count++;
          } else if (
            ingredientItem.sectionId === args?.sectionId ||
            ingredientItem.instructionId === args?.instructionId
          ) {
            count++;
          }
          return 0;
        });
    });
  }
};

export const instructionItemListElement = (args) => {
  let itemListElementArray = [];
  let recipeIdx = args?.recipeId;
  let instructionElement = args?.instructionArray?.itemListElement;
  let entireRecipeModalObject = args?.entireRecipeModalObject;

  if (instructionElement !== "undefined" && instructionElement?.length > 0) {
    instructionElement.forEach((instructionElementItem) => {
      const sampleElements = {
        title: "",
        stepUrl: "",
        stepImageUrl: "",
        tip: "",
        selectedActions: [],
        selectedIngredients: [],
        selectedModeSets: [],
        mentionsTitle: "",
        tools: [],
        actions: [],
        selectedTools: [],
        ingredients: [],
        recipeId: "",
        sectionId: "",
        id: "",
        instructionIndex: "",
        mentionsInputActive: false,
      };
      const cookingParameters = {
        stirring: "",
        isLidOpen: false,
        action: "",
        power: "", // needs to change to
        powerInLevel: "",
        temperature: "", // needs to change to
        centralTemperatureInC: "",
        probeTemperature: "", // needs to change to
        externalTemperatureInC: "",
        weight: "", // needs to change to
        weightInGrams: "",
        duration: "", // needs to change to
        durationInMins: "",
        sectionId: "",
        instructionId: "",
        id: "",
        recipeId: "",
        instructionIndex: "",
        presetId: "",
        presetName: "",
        controlModeId: "",
        controlModeFirmwareName: "",
        controlModeFirmwareId: "",
        manualHeatLevel: "",
        semiScreenId: "",
      };

      const sampleElementsKeys = Object.keys(sampleElements);
      const sampleElementsvalues = Object.values(sampleElements);
      let instructionIds = instructionElementItem["id"] || uuidv4();

      sampleElementsKeys.forEach((sampleElementsItem, sampleElementsIndex) => {
        if (sampleElementsItem === "sectionId") {
          sampleElements[sampleElementsItem] = args?.sectionId;
        } else if (sampleElementsItem === "instructionIndex") {
          sampleElements[sampleElementsItem] =
            instructionElementItem[sampleElementsItem] ||
            sampleElementsIndex + 1;
        } else if (sampleElementsItem === "id") {
          sampleElements[sampleElementsItem] = instructionIds;
        } else if (sampleElementsItem === "recipeId") {
          sampleElements[sampleElementsItem] = recipeIdx;
        } else if (sampleElementsItem === "stepImageUrl") {
          sampleElements[sampleElementsItem] =
            instructionElementItem["stepUrl"] || "";
        } else if (sampleElementsItem === "action") {
          sampleElements[sampleElementsItem] =
            instructionElementItem?.[sampleElementsItem] === "" &&
            ["add", "Add"].includes(
              instructionElementItem?.["selectedActions"]?.[0]
            )
              ? instructionElementItem?.["selectedActions"]?.[0]?.toLowerCase()
              : instructionElementItem?.[sampleElementsItem]?.toLowerCase();
        } else {
          sampleElements[sampleElementsItem] =
            instructionElementItem[sampleElementsItem] ||
            sampleElementsvalues[sampleElementsIndex];
        }
      });

      addingSectionId({
        selectedIngredients: instructionElementItem["selectedIngredients"],
        recipeIngredient: args?.recipeIngredients,
        instructionId: instructionIds,
        sectionId: args?.sectionId,
        entireRecipeModalObject: args?.entireRecipeModalObject,
      });

      const cookingParameter = instructionElementItem["cookingParameters"];

      const cookingParametersInfo = {
        ...cookingParameter,
        powerInLevel: cookingParameter.powerInLevel || cookingParameter.power,
        centralTemperatureInC:
          cookingParameter.centralTemperatureInC ||
          cookingParameter.temperature,
        externalTemperatureInC:
          cookingParameter.externalTemperatureInC ||
          cookingParameter.probeTemperature,
        durationInMins:
          cookingParameter.durationInMins || cookingParameter.duration,
        weightInGrams:
          cookingParameter.weightInGrams || cookingParameter.weight,
      };

      entireRecipeModalObject["cookingParameters"].push({
        ...ObjectFormatting(cookingParametersInfo, cookingParameters),
        id: uuidv4(),
        instructionId: instructionIds,
        sectionId: args?.sectionId,
        recipeId: recipeIdx,
      });

      itemListElementArray.push(sampleElements);
    });
  }
  return itemListElementArray;
};

export const instructionsFormatting = (args) => {
  let recipeInstructionArray = [];

  let recipeIdx = args?.recipeId;
  let instruction = args?.recipeInstructions;
  let recipeIngredients = args?.recipeIngredients;
  let entireRecipeModalObject = args?.entireRecipeModalObject;
  let sectionType = args?.sectionType;

  if (instruction !== "undefined" && instruction?.length > 0) {
    instruction.forEach((instructionItem) => {
      const recipeInstructions = {
        id: "",
        recipeId: "",
        sectionName: "",
        sectionType: "",
        isLoopSection: "",
        loopInstruction: "",
        sectionIndex: "",
        isProbeRequired: false,
      };

      let recipeInstructionsKeys = Object.keys(recipeInstructions);
      let recipeInstructionsValues = Object.values(recipeInstructions);
      let instructionItemKeys = ["itemListElement", ...recipeInstructionsKeys];

      let sectionIds = instructionItem["id"] || uuidv4();
      instructionItemKeys.forEach(
        (instructionItemKey, instructionItemIndex) => {
          if (recipeInstructionsKeys.includes(instructionItemKey)) {
            if (instructionItemKey === "id") {
              recipeInstructions[instructionItemKey] = sectionIds;
            } else if (instructionItemKey === "sectionName") {
              console.log(
                recipeInstructions[instructionItemKey],
                instructionItem[instructionItemKey],
                instructionItem["title"],
                "sectionName"
              );
              recipeInstructions[instructionItemKey] =
                instructionItem[instructionItemKey] ||
                instructionItem["title"] ||
                "";
            } else if (instructionItemKey === "recipeId") {
              recipeInstructions[instructionItemKey] = recipeIdx;
            } else if (instructionItemKey === "sectionType") {
              recipeInstructions[instructionItemKey] =
                (sectionType !== undefined &&
                  sectionType?.includes("Riku[EP1]") &&
                  "hardware") ||
                "manual";
              if (
                sectionType !== undefined &&
                sectionType?.includes("Riku[EP1]")
              ) {
                entireRecipeModalObject["isRikuSelected"] = true;
              } else if (
                sectionType !== undefined &&
                (sectionType?.includes("Semi[EVT") ||
                  sectionType?.includes("Semi[DVT]"))
              ) {
                entireRecipeModalObject["isSemiSelected"] = true;
              }
            } else {
              recipeInstructions[instructionItemKey] =
                instructionItem[instructionItemKey] ||
                recipeInstructionsValues[instructionItemIndex - 1];
            }
          } else {
            entireRecipeModalObject["recipeInstructions"] = [
              ...entireRecipeModalObject["recipeInstructions"],
              instructionItemListElement({
                sectionId: sectionIds,
                instructionArray: instructionItem,
                entireRecipeModalObject,
                recipeId: recipeIdx,
                recipeIngredients: recipeIngredients,
              }),
            ].flat(1);
          }
        }
      );
      recipeInstructionArray.push(recipeInstructions);
    });
  }
  return recipeInstructionArray;
};

export const ObjectFormatting = (args, args1) => {
  const objectArgs1Keys = Object.keys(args1);
  const objectArgs1Values = Object.values(args1);
  const objectFormatArgs = args;
  const objectFormatArgs1 = args1;

  objectArgs1Keys.forEach((args1Item, args1Index) => {
    objectFormatArgs1[args1Item] =
      objectFormatArgs[args1Item] || objectArgs1Values[args1Index];
  });
  return objectFormatArgs1;
};
