/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import { titlesValidation } from "../recipe-filters/recipe-filters-model";
import {
  mixed,
  string,
  number,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from "yup";
const prepStylesModel = {
  name: "Prep Styles",
  model: "prepStyles",
  collectionName: "prepStyles",
  defaultObject: {
    id: null,
    // thumbnailUrl: null,
    name: null,
    associatedActions: [],
    associatedTools: [],
    createdAt: null,
    updatedAt: null,
  },
  fields: [
    // {
    //   name: "thumbnailUrl",
    //   dataType: "String",
    //   inputFieldType: "image-uploader",
    //   label: "Thumbnail",
    //   required: false,
    //   showInEditLayout: true,
    //   editLayoutWidth: 6,
    //   showInListLayout: true,
    //   listLayoutWidth: 150,
    //   listLayoutComponent: (props) => {
    //     return (
    //       <img
    //         src={props.row?.thumbnailUrl}
    //         alt={props.row?.name}
    //         style={{ width: "50px", height: "50px", borderRadius: "50%" }}
    //       />
    //     );
    //   },
    // },
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      required: true,
      validation: titlesValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{name}</span>
          </div>
        );
      },
    },
    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 300,
      listLayoutComponent: (props) => {
        const description = props.row?.description;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{description}</span>
          </div>
        );
      },
    },
    {
      name: "associatedActions",
      dataType: "Array",
      inputFieldType: "select-multiple",
      label: "Associated Actions",
      required: false,
      collectionName: "actions",
    },
    {
      name: "associatedTools",
      dataType: "Array",
      inputFieldType: "select-multiple",
      label: "Associated Tools",
      required: false,
      collectionName: "tools",
    },
  ],
  layout: [
    // [{ name: "thumbnailUrl", width: 12 }],
    [{ name: "name", width: 6 }],
    [{ name: "description", width: 12 }],
    [{ name: "associatedTools", width: 12 }],
    [{ name: "associatedActions", width: 12 }],
  ],
  actions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
  ],
};

export default prepStylesModel;
