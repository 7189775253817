import React from "react";
import MobileDocRecipeEditor from "./mobile-doc-recipe-editor-old";
import "./mobile-doc-editor-old.css";

function CreateRecipeByMobileDocEditor() {
  return (
    <div className="App">
      <h1>Recipe Editor</h1>
      <MobileDocRecipeEditor />
    </div>
  );
}

export default CreateRecipeByMobileDocEditor;
