import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  Grid,
  Typography,
  createFilterOptions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useController, useFormContext } from "react-hook-form";
const styles = {
  renderTags: {
    border: " 2px solid  #EF4823",
    borderRadius: "4px",
    padding: "5px 10px",
    display: "flex",
    alignItems: "center",
    columnGap: 1,
    background: "#FFECE4",
    marginBottom: "10px",
  },
  parentStyles: {
    "MuiAutocomplete-listbox": {
      background: "yellow",
      border: "2px solid red",
    },
  },
};

const ModifiedAutoCompleteWithTags = (props) => {
  const { control, name, options } = props;
  const uniqueOptions = [
    ...new Set(options?.map((item) => item?.name?.toLowerCase())),
  ];

  const { setValue } = useFormContext();

  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });
  const [values, setValues] = useState(field.value);

  const onDelete = (id) => {
    const filterValues = values.filter((item, index) => index !== id);
    setValues(filterValues);
    setValue(name, filterValues);
  };
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option,
  });

  return (
    <Box>
      <Autocomplete
        autoHighlight={true}
        ListboxProps={{
          style: {
            maxHeight: "20vh",
            color: "#1C1C1C",
            background: "#F7F7F7",
            position: "bottom-start",
          },
        }}
        multiple
        disableClearable
        size="small"
        options={uniqueOptions}
        filterOptions={filterOptions}
        value={values}
        onChange={(e, newValue) => {
          setValues(newValue);
          field.onChange(newValue);
        }}
        renderTags={() => null}
        renderInput={(params) => <TextField {...params} placeholder="Choose" />}
      />
      <Box sx={{ flexGrow: 1, mt: 1 }}>
        <Grid
          container
          columnGap={1}
          sx={{ maxHeight: 100, overflowY: "auto" }}
        >
          {values?.map((item, index) => (
            <Grid item sx={styles.renderTags}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  color: "#EF4823",
                  textTransform: "capitalize",
                }}
              >
                {item}
              </Typography>
              <CloseIcon
                color="primary"
                sx={{ fontSize: "16px" }}
                onClick={() => onDelete(index)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ModifiedAutoCompleteWithTags;
