/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
// Implementing the recipe creation form with react-hook-form
// Goal is to implement the form with only one level of fieldArray nesting

// Recipe -> Ingredient
// Recipe -> HowToSection -> HowToStep -> SemiSteps

// Idea here is that instead of having multiple nesting, we will have all of them at the same level
// We will create the hierarchy by adding the key and index of the parent to the child

// Sections will be a fieldArray
// Instructions will be a fieldArray with the id of the section in them which will help us to group them
// SemiSteps will be a fieldArray with the id of the instruction in them which will help us to group them
// But all of them will be at the same level
import { uuid } from "uuidv4";
import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
// import { useHistory } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  Box,
} from "@mui/material";
import firebase from "firebase/app";
import "firebase/firestore";
import { Autocomplete } from "@mui/material";
import {
  PhotoCamera,
  AddCircleOutline,
  Delete,
  DragIndicator,
} from "@mui/icons-material";
import ReactJson from "react-json-view";

const DEBUG = true;
// const recipeInitialValues = {
//   "@context": "",
//   "@id": "",
//   "@type": "",
//   aggregateRating: {
//     "@type": "AggregateRating",
//     ratingCount: "",
//     ratingValue: "",
//   },
//   image: [],
//   images: {},
//   creatorId: "",
//   author: {
//     "@type": "Person",
//     name: "Team RIKU",
//   },
//   nutrition: {
//     "@type": "NutritionInformation",
//     calories: "",
//     carbohydrateContent: "",
//     fatContent: "",
//     proteinContent: "",
//   },
//   difficultyType: "",
//   keywords: [],
//   name: "",
//   recipeUrl: "",
//   recipeCategory: [],
//   recipeCuisine: [],
//   recipeIngredient: [
//     {
//       ingredient: "",
//       unit: "",
//       quantity: "",
//       prepStyle: "",
//     },
//   ],
//   recipeIngredientParsed: [],
//   servings: 3,
//   recipeInstructions: [
//     {
//       id: "0",
//       "@type": "HowToSection",
//       itemListElement: [
//         {
//           id: generateId(),
//           text: "",
//           recipeIngredient: [],
//           parameters: {
//             toolsUsed: [],
//             temperature: null,
//             duration: "",
//             pro: {
//               tips: "tips",
//               cookingAction: "Heat",
//               stirring: false,
//               temperature: {
//                 value: "",
//                 unit: "c",
//               },

//               power: {
//                 value: null,
//                 unit: "watt",
//               },
//             },
//           },
//           images: [],
//           cookingStyle: "",
//           semiRequirement: "",
//         },
//       ],
//       name: "Preparation",
//       derivedIngredients: "",
//       ingredients: [],
//     },
//   ],
//   recipeInstructionsPro: [
//     {
//       id: "0",
//       "@type": "HowToSection",
//       itemListElement: [
//         {
//           id: generateId(),
//           text: "",
//           ingredients: [],
//           parameters: {
//             toolsUsed: [],
//             temperature: null,
//             duration: "",
//             pro: {
//               cookingAction: "Heat",
//               stirring: false,
//               temperature: {
//                 value: "",
//                 unit: "c",
//               },

//               power: {
//                 value: null,
//                 unit: "watt",
//               },
//             },
//           },
//           cookingStyle: "",
//           images: [],
//         },
//       ],
//       name: "Preparation",
//       derivedIngredients: "",
//       ingredients: [],
//     },
//   ],
//   mealType: [],
//   prepTime: "",
//   cookTime: "",
//   totalDispense: "",
//   recipeYield: "",
//   proMode: false,
//   suitableForDiet: [],
//   video: {
//     "@type": "VideoObject",
//     contentUrl: "",
//     description: "",
//     name: "",
//   },
//   klynkAppRecipe: false,
// };

const CreateRecipeRedo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      title: "",
      url: "",
      description: "",
      recipeIngredient: [
        {
          ingredient: "",
          quantity: "",
          unit: "",
          prepStyle: "",
        },
      ],
      recipeInstructions: [
        {
          title: "",
          instructionId: uuid(),
          index: "",
          sectionId: "",
          ingredients: [],
          tools: [],
          cookingParameters: {},
          image: "",
          semiSteps: [],
        },
      ],
      semiSteps: [
        {
          instructionId: "",
          index: "",
          semiStepId: uuid(),
          text: "",
        },
      ],
      recipeInstructionSections: [
        {
          title: "",
          index: "",
          sectionId: uuid(),
        },
      ],
      images: [],
    },
  });

  const {
    fields: ingredientFields,
    append: appendIngredient,
    remove: removeIngredient,
    move: moveIngredient,
  } = useFieldArray({
    control,
    name: "recipeIngredient",
  });

  const {
    fields: instructionFields,
    append: appendInstruction,
    remove: removeInstruction,
  } = useFieldArray({
    control,
    name: "recipeInstructions",
  });

  const {
    fields: sectionFields,
    append: appendRecipeSection,
    remove: removeRecipeSection,
  } = useFieldArray({
    control,
    name: "recipeInstructionSections",
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [recipeImages, setRecipeImages] = useState([]);
  const [instructionImages, setInstructionImages] = useState([]);
  const [recipeIngredientData, setrecipeIngredientData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [prepStylesData, setPrepStylesData] = useState([]);
  // const history = useHistory();
  const db = firebase.firestore();
  const storageRef = firebase.storage().ref();

  const url = watch("url");
  const recipeForm = watch();

  const onSubmit = (data) => {
    // Submit data to server or do other logic here
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleParseUrl = async () => {
    if (!url) {
      alert("Please enter a URL");
      return;
    }

    try {
      const response = await fetch(url);
      const html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const scriptTag = doc.querySelector('script[type="application/ld+json"]');
      if (!scriptTag) {
        throw new Error("Recipe data not found");
      }
      const recipeData = JSON.parse(scriptTag.innerHTML);
      const { name, description, image, recipeIngredient, recipeInstructions } =
        recipeData;
      setValue("name", name);
      setValue("description", description);
      setValue("recipeImages.0", image);
      let currentSection = "";
      let sectionIndex = -1;
      recipeInstructions.forEach((instruction) => {
        if (instruction["@type"] === "RecipeSection") {
          currentSection = instruction.name;
          sectionIndex++;
          setValue(`instructions.${sectionIndex}.sectionName`, currentSection);
        } else if (instruction["@type"] === "HowToStep") {
          const { text, image, itemListElement } = instruction;
          let instructionIndex = -1;
          if (itemListElement) {
            itemListElement.forEach((item) => {
              instructionIndex++;
              const { text, recipeIngredient } = item;
              setValue(
                `instructions.${sectionIndex}.steps.${instructionIndex}.step`,
                text
              );
              if (recipeIngredient) {
                const [quantity, unit, name] = recipeIngredient.split(" ");
                setValue(
                  `instructions.${sectionIndex}.steps.${instructionIndex}.ingredient.quantity`,
                  quantity
                );
                setValue(
                  `instructions.${sectionIndex}.steps.${instructionIndex}.ingredient.unit`,
                  unit
                );
                setValue(
                  `instructions.${sectionIndex}.steps.${instructionIndex}.ingredient.name`,
                  name
                );
              }
            });
          } else {
            instructionIndex++;
            setValue(
              `instructions.${sectionIndex}.steps.${instructionIndex}.step`,
              text
            );
            if (image) {
              setInstructionImages((prev) => {
                const newImages = [...prev];
                if (!newImages[sectionIndex]) {
                  newImages[sectionIndex] = [];
                }
                if (!newImages[sectionIndex][instructionIndex]) {
                  newImages[sectionIndex][instructionIndex] = [];
                }
                newImages[sectionIndex][instructionIndex].push(image);
                return newImages;
              });
            }
          }
        }
      });
      recipeIngredient.forEach((ingredient, index) => {
        const [quantity, unit, name] = ingredient.split(" ");
        setValue(`recipeIngredient.${index}.quantity`, quantity);
        setValue(`recipeIngredient.${index}.unit`, unit);
        setValue(`recipeIngredient.${index}.name`, name);
      });
    } catch (error) {
      console.error(error);
      setError("url", { message: "Failed to parse URL" });
    }
  };

  const handleRecipeImageUpload = async (event) => {
    const files = event.target.files;
    const urls = [];
    for (const file of files) {
      const snapshot = await storageRef.child(`recipes/${file.name}`).put(file);
      const url = await snapshot.ref.getDownloadURL();
      urls.push(url);
    }
    setRecipeImages([...recipeImages, ...urls]);
  };

  const handleInstructionImageUpload = async (event, index) => {
    const files = event.target.files;
    const urls = [];
    for (const file of files) {
      const snapshot = await storageRef
        .child(`instructions/${file.name}`)
        .put(file);
      const url = await snapshot.ref.getDownloadURL();
      urls.push(url);
    }
    const newInstructionImages = [...instructionImages];
    newInstructionImages[index] = [
      ...(newInstructionImages[index] || []),
      ...urls,
    ];
    setInstructionImages(newInstructionImages);
  };

  const handleDeleteRecipeImage = (index) => {
    const newRecipeImages = [...recipeImages];
    newRecipeImages.splice(index, 1);
    setRecipeImages(newRecipeImages);
  };

  const handleDeleteInstruction = (instruction) => {};

  const handleDeleteInstructionImage = (instructionIndex, imageIndex) => {
    const image = (instructionIndex, imageIndex) => {
      const newInstructionImages = [...instructionImages];
      newInstructionImages[instructionIndex].splice(imageIndex, 1);
      setInstructionImages(newInstructionImages);
    };
  };

  const handleAddIngredient = () => {
    appendIngredient({});
  };

  const handleRemoveIngredient = (index) => {
    removeIngredient(index);
  };

  const handleMoveIngredient = (fromIndex, toIndex) => {
    moveIngredient(fromIndex, toIndex);
  };

  const handleAddInstruction = (sectionId, index) => {
    appendInstruction({
      sectionId: sectionId,
      title: "",
      instructionId: uuid(),
      index: index,
      ingredients: [],
      tools: [],
      cookingParameters: {},
      image: "",
      semiSteps: [],
    });
  };

  const handleRemoveInstruction = (index) => {
    removeInstruction(index);
  };

  const handleAddRecipeSection = () => {
    appendRecipeSection({ sectionId: uuid() });
  };

  const handleRemoveRecipeSection = (index) => {
    removeRecipeSection(index);
  };

  // const moveRecipeSection = (fromIndex, toIndex) => {
  //   moveRecipeSection(fromIndex, toIndex);
  // };

  const handleGetrecipeIngredient = async (value) => {
    const snapshot = await db
      .collection("recipeIngredient")
      .orderBy("name")
      .startAt(value)
      .endAt(value + "\uf8ff")
      .get();
    const recipeIngredient = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setrecipeIngredientData(recipeIngredient);
  };

  const handleGetUnits = async () => {
    const snapshot = await db.collection("units").orderBy("name").get();
    const units = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setUnitsData(units);
  };

  const handleGetPrepStyles = async () => {
    const snapshot = await db.collection("prepStyles").orderBy("name").get();
    const prepStyles = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setPrepStylesData(prepStyles);
  };

  return (
    <Box padding={2} spacing={2}>
      <Typography variant="h4">Add Recipe</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 0 && (
          <Grid container spacing={2} style={{ height: "80vh" }}>
            <Grid item xs={12}>
              <Typography variant="h6">Meta Data</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Recipe Title"
                name="title"
                {...register("title", { required: true })}
                error={!!errors.title}
                helperText={
                  errors.title?.type === "required" && "Recipe name is required"
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Recipe Description"
                name="description"
                {...register("description", { required: true })}
                error={!!errors.description}
                helperText={
                  errors.description?.type === "required" &&
                  "Recipe description is required"
                }
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Recipe Author"
                name="author"
                {...register}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Recipe URL"
                name="url"
                {...register}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleParseUrl}
              >
                Parse Recipe from URL
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Recipe Images</Typography>
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                id="recipe-image"
                multiple
                onChange={handleRecipeImageUpload}
                hidden
              />
              <label htmlFor="recipe-image">
                <IconButton component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
              {recipeImages.map((image, index) => (
                <div key={`recipe-image-${index}`} className="image-container">
                  <img src={image} alt={`Recipe Image ${index + 1}`} />
                  <IconButton onClick={() => handleDeleteRecipeImage(index)}>
                    <Delete />
                  </IconButton>
                </div>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextStep}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}
        {currentStep === 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">recipeIngredient</Typography>
            </Grid>
            {ingredientFields.map((ingredient, index) => (
              <Grid item xs={12} key={ingredient.id}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      options={recipeIngredientData}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          setValue(
                            `recipeIngredient.${index}.name`,
                            value.name
                          );
                          setValue(`recipeIngredient.${index}.id`, value.id);
                        } else {
                          setValue(`recipeIngredient.${index}`.name, "");
                          setValue(`recipeIngredient.${index}`.id, "");
                        }
                      }}
                      onInputChange={(event, value) => {
                        handleGetrecipeIngredient(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Ingredient Name"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      label="Quantity"
                      name={`recipeIngredient.${index}.quantity`}
                      {...register(`recipeIngredient.${index}.quantity`)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      options={unitsData}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          setValue(
                            `recipeIngredient.${index}.unit`,
                            value.name
                          );
                        } else {
                          setValue(`recipeIngredient.${index}.unit`, "");
                        }
                      }}
                      onOpen={handleGetUnits}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Unit"
                          {...register}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      options={prepStylesData}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          setValue(
                            `recipeIngredient.${index}.prepStyle`,
                            value.name
                          );
                        } else {
                          setValue(`recipeIngredient.${index}.prepStyle`, "");
                        }
                      }}
                      onOpen={handleGetPrepStyles}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Prep Style"
                          {...register}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <IconButton
                      aria-label="Drag"
                      size="small"
                      style={{ marginRight: "8px" }}
                    >
                      <DragIndicator />
                    </IconButton>
                    <IconButton
                      aria-label="Delete"
                      size="small"
                      onClick={() => handleRemoveIngredient(index)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="info"
                onClick={handleAddIngredient}
              >
                <AddCircleOutline style={{ marginRight: "8px" }} />
                Add Ingredient
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="info" onClick={handleNextStep}>
                Next
              </Button>
              <Button variant="contained" color="info" onClick={handlePrevStep}>
                Back
              </Button>
            </Grid>
          </Grid>
        )}
        {currentStep === 2 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Sections</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="info"
                onClick={handleAddRecipeSection}
              >
                <AddCircleOutline style={{ marginRight: "8px" }} />
                Add Section
              </Button>
            </Grid>
            {sectionFields.map((recipeSection, sectionIndex) => (
              <Grid item xs={12} key={recipeSection.id}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      label={`Section ${sectionIndex + 1}`}
                      name={`recipeInstructionSections.${sectionIndex}.title`}
                      {...register(
                        `recipeInstructionSections.${sectionIndex}.title`,
                        {
                          required: true,
                        }
                      )}
                      error={!!errors.recipeSections?.[sectionIndex]?.title}
                      helperText={
                        errors.recipeInstructionSections?.[sectionIndex]?.title
                          ?.type === "required" &&
                        `Section ${sectionIndex + 1} is required`
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {instructionFields
                      .filter(
                        (instructionField) =>
                          instructionField.sectionId == recipeSection.sectionId
                      )
                      .map((instruction, index) => (
                        <Grid item xs={12} key={instruction.id}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={4}>
                              <TextField
                                label={`Step ${index + 1}`}
                                name={`recipeInstructions.${
                                  index + 1 * sectionIndex * 10
                                }.step`}
                                {...register(
                                  `recipeInstructions.${
                                    index + 1 * sectionIndex * 10
                                  }.step`,
                                  {
                                    required: true,
                                  }
                                )}
                                error={
                                  !!errors.recipeInstructions?.[index]?.step
                                }
                                helperText={
                                  errors.recipeInstructions?.[index]?.step
                                    ?.type === "required" &&
                                  `Step ${index + 1} is required`
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <input
                                type="file"
                                id={`instruction-image-${index}`}
                                multiple
                                onChange={(event) =>
                                  handleInstructionImageUpload(event, index)
                                }
                                hidden
                              />
                              <label htmlFor={`instruction-image-${index}`}>
                                <IconButton component="span">
                                  <PhotoCamera />
                                </IconButton>
                              </label>
                              {(instructionImages[index] || []).map(
                                (image, imageIndex) => (
                                  <div
                                    key={`instruction-image-${index}-${imageIndex}`}
                                    className="image-container"
                                  >
                                    <img
                                      src={image}
                                      alt={`Instruction Image ${
                                        imageIndex + 1
                                      }`}
                                    />
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteInstructionImage(
                                          index,
                                          imageIndex
                                        )
                                      }
                                    >
                                      <Delete />
                                    </IconButton>
                                  </div>
                                )
                              )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <IconButton
                                aria-label="Delete"
                                size="small"
                                onClick={() => handleRemoveInstruction(index)}
                              >
                                <Delete />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleAddInstruction(
                            recipeSection.sectionId,
                            sectionIndex
                          )
                        }
                      >
                        <AddCircleOutline style={{ marginRight: "8px" }} />
                        Add Instruction
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
              <Button variant="contained" color="info" onClick={handlePrevStep}>
                Back
              </Button>
            </Grid>
          </Grid>
        )}
      </form>

      {DEBUG && <ReactJson src={recipeForm} />}
    </Box>
  );
};

export default CreateRecipeRedo;
