/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import styled from "@emotion/styled";

const styles = {
  inputFieldStyle: {
    borderRadius: "8px",
  },
};

const MuiTextField = styled(TextField)(() => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    // MozAppearance: "textfield",
  },
  ".MuiInputBase-input": {
    fontFamily: "Mukta Mahee",
    fontSize: "16px",
    fontWeight: 400,
    color: "#393939",
  },
}));

const ModifiedTextField = (props) => {
  const { control, name, label, type } = props;
  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });
  const [values, setValues] = useState("");
  const toFixedWithoutZeros = (num, precision) =>
    `${Number.parseFloat(num.toFixed(precision))}`;

  const onChangeTextField = (e) => {
    let value = e.target.value;

    if (type === "number" && value?.length <= 4) {
      if (value === "") {
        field.onChange(null);
        setValues(null);
        return;
      }

      const numberValue = Number(value);
      if (!isNaN(numberValue)) {
        const formattedValue = toFixedWithoutZeros(numberValue, 2);
        setValues(formattedValue);
        field.onChange(Number(formattedValue));
      }
    } else if (type !== "number") {
      setValues(value);
      field.onChange(value);
    }
  };

  useEffect(() => {
    if (type === "number" && field.value !== undefined) {
      setValues(toFixedWithoutZeros(Number(field.value), 2));
    } else {
      setValues(field.value || "");
    }
  }, [field.value]);

  return (
    <MuiTextField
      variant={props.variant ? props.variant : "standard"}
      error={props.error}
      disabled={props.disabled}
      label={label}
      size={props.size ? props.size : "small"}
      fullWidth={props.fullWidth}
      multiline={props.multiline}
      rows={props.rows}
      style={{ ...props.style, ...styles.inputFieldStyle }}
      placeholder={props.placeholder}
      onChange={onChangeTextField} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={values} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      type={type ? type : "text"}
      InputProps={{
        disableUnderline: true,
        endAdornment: props.endAdornment,
      }}
      inputProps={props.inputProps}
    />
  );
};

export default ModifiedTextField;
