/* eslint-disable valid-typeof */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Delete, EditSharp } from "@material-ui/icons";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { entityArrayName, recipeCards } from "../../utils/constants";
import { FirebaseApi } from "../../config/firebase-config";
import { useAuth } from "../auth/auth-context";
import useEntity from "../entities/use-entity";

// Collapse component to show the recipe sections details
// Will track the variable to show the section details in the state
function CustomCollapse(props) {
  const [open, setOpen] = useState(false);
  const { title, children } = props;
  return (
    <Box padding={2}>
      <Box
        onClick={() => setOpen(!open)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h6">{open ? "-" : "+"}</Typography>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Box>
  );
}

// A component to view a single recipe
export default function ViewRecipes() {
  const { user } = useAuth();
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const { deleteEntity, addUserLogsHandler } = useEntity();
  const [recipeData, setRecipeData] = useState({});
  const { metadata, recipeIngredients, recipeSections, recipeInstructions } =
    recipeData;
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [debugMode, setDebugMode] = useState(false);

  const getRecipeById = async (entityArrayName, recipeId, setRecipe) => {
    setIsLoading(true);
    try {
      const result = {};
      for (const collectionName of entityArrayName) {
        if (collectionName === "recipeCards") {
          const doc = await FirebaseApi[collectionName].doc(recipeId).get();

          if (doc.exists) {
            result["metadata"] = {
              recipeId: doc.id,
              id: doc.id,
              ...doc.data(),
              restTimeInMins: doc.data().restTimeInMins / 60,
            };
          }
        } else {
          const data = await FirebaseApi[collectionName]
            .where("recipeId", "==", recipeId)
            .get();

          if (!data.empty) {
            result[collectionName] = [];
            data.forEach((doc) => {
              result[collectionName].push({ id: doc.id, ...doc.data() });
            });
            // Sort the array based on sectionIndex for recipeSection and instructionIndex for recipeInstruction
            if (collectionName === "recipeSections") {
              result[collectionName].sort(
                (a, b) => a.sectionIndex - b.sectionIndex
              );
            } else if (collectionName === "recipeInstructions") {
              result[collectionName].sort(
                (a, b) => a.instructionIndex - b.instructionIndex
              );
            } else if (collectionName === "cookingParameters") {
              result[collectionName].sort(
                (a, b) => a.instructionIndex - b.instructionIndex
              );
            }
          }
        }
      }
      setRecipe(result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  };

  const filteredSectionsData = recipeSections?.map((section) => {
    const instructionsForSection = recipeInstructions.filter(
      (instruction) => instruction.sectionId === section.id
    );
    return {
      sectionName: section.sectionName || section.title,
      recipeInstructions: instructionsForSection,
    };
  });

  const formatDate = (isoString) => {
    if (!isoString) {
      return "";
    }
    const date = new Date(isoString);
    if (isNaN(date)) {
      return "";
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const deleteRecipe = async () => {
    setOpenDeleteDialog(false);
    setIsLoading(true);
    await deleteEntity(recipeCards, recipeId);
    await addUserLogsHandler();
    setIsLoading(false);
    navigate(-1);
  };

  const editRecipeOpen = () => {
    navigate(`/new-recipe-creation/${recipeId}`);
  };
  useEffect(() => {
    getRecipeById(entityArrayName, recipeId, setRecipeData);
  }, [recipeId]);

  return (
    <Box padding={2}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this recipe? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            No
          </Button>
          <Button onClick={deleteRecipe} autoFocus color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        padding={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          gap: 10,
        }}
      >
        {(user?.access === "Admin" || user?.access === "Editor") && (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => editRecipeOpen()}
            style={{ margin: 5, textTransform: "capitalize" }}
          >
            <EditSharp />
            Edit
          </Button>
        )}

        {user?.access === "Admin" && (
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => setOpenDeleteDialog(true)}
            style={{ margin: 5, textTransform: "capitalize" }}
          >
            <Delete />
            Delete
          </Button>
        )}
      </Box>

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8} sm={6}>
            <Typography variant="h4">{metadata?.title}</Typography>
            <Typography variant="caption">by {metadata?.authorName}</Typography>
            <Typography variant="h6">{metadata?.recipeDescription}</Typography>
            <Typography variant="body1" color={"GrayText"}>
              Updated at :{formatDate(metadata?.updatedAt || "")}
            </Typography>
            <Typography variant="body1" color={"GrayText"}>
              Created at :{formatDate(metadata?.createdAt || "")}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={6}>
            <img
              src={metadata?.thumbnailUrl || "https://via.placeholder.com/150"}
              alt={"thumbnailUrl"}
              style={{
                width: "100%",
                height: 200,
                objectFit: "contain",
                borderRadius: "12px",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box padding={2}>
        <CustomCollapse title="Ingredients">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Ingredient</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recipeIngredients &&
                  recipeIngredients.map((ingredient, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {`${ingredient.quantity} ${ingredient.units}${
                            ingredient.prepStyles
                              ? `, ${ingredient.prepStyles}`
                              : ""
                          }`}
                        </TableCell>
                        <TableCell>{ingredient.name}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomCollapse>
      </Box>
      <Box padding={2}>
        <Typography variant="h6" color="initial">
          Instructions
        </Typography>
        {filteredSectionsData &&
          filteredSectionsData.map((section, index) => {
            const sectionName = section?.sectionName || "";
            return (
              <Box key={index}>
                <CustomCollapse
                  title={(index + 1).toString() + ". " + sectionName}
                >
                  <Box padding={2}>
                    {section.recipeInstructions &&
                      section.recipeInstructions.map((step, stepIndex) => {
                        return (
                          <Box padding={2} key={stepIndex}>
                            <Grid container spacing={2}>
                              <Grid item xs={0.5}>
                                <Typography variant="body1" color="initial">
                                  {stepIndex + 1}.
                                </Typography>
                              </Grid>
                              <Grid item xs={1.5}>
                                <img
                                  src={
                                    step.stepImageUrl ||
                                    "https://via.placeholder.com/150"
                                  }
                                  alt={step.title}
                                  style={{
                                    width: "100%",
                                    height: 100,
                                    objectFit: "cover",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={10}>
                                <Typography variant="body1" color="initial">
                                  {step?.title}
                                </Typography>
                                {debugMode && (
                                  <Typography variant="caption" color="initial">
                                    {step.action}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                  </Box>
                </CustomCollapse>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}
