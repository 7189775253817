/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import { nameValidation } from "../units/units-model";
import { thumbnailUrlValidation } from "../ingredients/ingredients-model";
import dummyImage from "../../.././src/assets/Undetected-ingredient-image.png";
const cuisinesModel = {
  name: "Cuisines",
  model: "cuisines",
  collectionName: "cuisines",
  defaultObject: {
    id: "",
    name: "",
    description: "",
    thumbnailUrl: null,
    createdAt: null,
    updatedAt: null,
  },
  fields: [
    {
      name: "thumbnailUrl",
      dataType: "String",
      inputFieldType: "image-uploader",
      label: "Thumbnail",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: thumbnailUrlValidation,
      listLayoutComponent: (props) => {
        const thumbnailUrl = props.row?.thumbnailUrl || dummyImage;
        return (
          <img
            src={thumbnailUrl}
            alt={props.row?.name || "Placeholder"}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        );
      },
    },
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150, 
      validation: nameValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{name}</span>
          </div>
        );
      },
    }, 
    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 300,
      listLayoutComponent: (props) => {
        const description = props.row?.description;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{description}</span>
          </div>
        );
      },
    },
    {
      name: "createdAt",
      dataType: "Date",
      inputFieldType: "date",
      label: "Created At",
      required: false,
      showInEditLayout: false,
      showInListLayout: false,
    },
    {
      name: "updatedAt",
      dataType: "Date",
      inputFieldType: "date",
      label: "Updated At",
      required: false,
      showInEditLayout: false,
      showInListLayout: false,
    },
  ],
  layout: [
    [{ name: "thumbnailUrl", width: 12 }],
    [{ name: "name", width: 6 }],
    [{ name: "description", width: 12 }],
  ],
  actions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
  ],
};

export default cuisinesModel;
