import React from "react";
import { Box, Button, Typography, Modal, Grid } from "@mui/material";
import PopupImage from "../assets/caution-icon.png";
import { useFormContext } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 398,
  background: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
  padding: "32px 20px 0px",
};

export default function DeleteUsedIngredientPopup(props) {
  const {
    openDeletePopup,
    setOpenDeletePopup,
    removeTaggedIngredient,
    selectIngredientId,
    setOpenMoreOptions,
  } = props;

  return (
    <Modal
      open={openDeletePopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          xs={12}
          container
          flexDirection="column"
          alignItems="center"
          rowSpacing={2}
        >
          <Grid item>
            <img src={PopupImage} alt="" />
          </Grid>
          <Grid item>
            <Typography variant="h4" sx={{ fontSize: "20px" }}>
              Are you sure you want to proceed?
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body3"
              sx={{ lineHeight: "22px", color: "#EF4823", textAlign: "center" }}
            >
              Hey creator, it seems this ingredient is associated with a step.
              By deleting it, the step will become unused.
            </Typography>
          </Grid>
        </Grid>

        <Grid width="100%" display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            sx={{ width: "48%", height: "48px" }}
            onClick={() => {
              setOpenDeletePopup(false);
              setOpenMoreOptions(false);
            }}
          >
            <Typography variant="body3" sx={{ textTransform: "none" }}>
              No
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ width: "48%", height: "48px" }}
            onClick={() => {
              removeTaggedIngredient(selectIngredientId);
              setOpenDeletePopup(false);
              setOpenMoreOptions(false);
            }}
          >
            <Typography
              variant="body3"
              sx={{ textTransform: "none", color: "#fff" }}
            >
              Yes
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
