/* eslint-disable consistent-return */

import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import ReplayIcon from "@mui/icons-material/Replay";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormContext } from "react-hook-form";
import circularSpinner from "../assets/circular-loader.gif";
import redWarning from "../assets/red-warning.png";
import {
  DeleteIconBox,
  EmptyImageBox,
  ImageBox,
  ImageUploaderContainerGrid,
  ReplaceIconBox,
} from "./styles";
import fileUpload from "../assets/file-upload.png";
import { maxImageSizeInKB } from "../constants";
import { storage } from "../../../config/firebase-config";

export default function SingleVideoUploader({
  storageLocation,
  name,
  videoUrl,
  errorMessage,
}) {
  const [uploadedVideo, setUploadedVideo] = useState("");
  const { setValue, setError, clearErrors } = useFormContext();
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [uploadingError, setUploadingError] = useState(null);

  const fileInputRef = useRef(null);

  const onFileChange = (e) => {
    setLoadingVideo(true);
    const storageLocationId = storageLocation.split("/");
    const file = e.target.files[0];

    if (file) {
      const uuid = uuidv4();
      const newVideos = {
        file,
        fileName: file.name,
        status: "CREATED",
        storageRef: storage
          .ref()
          .child(
            storageLocation +
              "/video/" +
              storageLocationId[1] +
              "--" +
              uuid +
              "--" +
              file.name
          ),
        downloadURL: URL.createObjectURL(file),
        description: "",
      };

      setUploadedVideo(newVideos);
      e.target.value = null;
    }
  };

  const handleDelete = () => {
    setUploadedVideo("");
    setValue(name, "");
    setLoadingVideo(false);
  };

  const handleUpload = () => {
    if (uploadedVideo) {
      if (uploadedVideo.file.size > maxImageSizeInKB) {
        setLoadingVideo(false);
        setError(name, {
          type: "custom",
          message: "Max allowed size is 10MB",
        });
      } else {
        setLoadingVideo(true);

        uploadedVideo.storageRef.put(uploadedVideo.file).on(
          "state_changed",
          // eslint-disable-next-line no-unused-vars
          (snapshot) => {},
          (error) => {
            setUploadingError(error);
            console.log(error, "error");
          },
          () => {
            uploadedVideo.storageRef.getDownloadURL().then((url) => {
              uploadedVideo.downloadURL = url;
              setValue(name, url);
              uploadedVideo.status = "UPLOADED";
              clearErrors(name);
              setUploadedVideo(uploadedVideo);
              setLoadingVideo(false);
            });
          }
        );
      }
    }
  };

  useEffect(() => {
    if (loadingVideo) {
      handleUpload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedVideo]);
  const LoadingComponent = () => {
    return <img src={circularSpinner} width="130px" alt="circularSpinner" />;
  };

  const renderVideoComponent = () => {
    if (loadingVideo) {
      return (
        <EmptyImageBox
          sx={{
            border: errorMessage ? "2px dashed red" : "2px dashed #A7A7A7 ",
            justifyContent: "flex-start",
            pt: 4,
            position: "relative",
          }}
        >
          <LoadingComponent />
          <Typography mb={1} mt={-2} variant="subtext2">
            Uploading your video
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "14px", color: "#7C7B79" }}
          >
            Supports MP4, AVI, MKV
          </Typography>
        </EmptyImageBox>
      );
    }
    if (errorMessage || uploadingError) {
      return (
        <EmptyImageBox
          sx={{
            border: errorMessage ? "2px dashed red" : "2px dashed #A7A7A7 ",
            position: "relative",
          }}
        >
          <img src={redWarning} height="20px" width="20px" alt="Warning" />
          <Typography my={1} variant="subtext2" color="red">
            {errorMessage || uploadingError}
          </Typography>
          <Typography variant="subtext2" sx={{ color: "#7C7B79" }}>
            Supports MP4, AVI, MKV
          </Typography>
          <Typography
            onClick={() => {
              fileInputRef.current.click();
            }}
            variant="subtext2"
            sx={{ color: "#EF4823", cursor: "pointer" }}
          >
            {uploadingError ? "Try Again" : "Upload video"}
          </Typography>
        </EmptyImageBox>
      );
    }
    if (videoUrl) {
      return (
        <ImageBox>
          <video
            width="320"
            height="240"
            controls
            style={{
              display: "block",
              objectFit: "cover",
              width: "250px",
              height: "100%",
              borderRadius: "8px",
            }}
          >
            <source src={videoUrl} type="video/mp4" />
            <track
              kind="captions"
              src=""
              srcLang="en"
              label="English"
              default
            />
            Your browser does not support the video tag.
          </video>
          <DeleteIconBox className="delete-icon" onClick={handleDelete}>
            <ClearIcon sx={{ margin: "3px" }} />
          </DeleteIconBox>
          <ReplaceIconBox
            className="replace-icon"
            display="flex"
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <ReplayIcon sx={{ fontSize: "18px", marginTop: "3px" }} />
            <Typography variant="subtext2" ml={0.6}>
              Replace
            </Typography>
          </ReplaceIconBox>
        </ImageBox>
      );
    }
    if (!uploadedVideo) {
      return (
        <EmptyImageBox
          sx={{
            border: errorMessage ? "2px dashed red" : "2px dashed #A7A7A7 ",
          }}
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          <img src={fileUpload} height="16px" width="16px" alt="Upload" />{" "}
          <Typography my={1} variant="subtext2">
            Choose Video to Upload{" "}
            {name === "metadata.thumbnailUrl" && (
              <span style={{ color: "#EF4823" }}>*</span>
            )}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "14px", color: "#7C7B79" }}
          >
            Supports MP4, AVI, MKV
          </Typography>
        </EmptyImageBox>
      );
    }
  };

  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        <ImageUploaderContainerGrid xs={3.4} item>
          <input
            type="file"
            accept="video/mp4, video/avi, video/mkv"
            onChange={onFileChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          {renderVideoComponent()}
        </ImageUploaderContainerGrid>
      </Grid>
    </Box>
  );
}
