/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// Importing necessary libraries and components
import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Grid, Box, Typography, Divider } from "@mui/material";
import RecipeInstruction from "./recipe-instruction";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "./assets/delete-icon.png";
import CloseIcon from "@mui/icons-material/Close";
import ReorderImage from "./assets/reorder-img.png";
import DeleteStepModal from "./components/delete-step-popup";
import ReorderStepsModal from "./components/reorder-steps-popup";
import AddIcon from "@mui/icons-material/Add";
import useRecipeInstructions from "./hooks/use-recipe-instructions";
import ErrorIcon from "./assets/error.png";

// Main component
export default function RecipeInstructions(props) {
  // State variables for mode sets, cooking presets and actions
  const [openDotMenu, setOpenDotMenu] = useState(false);
  const [openReorder, setOpenReorder] = useState(false);
  const [openDeleteStepPopup, setOpenDeleteStepPopup] = useState(false);
  const [instructionIndex, selectInstructionIndex] = useState("");
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const isError = errors?.recipeInstructions;

  // Destructuring sectionId from props
  const { sectionId, addSectionButton, setAddSectionButton } = props;

  // State variables for selected instruction array index and id
  const [selectedInstructionArrayIndex, setSelectedInstructionArrayIndex] =
    useState(null);
  const [selectInstruction, setSelectInstruction] = useState(null);
  const [selectedInstructionId, setSelectedInstructionId] = useState("");
  const recipeInstructions = getValues("recipeInstructions");
  const filterRecipeInstructions = recipeInstructions.filter(
    (item) => item.sectionId === sectionId
  );

  // Function to set selected instruction
  const setSelectedInstruction = (instructionId, arrayIndex) => {
    setOpenDotMenu(false);
    setSelectedInstructionArrayIndex(arrayIndex);
    setSelectedInstructionId(instructionId);
  };

  const handlingInstructionId = (id, index) => {
    setSelectedInstructionId(id, index);
  };

  const {
    addRecipeInstruction,
    removeRecipeInstruction,
    addInBetweenRecipeInstruction,
  } = useRecipeInstructions({
    setSelectedInstructionArrayIndex,
    handlingInstructionId,
  });

  useEffect(() => {
    if (addSectionButton) {
      addRecipeInstruction(sectionId);
      setAddSectionButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId, addSectionButton]);

  useEffect(() => {
    if (recipeInstructions.length > 0) {
      const arrayIndex = recipeInstructions.findIndex(
        (instruction) => instruction.id === filterRecipeInstructions[0]?.id
      );
      setSelectedInstructionId(filterRecipeInstructions[0]?.id);
      setSelectedInstruction(filterRecipeInstructions[0]?.id, arrayIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  // Rendering component
  return (
    <Grid container mt={3}>
      <ReorderStepsModal
        openReorder={openReorder}
        setOpenReorder={setOpenReorder}
        sectionId={sectionId}
      />
      <DeleteStepModal
        setSelectedInstruction={setSelectedInstruction}
        openDeleteStepPopup={openDeleteStepPopup}
        setOpenDeleteStepPopup={setOpenDeleteStepPopup}
        instructionData={selectInstruction}
        setOpenDotMenu={setOpenDotMenu}
        filterRecipeInstructions={filterRecipeInstructions}
        removeRecipeInstruction={removeRecipeInstruction}
      />
      <Grid item xs={12}>
        <Grid display="flex">
          <Grid
            item
            xs={12}
            sx={{
              padding: "5px",
              display: "flex",
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                display: "none" /* WebKit */,
              },
              overflowY: "hidden",
              height: "110px",
              width: "500px",
            }}
          >
            {/* Mapping through recipe instructions and rendering buttons for each */}
            {recipeInstructions &&
              recipeInstructions
                .filter((instruction) => instruction.sectionId == sectionId)
                .map((instruction, stepIndex) => {
                  const instructionId = instruction.id;
                  const arrayIndex = recipeInstructions.findIndex(
                    (instruction) => instruction.id === instructionId
                  );
                  return (
                    <Box
                      position="relative"
                      display="flex"
                      sx={{
                        "&:hover": {
                          ".on-add-step": {
                            display: "block",
                          },
                        },
                      }}
                    >
                      <Box
                        onClick={() =>
                          setSelectedInstruction(instruction.id, arrayIndex)
                        }
                        style={{
                          ...styles.instructionButton,
                          background:
                            instruction.id === selectedInstructionId
                              ? "#EF4823"
                              : "#F1F1F1",
                          "&:hover": {
                            opacity: 1,
                          },
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <Box>
                            {isError && isError[arrayIndex] && (
                              <img
                                src={ErrorIcon}
                                alt=""
                                style={{
                                  height: 12,
                                  width: 12,
                                  margin: "12px 0px 0px 6px",
                                }}
                              />
                            )}
                          </Box>
                          <Typography
                            variant="h7"
                            sx={{
                              fontWeight: 600,
                              color:
                                instruction.id === selectedInstructionId
                                  ? "#fff"
                                  : "#7C7B79",
                              textTransform: "upperCase",
                              padding: "10px 10px 0px",
                            }}
                          >
                            Step{" "}
                            {`${stepIndex + 1}` <= 9
                              ? `0${stepIndex + 1}`
                              : `${stepIndex + 1}`}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: "0px 10px",
                            wordBreak: "break-word",
                            fontSize: "14px",
                            height: 40,
                            overflow: "hidden",

                            color:
                              instruction.id === selectedInstructionId &&
                              "#fff",
                          }}
                        >
                          {instruction.title}
                        </Typography>
                        <Typography
                          variant="body4"
                          sx={{
                            padding: "0px 10px",

                            fontSize: "14px",

                            color:
                              instruction.id === selectedInstructionId &&
                              "#fff",
                          }}
                        >
                          {}
                        </Typography>
                      </Box>
                      {recipeInstructions?.length > 1 && (
                        <Box
                          className="on-add-step"
                          id="deleteIcon"
                          onClick={() => {
                            setOpenDotMenu(!openDotMenu);
                            selectInstructionIndex(stepIndex);
                            setSelectInstruction(instruction);
                          }}
                          sx={{
                            position: "absolute",
                            display: "none",
                            top: "5px",
                            right: "8px",
                          }}
                        >
                          {openDotMenu ? (
                            stepIndex === instructionIndex && (
                              <CloseIcon
                                sx={{
                                  color:
                                    instruction.id === selectedInstructionId
                                      ? "#fff"
                                      : "#000",
                                }}
                              />
                            )
                          ) : (
                            <MoreVertIcon
                              sx={{
                                color:
                                  instruction.id === selectedInstructionId
                                    ? "#fff"
                                    : "#000",
                              }}
                            />
                          )}
                        </Box>
                      )}

                      {openDotMenu && stepIndex === instructionIndex && (
                        <Box
                          sx={{
                            width: "133px",
                            height: "70px",
                            background: "#fff",
                            position: "absolute",
                            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
                            zIndex: 10,
                            top: "32px",
                            right: "20px",
                            borderRadius: "6px",
                          }}
                        >
                          <Grid
                            display="flex"
                            padding="8px"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpenReorder(true);
                              setOpenDotMenu(false);
                            }}
                          >
                            <img
                              src={ReorderImage}
                              alt=""
                              style={{ width: 20, height: 20 }}
                            />
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "14px" }}
                              ml={1}
                            >
                              Reorder Step
                            </Typography>
                          </Grid>
                          <Divider />
                          <Grid
                            display="flex"
                            padding="8px"
                            sx={{ cursor: "pointer" }}
                            onClick={() => setOpenDeleteStepPopup(true)}
                          >
                            <img src={DeleteIcon} alt="" />
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "14px", color: "#990000" }}
                              ml={1}
                            >
                              Delete step
                            </Typography>
                          </Grid>
                        </Box>
                      )}
                      <Box
                        className="on-add-step"
                        position="relative"
                        sx={{ cursor: "pointer", display: "none" }}
                        onClick={() =>
                          addInBetweenRecipeInstruction(sectionId, stepIndex)
                        }
                      >
                        <Box
                          sx={{
                            marginLeft: "-5px",
                            background: "#FFD2C3",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "8px",
                            height: "100%",
                          }}
                        />
                        <Box
                          sx={{
                            background: "#FFD2C3",
                            position: "absolute",
                            top: "38%",
                            right: " -9px",
                            zIndex: 10,
                            width: "24px",
                            height: "24px",
                            borderRadius: "50%",
                          }}
                        >
                          <AddIcon color="primary" />
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            <Grid item xs={2}>
              {/* Button to add a new instruction */}
              <Box
                onClick={() => {
                  addRecipeInstruction(sectionId);
                  setAddSectionButton(false);
                }}
                style={{
                  ...styles.instructionButton,
                  width: 100,
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
                }}
              >
                <Typography
                  variant="h7"
                  sx={{
                    textAlign: "center",
                    paddingTop: "25%",
                  }}
                >
                  {" "}
                  Add new step
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Rendering RecipeInstruction component for selected instruction */}
      {selectedInstructionArrayIndex !== "" && (
        <>
          {recipeInstructions &&
            recipeInstructions
              .filter((instruction) => instruction.sectionId == sectionId)
              .map((item) => {
                const instructionId = item.id;
                const arrayIndex = recipeInstructions.findIndex(
                  (instruction) => instruction.id === instructionId
                );
                if (instructionId === selectedInstructionId) {
                  // eslint-disable-next-line no-unused-expressions
                  return (
                    <RecipeInstruction
                      key={instructionId}
                      sectionId={sectionId}
                      instructionId={selectedInstructionId}
                      instructionArrayIndex={arrayIndex}
                    />
                  );
                }
              })}
        </>
      )}
    </Grid>
  );
}

// Styles object
const styles = {
  instructionButton: {
    height: 100,
    minWidth: 150,
    maxWidth: 150,
    overflowY: "hidden",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
    marginRight: 6,
    padding: 0,
  },
};
