/* eslint-disable no-unused-vars */
// This is a collection of Controlled Material UI components modified to work with react-hook-form
import React from "react";

import {
  AppBar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
  Grid,
  MenuItem,
  Collapse,
  Autocomplete,
  Divider,
  Modal,
  Chip,
  Snackbar,
  CircularProgress,
  FormLabel,
} from "@mui/material";
import { useController } from "react-hook-form";

function ModifiedAutoComplete(props) {
  const units = ["GMS", "KGS", "L", "ML"];
  const { control, name } = props;
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });
  return (
    <Autocomplete
      size={props.size ? props.size : "small"}
      disablePortal
      label={field.name}
      id={field.name}
      onChange={(evt, newValue) => field.onChange(newValue)}
      onBlur={field.onBlur}
      // onChange={field.onChange}
      name={field.name}
      inputRef={field.ref}
      freeSolo
      options={units.map((unit) => unit)}
      renderInput={(params) => <TextField {...params} fullWidth label="" />}
    />
  );
}

function ModifiedAutoCompleteWithOptions(props) {
  const { control, name, options, multiple } = props;
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });
  console.log("options", options);
  return (
    <Autocomplete
      size={props.size ? props.size : "small"}
      multiple={multiple ? multiple : false}
      disablePortal
      label={field.name}
      id={field.name}
      onChange={(evt, newValue) => field.onChange(newValue)}
      onBlur={field.onBlur}
      // onChange={field.onChange}
      name={field.name}
      ref={field.ref}
      freeSolo
      value={field.value}
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} fullWidth label="" />}
    />
  );
}

function ModifiedAutoCompleteWithOptionsObject(props) {
  const { control, name, options, multiple } = props;
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });
  console.log("options", options);
  return (
    <Autocomplete
      size={props.size ? props.size : "small"}
      multiple={multiple ? multiple : false}
      disablePortal
      label={field.name}
      id={field.name}
      onChange={(evt, newValue) => field.onChange(newValue)}
      onBlur={field.onBlur}
      // onChange={field.onChange}
      name={field.name}
      ref={field.ref}
      freeSolo
      value={field.value}
      options={options.map((option) => option.name)}
      renderInput={(params) => <TextField {...params} fullWidth label="" />}
    />
  );
}

function ModifiedTextField(props) {
  const { control, name, placeholder, label } = props;
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <TextField
      label={label}
      size={props.size ? props.size : "small"}
      fullWidth={props.fullWidth}
      multiline={props.multiline}
      rows={props.rows}
      style={props.style}
      placeholder={placeholder}
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
    />
  );
}

function ModifiedSelect(props) {
  const { name, control } = props;
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <Select
      size={props.size ? props.size : "small"}
      fullWidth
      labelId={name}
      id={name}
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      style={{ minWidth: 120, ...props.style }}
    >
      {props.children}
    </Select>
  );
}

const ControlledFields = {
  TextField: ModifiedTextField,
  Select: ModifiedSelect,
  Autocomplete: ModifiedAutoCompleteWithOptionsObject,
};

export default ControlledFields;
