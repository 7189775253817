/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Grid, Typography, Box, styled, Switch } from "@mui/material";

import ModifiedTextField from "./modified-field/modified-text-field";

const stylesheet = {
  servingsButton: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "8px",
    marginRight: "7px",
    boxShadow: " 0px 0px 4px 0px rgba(0, 0, 0, 0.05)",
    textTransform: "capitalize",
    "&:hover": {
      // border: "1px solid #EF4823 ",
      background: "#FFECE4",
    },
  },
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#000" : "#000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function CookSection(props) {
  const { control, setValue, getValues, watch } = useFormContext();
  const { sectionId } = props;
  const location = window.location.hostname;

  const recipeSections = getValues("recipeSections").filter(
    (section) => section.id === sectionId
  )[0];

  const sectionType = recipeSections?.sectionType;
  const [semiSelected, setSemiselected] = useState(sectionType);
  const [rikuSelected, setRikuselected] = useState(sectionType);

  const metaData = getValues("metadata");
  if (!props.isSelected) return null;
  const updateRecipeSection = (key, value) => {
    const updatedRecipeSections = getValues("recipeSections").map((section) => {
      if (section.id === props.sectionId) {
        return { ...section, [key]: value };
      } else {
        return section;
      }
    });
    setValue("recipeSections", updatedRecipeSections);
  };

  const cloneSection = () => {};
  const formValues = watch("recipeSections").filter(
    (section) => section.id === props.sectionId
  );

  const selectCompatibilityHandler = (index) => {
    if (index === 0) {
      setValue(`recipeSections[${props.sectionIndex}].sectionType`, "manual");
      setSemiselected("manual");
      setRikuselected("");
    } else {
      setValue(`recipeSections[${props.sectionIndex}].sectionType`, "hardware");
      setRikuselected("hardware");
      setSemiselected("");
    }
  };

  return (
    <Grid
      container
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 4,
        // padding: 5,
      }}
    >
      <Grid item xs={12}>
        <Grid xs={12} container sx={{ display: "flex", alignItems: "center" }}>
          <Grid xs={6} item>
            <Typography mb={1} variant="h3">
              Section{" "}
              {`${props.sectionIndex + 1}` <= 9
                ? `0${props.sectionIndex + 1}`
                : `${props.sectionIndex + 1}`}{" "}
              title
              <span style={{ color: "#EF4823" }}>*</span>
            </Typography>
            <ModifiedTextField
              variant="outlined"
              placeholder="Recipe section here"
              fullWidth
              size="small"
              control={control}
              name={`recipeSections.${props.sectionIndex}.title`}
            />
          </Grid>
          {metaData?.isRecipeProSelected && (
            <Grid ml={5}>
              <Typography mb={1} variant="h3">
                Type of sections <span style={{ color: "#EF4823" }}>*</span>
              </Typography>
              <Box display="flex">
                {["Standard", "Riku"].map((item, index) => (
                  <Box
                    onClick={() => selectCompatibilityHandler(index)}
                    variant="outlined"
                    sx={{
                      ...stylesheet.servingsButton,
                      border:
                        index === 0
                          ? semiSelected === "manual"
                            ? "2px solid #EF4823"
                            : "2px solid #f1f1f1"
                          : rikuSelected === "hardware"
                          ? "2px solid #EF4823"
                          : "2px solid #f1f1f1",
                      background:
                        index === 0
                          ? semiSelected === "manual" && "#FFECE4"
                          : rikuSelected === "hardware" && "#FFECE4",
                      visibility:
                        item === "riku" &&
                        location === "klynk.recipes" &&
                        "none",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        color:
                          index === 0
                            ? semiSelected === "manual" && "#EF4823"
                            : rikuSelected === "hardware" && "#EF4823",
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item mt={4}>
          <Grid
            mb={1}
            container
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="h3" mr={1}>
              Loop this section
            </Typography>

            <Controller
              name={`recipeSections[${props.sectionIndex}].isLoopSection`}
              control={control}
              render={({ field }) => (
                <IOSSwitch
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              )}
              disabled={
                formValues.isLoopSection &&
                formValues.sectionType === "hardware"
                  ? true
                  : false
              }
            />
          </Grid>
          {recipeSections.isLoopSection && (
            <Grid xs={12}>
              <ModifiedTextField
                variant="outlined"
                placeholder="Write a looping instruction, eg: If you still have any extra batter left, then repeat until its finished"
                fullWidth
                size="small"
                control={control}
                name={`recipeSections[${props.sectionIndex}].loopInstruction`}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
