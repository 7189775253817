const SERVINGS_NUMBERS = [1, 2, 3, 4, 5, 6];
const maxImageSizeInKB = 1e7;
const difficultyLevel = "easy";
const cookingParameterConstant = {
  presetId: "3cbab4e3-afdb-4bb6-9af7-bb4ce2a6b7b6",
  presetName: "Not Applicable",
  controlModeId: "6b857ca9-5bc7-46cb-bf81-23594cf88adc",
  controlModeFirmwareName: "NOT-APPLICABLE",
  controlModeFirmwareId: "FF",
  semiScreenId: 1,
  isCentralProbeTemperatureRequired: "NOT-REQUIRED",
  isExternalProbeTemperatureRequired: "NOT-REQUIRED",
  isPowerRequired: "NOT-REQUIRED",
  isTimeRequired: "NOT-REQUIRED",
};
const semiCompatible = ["Semi[EVT]", "Semi[DVT]"];
const rikuCompatible = ["Riku[EP1]"];
const heatLevel = ["low", "medium", "high"];
const manualHeatOptions = ["add", "heat", "none"];
const difficultyLevelArray = ["easy", "medium", "hard"];
const vegetarian = "vegetarian";
const nonVegetarian = "non vegetarian";
const courseName = "metadata.courses";
const dietsName = "metadata.diets";
const userLogsCollection = "userLogs";
export {
  SERVINGS_NUMBERS,
  cookingParameterConstant,
  semiCompatible,
  difficultyLevel,
  rikuCompatible,
  maxImageSizeInKB,
  heatLevel,
  manualHeatOptions,
  difficultyLevelArray,
  vegetarian,
  nonVegetarian,
  courseName,
  dietsName,
  userLogsCollection,
};
