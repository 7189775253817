/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// Side Navigator component for the app
// Acts as the main navigation for the app and first entry point after login
// All components are children of this component
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Typography,
  Collapse,
  Grid,
  Avatar,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CoffeeIcon from "@mui/icons-material/Coffee";
import QrCodeIcon from "@mui/icons-material/QrCode";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";
import TuneIcon from "@mui/icons-material/Tune";
import klynkLogo from "../assets/klynk-logo.png";
import { useAuth } from "../pages/auth/auth-context";

import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import CategoryIcon from "@mui/icons-material/Category";
import FilterListIcon from "@mui/icons-material/FilterList";
import StyleIcon from "@mui/icons-material/Style";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import EggIcon from "@mui/icons-material/Egg";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import HandymanIcon from "@mui/icons-material/Handyman";
import FlatwareIcon from "@mui/icons-material/Flatware";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import SwitchAccessShortcutAddIcon from "@mui/icons-material/SwitchAccessShortcutAdd";
import ListIcon from "@mui/icons-material/List";
import ViewListIcon from "@mui/icons-material/ViewList";
import DangerousIcon from "@mui/icons-material/Dangerous";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import StraightenIcon from "@mui/icons-material/Straighten";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import TerminalIcon from "@mui/icons-material/Terminal";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import CollectionsIcon from "@mui/icons-material/Collections";
import ContactsIcon from "@mui/icons-material/Contacts";
import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
const drawerWidth = 260;

// Single Menu Item
const SingleLevel = ({ item }) => {
  let activeStyle = {
    textDecoration: "none",
    color: "green",
  };

  let inActiveStyle = {
    textDecoration: "none",
    color: "black",
  };

  return (
    <NavLink
      to={item.path}
      style={({ isActive }) => (isActive ? activeStyle : inActiveStyle)}
    >
      <ListItem>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          primary={
            item.title === "Dashboard" ? (
              <Typography variant="subHeading1">{item.title}</Typography>
            ) : (
              <Typography variant="body1">{item.title}</Typography>
            )
          }
        />
      </ListItem>
    </NavLink>
  );
};

// Multilevel Menu
const MultiLevel = ({
  item,
  openMenuIndex,
  currentMenuIndex,
  setOpenMenuIndex,
}) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpenMenuIndex(currentMenuIndex);
      setOpen(true);
    }
  };
  // console.log("item",item)
  return (
    <React.Fragment>
      {/* <Divider /> */}
      <ListItem
        onClick={handleClick}
        style={{
          margin: 5,
          padding: 5,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomLeftRadius: open ? 0 : 5,
          borderBottomRightRadius: open ? 0 : 5,
          background: "#cecece",
          width: drawerWidth - 15,
          borderBottom: 0,
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          primary={<Typography variant="subHeading1">{item.title}</Typography>}
        />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse
        in={open && openMenuIndex === currentMenuIndex}
        timeout="auto"
        unmountOnExit
      >
        <List
          // component="div"
          style={{
            background: "#dbdbdb",
            marginLeft: 5,
            marginRight: 5,
            width: drawerWidth - 15,
            marginTop: -5,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          {children.map((child, key) => (
            <SingleLevel item={child} key={key} />
          ))}
        </List>
      </Collapse>
      {/* <Divider /> */}
    </React.Fragment>
  );
};

export default function AppSideNavigator() {
  const { user, setOpenLogoutPopup } = useAuth();

  const menuItems = [
    {
      title: "Dashboard",
      icon: <DashboardIcon />,
      path: "/",
    },
    {
      title: "Recipes",
      icon: <MenuBookIcon />,

      items: [
        ...(user?.access === "Admin" || user?.access === "Editor"
          ? [
              {
                title: "New Recipe Creation",
                path: "/new-recipe-creation",
                icon: <AddCircleIcon />,
              },
            ]
          : []),
        {
          title: "All Recipes",
          path: "/recipes",
          icon: <ListIcon />,
        },
        // {
        //   title: "Recipe Model Viewer",
        //   path: "/recipeInstructions",
        //   icon: <ViewListIcon />,
        // },
        // {
        //   title: "New Recipe(Mobile)",
        //   path: "/create-recipe-pro",
        //   icon: <AddCircleIcon />,
        // },
      ],
    },
    {
      title: "Ingredients",
      icon: <EggIcon />,
      items: [
        {
          title: "All Ingredients",
          path: "/ingredients",
          icon: <ListIcon />,
        },
      ],
    },
    {
      title: "Filters",
      icon: <FilterListIcon />,
      items: [
        {
          title: "Aisle",
          path: "/aisles",
          icon: <ShoppingBasketIcon />,
        },
        {
          title: "Food Categories",
          path: "/food-categories",
          icon: <CategoryIcon />,
        },
        // {
        //     title: "Cooking Categories",
        //     path: "/Cooking-categories",
        //     icon: <MenuBookIcon />
        // },
        {
          title: "Cuisines",
          path: "/cuisines",
          icon: <RamenDiningIcon />,
        },
        {
          title: "Courses",
          path: "/courses",
          icon: <BedtimeIcon />,
        },
        {
          title: "Medical Conditions",
          path: "/medical-conditions",
          icon: <MedicalInformationIcon />,
        },
        {
          title: "Allergies",
          path: "/allergies",
          icon: <DangerousIcon />,
        },
        {
          title: "Diets",
          path: "/diets",
          icon: <MonitorWeightIcon />,
        },
        {
          title: "Tags",
          path: "/tags",
          icon: <StyleIcon />,
        },
      ],
    },
    {
      title: "Cooking",
      icon: <SoupKitchenIcon />,
      items: [
        {
          title: "Tools",
          path: "/tools",
          icon: <FlatwareIcon />,
        },
        {
          title: "Units",
          path: "/units",
          icon: <StraightenIcon />,
        },
        {
          title: "Prep Styles",
          path: "/prep-styles",
          icon: <CallSplitIcon />,
        },
        {
          title: "Actions",
          path: "/actions",
          icon: <WorkspacesIcon />,
        },
        {
          title: "Presets",
          path: "/presets",
          icon: <SwitchAccessShortcutAddIcon />,
        },
        {
          title: "Control Modes",
          path: "/controlModes",
          icon: <TuneIcon />,
        },
      ],
    },
    {
      title: "Appliances",
      icon: <PrecisionManufacturingIcon />,
      items: [
        {
          title: "Products",
          path: "/products",
          icon: <Inventory2Icon />,
        },
        {
          title: "Releases",
          path: "/product-releases",
          icon: <NewReleasesIcon />,
        },
      ],
    },
    {
      title: "Riku",
      icon: <AdfScannerIcon />,
      items: [
        { title: "QR codes", path: "/qr-code-table", icon: <QrCodeIcon /> },
      ],
    },
    {
      title: "Semi",
      icon: <TabletMacIcon />,
      items: [
        {
          title: "WebSockets Test",
          path: "/semi-test",
          icon: <TerminalIcon />,
        },
      ],
    },
    {
      title: "App",
      icon: <AppShortcutIcon />,
      items: [
        {
          title: "Recipe Filter",
          path: "/recipe-filters",
          icon: <FilterListIcon />,
        },
        {
          title: "Recipe Filter Groups",
          path: "/recipe-filter-groups",
          icon: <AutoAwesomeIcon />,
        },
      ],
    },
    {
      title: "Creators",
      icon: <PhotoCameraFrontIcon />,
      items: [
        {
          title: "Leads",
          path: "/creator-leads",
          icon: <ContactsIcon />,
        },
      ],
    },
    user?.access === "Admin" && {
      title: "User Logs",
      icon: <PhotoCameraFrontIcon />,
      items: [
        {
          title: "All User Logs",
          path: "/user-logs",
          icon: <ListIcon />,
        },
      ],
    },
  ];

  const [openMenuIndex, setOpenMenuIndex] = useState(0);

  return (
    <Box style={{ display: "flex", justifyContent: "center", height: "100vh" }}>
      <Box
        style={{
          width: drawerWidth,
          backgroundColor: "#f5f5f5",
          color: "#000000",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box padding={2} style={{ height: 80 }}>
          <img src={klynkLogo} alt="Klynk.OS" style={{ height: "100%" }} />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Divider />
          <List>
            {menuItems.map((item, index) => {
              if (item.items) {
                return (
                  <MultiLevel
                    openMenuIndex={openMenuIndex}
                    currentMenuIndex={index}
                    setOpenMenuIndex={setOpenMenuIndex}
                    key={index}
                    item={item}
                  />
                );
              }
              return <SingleLevel key={index} item={item} />;
            })}
          </List>
        </Box>
        <Box
          padding={2}
          style={{
            display: "flex",
            alignItems: "center",
            width: drawerWidth - 32,
            background: "#f5f5f5",
          }}
        >
          <Grid container spacing={2} style={{ verticalAlign: "center" }}>
            <Grid item xs={9} style={{ display: "flex", textAlign: "center" }}>
              <Avatar
                size="small"
                alt="Remy Sharp"
                src={user ? user.photoURL : ""}
              />
              <Grid>
                <Typography
                  variant="body2"
                  color="initial"
                  style={{ textAlign: "left", marginLeft: 10 }}
                >
                  {user ? user.displayName : ""}
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ textAlign: "left", marginLeft: 10 }}
                >
                  {user ? user.access : ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <IconButton
                style={{ marginLeft: "auto" }}
                onClick={() => setOpenLogoutPopup(true)}
              >
                <LogoutIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box component="main" style={{ flex: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
