/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// This code creates a new diet object with the specified fields.
// The diet object is then added to the diets array.
// The code also specifies the default values for the fields.
import React from "react";
import { nameValidation } from "../units/units-model";
import { thumbnailUrlValidation } from "../ingredients/ingredients-model";
import dummyImage from "../../.././src/assets/Undetected-ingredient-image.png";
import {
  mixed,
  string,
  number,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from "yup";

const dietsModel = {
  name: "Diets",
  model: "diets",
  collectionName: "diets",
  defaultObject: {
    id: null,
    thumbnailUrl: null,
    name: null,
    description: null,
    createdAt: null,
    updatedAt: null,
  },
  fields: [
    {
      name: "thumbnailUrl",
      inputFieldType: "image-uploader",
      dataType: "String",
      label: "Thumbnail",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: thumbnailUrlValidation,
      validation: string().required("Thumbnail is required"),
      listLayoutComponent: (props) => {
        const thumbnailUrl = props.row?.thumbnailUrl || dummyImage;
        return (
          <img
            src={thumbnailUrl}
            alt={props.row?.name || "Placeholder"}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        );
      },
    },
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150, 
      validation: nameValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{name}</span>
          </div>
        );
      },
    }, 
    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 350, 
      listLayoutComponent: (props) => {
        const description = props.row?.description;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{description}</span>
          </div>
        );
      },
    }, 
  ],
  layout: [
    [{ name: "thumbnailUrl", width: 12 }],
    [{ name: "name", width: 6 }],
    [{ name: "description", width: 6 }],
  ],
};

export default dietsModel;
