/* eslint-disable react-hooks/exhaustive-deps */
// Handles the user authentication and provides the user object to the rest of the app
// Uses localStorage to store the user object for persistence

import React from "react";
import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./use-local-storage";
import { FirebaseApi, auth } from "../../config/firebase-config";
import firebase from "firebase";
import useEntity from "../entities/use-entity";
import { accessRoles, userAccessCollection } from "../../utils/constants";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [storedValue, setValue] = useLocalStorage("user", null);
  const [user, setUser] = useState(storedValue);
  const [openAccessPopup, setOpenAccessPopup] = useState(false);
  const [openDomainPopup, setOpenDomainPopup] = useState(false);
  const [openLogoutPopup, setOpenLogoutPopup] = useState(false);
  const navigate = useNavigate();

  const getUserAccess = async (entityName) => {
    try {
      const data = await FirebaseApi[entityName].get();
      const userAccessData = data.docs.map((item) => {
        return {
          ...item.data(),
          id: item.id,
        };
      });
      return userAccessData;
    } catch (error) {
      console.error(error);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await auth().signInWithPopup(provider);
      const { user } = result;
      const emailDomain = user?.email.split("@")[1];
      const userAccess = await getUserAccess(userAccessCollection);
      if (emailDomain === "ftlabs.io") {
        if (userAccess?.length > 0) {
          let userAccessRole = null;
          for (const role of accessRoles) {
            const roleInfo = userAccess?.find((item) => item.id === role);
            if (roleInfo) {
              const userInRole = roleInfo?.users?.find(
                (val) => val.email === user.email
              );
              if (userInRole) {
                userAccessRole = { ...userInRole, access: role };
                break;
              }
            }
          }
          if (userAccessRole) {
            const userObject = {
              displayName: user.displayName,
              userId: user.uid,
              access: userAccessRole?.access,
              email: user.email,
              photoURL: user.photoURL,
            };
            setUser(userObject);
            setValue(userObject);
            navigate("/", { replace: true });
          } else {
            setOpenAccessPopup(true);

            navigate("/login");
          }
        }
      } else {
        setOpenDomainPopup(true);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  // call this function to sign out logged in user
  const confirmLogout = async () => {
    await auth().signOut();
    setValue(null);
    setOpenLogoutPopup(false);
    navigate("/login", { replace: true });
  };
  // This function using for to update real time changes whenever access got change
  const onAuthStateChanged = () => {
    try {
      auth().onAuthStateChanged(async (user) => {
        if (!user) return;

        const emailDomain = user.email.split("@")[1];
        const userAccess = await getUserAccess(userAccessCollection);

        if (emailDomain === "ftlabs.io") {
          if (userAccess.length > 0) {
            let userAccessRole = null;

            for (const role of accessRoles) {
              const roleInfo = userAccess?.find((item) => item.id === role);

              if (roleInfo) {
                const userInRole = roleInfo?.users?.find(
                  (val) => val.email === user.email
                );

                if (userInRole) {
                  userAccessRole = { ...userInRole, access: role };
                  break; // Exit the loop if the user is found in an authorized role
                }
              }
            }

            if (userAccessRole) {
              const userObject = {
                displayName: user.displayName,
                userId: user.uid,
                access: userAccessRole.access,
                email: user.email,
                photoURL: user.photoURL,
              };

              setUser(userObject);
              navigate("/", { replace: true });
            } else {
              // User is not in an authorized role
              setOpenAccessPopup(true);

              navigate("/login");
            }
          }
        } else {
          setOpenDomainPopup(true);
        }
      });
    } catch (e) {
      console.error(e, "in onAuthStateChanged");
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const value = useMemo(
    () => ({
      user,
      signInWithGoogle,
      setOpenLogoutPopup,
    }),
    [user]
  );
  return (
    <AuthContext.Provider value={value}>
      {children}
      <Dialog open={openAccessPopup} onClose={() => setOpenAccessPopup(false)}>
        <DialogTitle>Access Denied</DialogTitle>
        <DialogContent>
          <p>
            You don't have access to open this Web app. Kindly request
            permission from an admin.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAccessPopup(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDomainPopup} onClose={() => setOpenDomainPopup(false)}>
        <DialogTitle>Unauthorized Domain</DialogTitle>
        <DialogContent>
          <p>
            You are not authorized to log in. You are not a Futuristic Lab user.
            Please log in using a Futuristic Lab email.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDomainPopup(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openLogoutPopup} onClose={() => setOpenLogoutPopup(false)}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to log out?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmLogout}>Yes</Button>
          <Button onClick={() => setOpenLogoutPopup(false)}>No</Button>
        </DialogActions>
      </Dialog>
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
