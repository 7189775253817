/* eslint-disable no-unused-vars */
import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import styled from "@emotion/styled";

const styles = {
  inputFieldStyle: {
    borderRadius: "8px",
  },
};

const MuiTextField = styled(TextField)(() => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    // MozAppearance: "textfield",
  },
}));

const ModifiedTextField = (props) => {
  const {
    control,
    name,

    label,

    probeTemperature,
    setValue,
    sectionIndex,
    type,
  } = props;

  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  const onChangeTextField = (e, newValue) => {
    if (type === "number" && e.target?.value?.length <= 3) {
      field.onChange(e.target.value);
    } else if (type !== "number") {
      field.onChange(e.target.value);

      if (probeTemperature) {
        setValue(
          `recipeSections.${sectionIndex}.isProbeRequired`,
          e.target.value > 0 ? true : false
        );
      }
    }
  };

  return (
    <MuiTextField
      variant={props.variant ? props.variant : "standard"}
      disabled={props.disabled}
      label={label}
      size={props.size ? props.size : "small"}
      fullWidth={props.fullWidth}
      multiline={props.multiline}
      rows={props.rows}
      style={{ ...props.style, ...styles.inputFieldStyle }}
      placeholder={props.placeholder}
      onChange={onChangeTextField} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      type={type ? type : "text"}
      InputProps={{
        disableUnderline: true,
        endAdornment: props.endAdornment,
      }}
      inputProps={type === "number" && { max: 3 }}
    />
  );
};

export default ModifiedTextField;
