import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useGlobalData } from "../../config/global-data-context";
import { rikuCompatible, semiCompatible } from "../../utils/constants";

export default function Dashboard() {
  const {
    recipeData,
    monthWiseRecipes,
    weekWiseRecipes,
    monthWiseDeletedRecipes,
    weekWiseDeletedRecipes,
  } = useGlobalData();
  const maunalRecipes = recipeData.filter(
    (item) =>
      Array.isArray(item?.productReleases) &&
      item?.productReleases?.length === 0
  );

  const semiRecipes = recipeData.filter(
    (item) =>
      Array.isArray(item?.productReleases) &&
      item?.productReleases?.some((release) => semiCompatible.includes(release))
  );

  const rikuRecipes = recipeData.filter(
    (item) =>
      Array.isArray(item?.productReleases) &&
      item?.productReleases?.some((release) => rikuCompatible.includes(release))
  );
  const verifiedByKlynk = recipeData.filter((item) => item?.isVerifiedByKlynk);

  const dashboard = {
    "Total Recipes": recipeData?.length || 0,
    "Manual Recipes": maunalRecipes?.length || 0,
    "SEMI Recipes": semiRecipes?.length || 0,
    "RIKU Recipes": rikuRecipes?.length || 0,
    "Total Recipes verified by Klynk": verifiedByKlynk?.length || 0,
    "Total Recipes added current month": monthWiseRecipes || 0,
    "Total Recipes removed current month": monthWiseDeletedRecipes || 0,
    "Total Recipes added current week": weekWiseRecipes || 0,
    "Total Recipes removed current week": weekWiseDeletedRecipes || 0,
  };

  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">Dashboard</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        {Object.keys(dashboard).map((item, index) => {
          return (
            <Grid item xs={4} key={index}>
              <Box
                style={{
                  height: 100,
                  backgroundColor: "#f5f5f5",
                  borderRadius: 2,
                  padding: 2,
                }}
              >
                <Typography variant="h5" color="primary">
                  {dashboard[item]}
                </Typography>
                <Typography variant="body2" color="primary">
                  {item}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
