/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from "react";
import { FirebaseApi } from "../../../config/firebase-config";
import { useNavigate } from "react-router-dom";

export default function useEntity() {
  const [loading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const navigate = useNavigate();
  const entityArrayName = [
    "recipeCards",
    "recipeIngredients",
    "recipeSections",
    "recipeInstructions",
    "cookingParameters",
  ];
  const allowedItems = [
    "recipeIngredients",
    "recipeSections",
    "recipeInstructions",
    "cookingParameters",
  ];

  // List of entities
  const getEntities = async (entityName, localSetter) => {
    setLoading(true);
    setLoadingMessage("Loading... " + entityName + "...");
    try {
      const data = await FirebaseApi[entityName].get();
      const parsedData = data.docs.map((item) => {
        return {
          ...item.data(),
          id: item.id,
          // updatedAt: item.data().updatedAt
          //   ? item.data().updatedAt.toDate()
          //   : "",
          // createdAt:
          //   item.data().createdAt &&
          //   item.data().createdAt.hasOwnProperty("toDate")
          //     ? item.data().createdAt.toDate()
          //     : "",
        };
      });
      localSetter(parsedData);
      setLoadingMessage("Done!");
      setLoading(false);
    } catch (error) {
      setLoadingMessage("Error in loading data!");
      setLoading(false);
    }
  };

  // List of entities
  const getEntitiesAsArray = async (entityName) => {
    setLoading(true);
    setLoadingMessage("Loading... " + entityName + "...");
    try {
      const data = await FirebaseApi[entityName].get();

      const parsedData = data.docs.map((item) => {
        return {
          ...item.data(),
          id: item.id,
          // updatedAt: item.data().updatedAt
          //   ? item.data().updatedAt.toDate()
          //   : "",
          // createdAt: item.data().createdAt
          //   ? item.data().createdAt.toDate()
          //   : "",
        };
      });
      setLoading(false);
      setLoadingMessage("Done!");
      return parsedData;
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error in loading data!");
      setLoading(false);
      return [];
    }
  };

  // Get a single entity by id
  const getEntity = async (entityName, entityId, localSetter) => {
    setLoading(true);
    setLoadingMessage("Loading... " + entityName + "...");
    try {
      const doc = await FirebaseApi[entityName].doc(entityId).get();
      // console.log(doc);
      let docData = doc.data();
      let data = {
        ...docData,
        id: doc.id,
        // updatedAt:
        //   docData && docData.updatedAt ? docData.updatedAt.toDate() : "",
        // createdAt:
        //   docData && docData.createdAt ? docData.createdAt.toDate() : "",
      };
      // console.log("data from getEntity", data);
      localSetter(data);
      setLoadingMessage("Done!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error in loading data!");
      setLoading(false);
    }
  };

  // Get a single entity by id
  const getEntityByKey = async (entityName, key, keyValue, localSetter) => {
    setLoading(true);
    setLoadingMessage("Loading... " + entityName + "...");
    try {
      const data = await FirebaseApi[entityName]
        .where(key, "==", keyValue)
        .get();
      const parsedData = data.docs.map((item) => {
        return {
          ...item.data(),
          id: item.id,
          // updatedAt: item.data().updatedAt
          //   ? item.data().updatedAt.toDate()
          //   : "",
          // createdAt: item.data().createdAt
          //   ? item.data().createdAt.toDate()
          //   : "",
        };
      });
      if (parsedData.length > 0) {
        localSetter(parsedData[0]);
      } else {
        localSetter({});
      }
      // localSetter(parsedData)
      setLoadingMessage("Done!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error in loading data!");
      setLoading(false);
    }
  };

  // Get a multiple entity by a specific key
  const getEntitiesByKey = async (entityName, key, keyValue, localSetter) => {
    setLoading(true);
    setLoadingMessage("Loading... " + entityName + "...");
    try {
      const data = await FirebaseApi[entityName]
        .where(key, "==", keyValue)
        .get();
      const parsedData = data.docs.map((item) => {
        return {
          ...item.data(),
          // id: item.id,
          // updatedAt: item.data().updatedAt
          //   ? item.data().updatedAt.toDate()
          //   : "",
          // createdAt: item.data().createdAt
          //   ? item.data().createdAt.toDate()
          //   : "",
        };
      });
      localSetter(parsedData);
      setLoadingMessage("Done!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error in loading data!");
      setLoading(false);
    }
  };

  // Save a new entity
  const addEntity = async (entityName, entityData, localSetter) => {
    setLoading(true);
    setLoadingMessage("Saving... " + entityName + "...");
    try {
      // entityData["createdAt"] = new Date().toISOString();
      entityData["updatedAt"] = new Date().toISOString();
      const docRef = await FirebaseApi[entityName]
        .doc(entityData.id)
        .set(entityData);
      localSetter({
        ...entityData,
        id: docRef.id,
        updatedAt: entityData.updatedAt ? entityData.updatedAt : "",
        createdAt: entityData.createdAt ? entityData.createdAt : "",
      });
      setLoadingMessage("Done! refresh to see changes");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Erorr!");
      setLoading(false);
    }
  };

  // Update an entity
  const updateEntity = async (entityName, entityData) => {
    // console.log("use entity", entityName, entityData);
    setLoading(true);
    setLoadingMessage("Updating... " + entityName + " : " + entityData.id);
    try {
      entityData["updatedAt"] = new Date().toISOString();
      const data = await FirebaseApi[entityName]
        .doc(entityData.id)
        .update(entityData);

      setLoadingMessage("Done! refresh to see changes");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error not updated!");
      setLoading(false);
    }
  };

  // Update an entity by sender Id
  const updateEntityBySenderId = async (entityName, entityData, senderId) => {
    setLoading(true);
    setLoadingMessage("Updating... " + entityName + "...");
    try {
      entityData["updatedAt"] = new Date().toISOString();
      const data = await FirebaseApi[entityName].doc(senderId).set(entityData);
      setLoadingMessage("Done! refresh to see changes");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error not updated!");
      setLoading(false);
    }
  };

  // add an entity by sender Id
  const addEntityBySenderId = async (entityName, entityData, senderId) => {
    setLoading(true);
    setLoadingMessage("Updating... " + entityName + "...");
    try {
      entityData["createdAt"] = new Date().toISOString();
      entityData["updatedAt"] = new Date().toISOString();
      const data = await FirebaseApi[entityName].doc(senderId).set(entityData);
      setLoadingMessage("Done! refresh to see changes");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error not updated!");
      setLoading(false);
    }
  };

  // Delete an entity in collections.
  const deleteEntityInDifferentCollection = async (entityId) => {
    setLoading(true);
    setLoadingMessage("Deleting recipe...");
    try {
      setLoadingMessage("Deleting recipes..." + entityId);
      const data = await FirebaseApi[entityArrayName[0]].doc(entityId).delete();
      const data1 = await FirebaseApi[entityArrayName[1]].where(
        "recipeId",
        "==",
        entityId
      );
      data1.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
      const data2 = await FirebaseApi[entityArrayName[2]].where(
        "recipeId",
        "==",
        entityId
      );
      data2.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
      const data3 = await FirebaseApi[entityArrayName[3]].where(
        "recipeId",
        "==",
        entityId
      );
      data3.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
      const data4 = await FirebaseApi[entityArrayName[4]].where(
        "recipeId",
        "==",
        entityId
      );
      data4.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
      setLoadingMessage("Done! .. refresh to see changes");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error not deleted!");
      setLoading(false);
    }
  };

  // Delete an entity
  const deleteEntity = async (entityName, entityId) => {
    setLoading(true);
    setLoadingMessage("Deleting... " + entityName + "...");
    try {
      setLoadingMessage("Deleting... " + entityName + "..." + entityId);
      const data = await FirebaseApi[entityName].doc(entityId).delete();
      setLoadingMessage("Done! .. refresh to see changes");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error not deleted!");
      setLoading(false);
    }
  };

  // Delete multiple entities
  const deleteEntities = async (entityName, entityIds) => {
    setLoading(true);
    entityIds.forEach((element) => {
      setLoadingMessage("Deleting... " + entityName + element + "...");
      deleteEntity(entityName, element);
    });
    setLoadingMessage("Done! refresh to see changes");
    setLoading(false);
  };

  const getExistingDataByRecipeId = async (entityName, recipeId) => {
    const snapshot = await FirebaseApi[entityName]
      .where("recipeId", "==", recipeId)
      .get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  const entityMappingToDifferentCollections = async (
    entityName,
    entityData,
    status,
    recipeId
  ) => {
    const existingData = await getExistingDataByRecipeId(entityName, recipeId);
    const existingIds = existingData.map((item) => item.id);
    const newIds = entityData.map((item) => item.id);

    // Identify documents to delete
    const idsToDelete = existingIds.filter((id) => !newIds.includes(id));

    if (status) {
      for (const entityDataItem of entityData) {
        await updateEntityBySenderId(
          entityName,
          entityDataItem,
          entityDataItem.id
        );
      }
      for (const id of idsToDelete) {
        await deleteEntity(entityName, id);
      }
    } else {
      for (const entityDataItem of entityData) {
        await addEntityBySenderId(
          entityName,
          entityDataItem,
          entityDataItem.id
        );
      }
    }
  };

  const entityByDifferentCollections = async (
    entityName,
    data,
    status,
    setIsLoading
  ) => {
    setIsLoading(true);
    const recipeId = data.metadata.recipeId;

    const processItem = async (item) => {
      if (item === "metadata") {
        const entityData = {
          ...data[item],
          recipeIngredientNames: data?.recipeIngredientNames,
          recipeIngredientsLength: data?.recipeIngredientsLength,
          restTimeInMins: data?.restTimeInMins,
        };
        if (status) {
          await updateEntityBySenderId(
            entityName[0],
            entityData,
            data[item].recipeId
          );
        } else {
          await addEntityBySenderId(
            entityName[0],
            entityData,
            data[item].recipeId
          );
        }
      } else if (allowedItems.includes(item)) {
        await entityMappingToDifferentCollections(
          item,
          data[item],
          status,
          recipeId
        );
      }
    };
    await Promise.all(Object.keys(data).map(processItem));
    setIsLoading(false);
    navigate("/recipes");
  };

  return {
    loading,
    loadingMessage,
    getEntities,
    getEntitiesByKey,
    getEntityByKey,
    getEntitiesAsArray,
    getEntity,
    addEntity,
    updateEntity,
    deleteEntityInDifferentCollection,
    deleteEntity,
    deleteEntities,
    entityByDifferentCollections,
  };
}
