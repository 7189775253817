import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useController } from "react-hook-form";

const capitalizeFirstLetter = (string) => {
  if (typeof string !== "string" || string.length === 0) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const ControlledSelect = (props) => {
  const {
    control,
    name,
    options = [],
    multiple = false,
    label,
    size = "small",
    error,
    helperText,
    placeholder,
  } = props;

  const {
    field,
    fieldState: { error: fieldError },
  } = useController({
    name,
    control,
    defaultValue: multiple ? [] : "",
  });

  const handleChange = (event) => {
    const { value } = event.target;
    const capitalizedValue = multiple
      ? value.map((v) => capitalizeFirstLetter(v))
      : capitalizeFirstLetter(value);
    field.onChange(capitalizedValue);
  };

  const renderValue = (selected) => {
    if (selected === "" || (Array.isArray(selected) && selected.length === 0)) {
      return <em>{placeholder}</em>;
    }
    return Array.isArray(selected)
      ? selected.map((v) => capitalizeFirstLetter(v)).join(", ")
      : capitalizeFirstLetter(selected);
  };

  return (
    <FormControl fullWidth error={!!error || !!fieldError}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select
        {...field}
        onChange={handleChange}
        size={size}
        label={label}
        id={field.name}
        fullWidth
        multiple={multiple}
        value={field.value || (multiple ? [] : "")}
        displayEmpty
        renderValue={renderValue}
      >
        {!multiple && (
          <MenuItem value="" disabled>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem key={`${option}-${index}`} value={option}>
            {capitalizeFirstLetter(String(option))}
          </MenuItem>
        ))}
      </Select>
      {(helperText || fieldError) && (
        <FormHelperText>{helperText || fieldError?.message}</FormHelperText>
      )}
    </FormControl>
  );
};

ControlledSelect.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
};

export default ControlledSelect;
