import React from "react";
import * as Yup from "yup";
import {
  titlesValidation,
  recipeListValidation,
} from "../recipe-filters/recipe-filters-model";
import { firestore } from "../../config/firebase-config";
import dummyImage from "../../.././src/assets/Undetected-ingredient-image.png";
import { thumbnailUrlValidation } from "../ingredients/ingredients-model";

const layoutIndexSchema = Yup.number()
  .integer("Index must be a whole number")
  .typeError("Index must be a number")
  .positive("Index must be positive")
  .required("Required")
  .test("is-unique", "Index must be unique", async function (value) {
    return await isIndexUnique(value);
  });

const isIndexUnique = async (value) => {
  try {
    // Query Firebase to check if a document with the same layoutIndex exists
    const snapshot = await firestore
      .collection("recipeFilterGroups")
      .where("layoutIndex", "==", value)
      .get();

    // If there are no documents with the same layoutIndex, return true (unique)
    return snapshot.empty;
  } catch (error) {
    console.error("Error checking layoutIndex uniqueness:", error);
    throw error;
  }
};

const layoutOptions = [
  {
    value: "thumbnail-and-text-vertical-with-background",
    label: "Thumbnail and Text Vertical with Background",
    imageUrl: "/customimages/mozarella.jpg",
  },
  {
    value: "thumbnail-and-text-horizontal-with-background",
    label: "Thumbnail and Text Horizontal with Background",
    imageUrl: "/customimages/thumbnail-and-text-horizantal-with-background.png",
  },
  {
    value: "thumbnail-and-text-vertical-no-background",
    label: "Thumbnail and Text Vertical No Background",
    imageUrl: "/customimages/thumbnail-and-text-vertical-with-nobackground.png",
  },
  {
    value: "text-with-background",
    label: "Text with Background",
    imageUrl: "/customimages/text-with-background.png",
  },
  {
    value: "sorted-recipe-card-collection",
    label: "Sorted Recipe Card Collection",
    imageUrl: "/customimages/mozarella.jpg",
  },
  {
    value: "filtered-recipe-card-collection",
    label: "Filtered Recipe Card Collection",
    imageUrl: "/customimages/mozarella.jpg",
  },
];

const recipeFilterGroupsModel = {
  name: "Recipe Filter Groups",
  model: "recipeFilterGroups",
  collectionName: "recipeFilterGroups",
  defaultObject: () => ({
    id: null,
    thumbnailUrl: null,
    title: null,
    subTitle: null,
    isActive: false,
    recipeFilters: [],
    layoutIndex: 0,
    filterGroupLayoutStyle: "recipe-card-collection",
    createdAt: null,
    updatedAt: null,
  }),
  fields: [
    {
      name: "layoutIndex",
      label: "Index",
      showInListLayout: true,
      listLayoutWidth: 75,
      // dataType: "Number",
      // inputFieldType: "noChar-text",
      // showInEditLayout: false,
      // editLayoutWidth: 6,
      // validation: layoutIndexSchema,
    },
    {
      name: "thumbnailUrl",
      dataType: "String",
      inputFieldType: "image-uploader",
      label: "Thumbnail",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 100,
      validation: thumbnailUrlValidation,
      listLayoutComponent: (props) => {
        const thumbnailUrl = props.row?.thumbnailUrl || dummyImage;
        return (
          <img
            src={thumbnailUrl}
            alt={props.row?.name || "Placeholder"}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = dummyImage;
            }}
          />
        );
      },
    },  
    {
      name: "title",
      dataType: "String",
      inputFieldType: "text",
      label: "Title",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 230,
      validation: titlesValidation,
      listLayoutComponent: (props) => {
        const title = props.row?.title;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{title}</span>
          </div>
        );
      },
    },
    {
      name: "subTitle",
      dataType: "String",
      inputFieldType: "subtitle-text",
      label: "SubTitle",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 230,
      validation: titlesValidation,
      listLayoutComponent: (props) => {
        const subTitle = props.row?.subTitle;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{subTitle}</span>
          </div>
        );
      },
    },
    {
      name: "filterGroupLayoutStyle",
      dataType: "String",
      inputFieldType: "img-select",
      label: "Group Layout",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 250,
      options: layoutOptions,
      imageKey: "imageUrl",
      listLayoutComponent: (props) => {
        const filterGroupLayoutStyle = props.row?.filterGroupLayoutStyle;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{filterGroupLayoutStyle}</span>
          </div>
        );
      },
    },
    {
      name: "recipeFilters",
      dataType: "Array",
      inputFieldType: "autocomplete",
      label: "RecipeFilters",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 450,
      multiple: true,
      collectionName: "recipeFilters",
      validation: recipeListValidation,
      collectionLookupKey: "title",
      listLayoutComponent: (props) => {
        return (
          <div
            style={{
              maxWidth: "100%",
              overflowX: "auto",
              whiteSpace: "nowrap",
              display: "flex",
              gap: "4px",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            {props.row?.recipeFilters?.map((recipeFilter, index) => (
              <React.Fragment key={recipeFilter.id}>
                {index > 0 && ", "}
                <div>{recipeFilter.title}</div>
              </React.Fragment>
            ))}
          </div>
        );
      },
    },
    {
      name: "createdAt",
      dataType: "Timestamp",
      inputFieldType: "DateTimePicker",
      label: "Created At",
      showInEditLayout: false,
      editLayoutWidth: 6,
      showInListLayout: false,
    },
    {
      name: "updatedAt",
      dataType: "Timestamp",
      inputFieldType: "DateTimePicker",
      label: "Updated At",
      showInEditLayout: false,
      editLayoutWidth: 6,
      showInListLayout: false,
    },
  ],
  layout: [
    [
      { name: "thumbnailUrl", width: 12 },
      // { name: "layoutIndex", width: 3 },
      { name: "title", width: 6 },
      { name: "subTitle", width: 12 },
      { name: "filterGroupLayoutStyle", width: 6 },
      { name: "recipeFilters", width: 12 },
    ],
  ],
  permittedActions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
    {
      name: "edit",
      label: "Edit",
      type: "edit",
      icon: "edit",
      color: "primary",
    },
    {
      name: "delete",
      label: "Delete",
      type: "delete",
      icon: "delete",
      color: "primary",
    },
  ],
};

export default recipeFilterGroupsModel;
