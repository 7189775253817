/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { useController } from "react-hook-form";
import { cookingParameterConstant } from "../constants";

const stylesObject = {
  ListboxPropsStyles: {
    maxHeight: "20vh",
    color: "#1C1C1C",
    fontFamily: "Mukta Mahee",
    fontSize: "14px",
    fontWeight: 500,
    background: "#F7F7F7",
    position: "bottom-start",
  },
  muiTextFieldStyle: {
    ".MuiInputBase-input": {
      fontFamily: "Mukta Mahee",
      fontSize: "16px",
      fontWeight: 400,
      color: "#393939",
    },
  },
};

export const settingManualHeatLevel = (action) => {
  let {
    controlModeName,
    centralTemperatureInC,
    powerInLevel,
    externalTemperatureInC,
  } = action;

  controlModeName = controlModeName ?? "";
  centralTemperatureInC = centralTemperatureInC ?? "";
  powerInLevel = powerInLevel ?? "";
  externalTemperatureInC = externalTemperatureInC ?? "";

  switch (controlModeName?.split("-").join(" ").toLowerCase()) {
    case "simmer":
    case "simmer milk":
    case "steam":
    case "heat oil":
    case "heat mode":
    case "spice temp":
    case "sous vide":
      return "low";

    case "deep fry":
    case "flat bread":
    case "pre heat":
      return "medium";

    case "boil":
    case "stir fry":
    case "pressure cook":
    case "shallow fry":
    case "searing":
      return "high";

    case "heat(temperature)":
      if (
        Number(centralTemperatureInC) >= 0 &&
        Number(centralTemperatureInC < 100)
      ) {
        return "low";
      }
      if (
        Number(centralTemperatureInC) >= 100 &&
        Number(centralTemperatureInC < 200)
      ) {
        return "medium";
      }
      if (Number(centralTemperatureInC >= 200)) {
        return "high";
      }
      break;

    case "probeTemperature":
      if (
        Number(externalTemperatureInC) >= 0 &&
        Number(externalTemperatureInC) < 100
      ) {
        return "low";
      }
      if (
        Number(externalTemperatureInC) >= 100 &&
        Number(externalTemperatureInC) < 200
      ) {
        return "medium";
      }
      if (Number(externalTemperatureInC) >= 200) {
        return "high";
      }
      break;

    case "heat(power)":
      if (Number(powerInLevel) >= 1 && Number(powerInLevel) < 3) {
        return "low";
      }
      if (Number(powerInLevel) >= 3 && Number(powerInLevel) < 6) {
        return "medium";
      }
      if (Number(powerInLevel) >= 7 && Number(powerInLevel) <= 10) {
        return "high";
      }
      break;

    default:
      return "";
  }
};

export const selectionPreset = (
  selectedPreset,
  foundModeSet,
  setValue,
  getValues,
  instructionArrayIndex,
  cookingAction,
  clearErrors,
  sectionId
) => {
  const {
    presetId,
    presetName,
    controlModeId,
    controlModeFirmwareName,
    controlModeFirmwareId,
    semiScreenId,
    isCentralProbeTemperatureRequired,
    isExternalProbeTemperatureRequired,
    isPowerRequired,
    isTimeRequired,
  } = cookingParameterConstant;
  const recipeSections = getValues("recipeSections");
  const recipeSectionsIndex = recipeSections.findIndex(
    (item) => item.id === sectionId
  );
  if (cookingAction) {
    if (foundModeSet?.isExternalProbeTemperatureRequired === "REQUIRED") {
      setValue(`recipeSections.${recipeSectionsIndex}.isProbeRequired`, true);
      setValue(`metadata.isProbeRequired`, true);
    } else {
      setValue(`recipeSections.${recipeSectionsIndex}.isProbeRequired`, false);
      setValue(`metadata.isProbeRequired`, false);
    }
    // setting Presets
    setValue(
      `cookingParameters.${instructionArrayIndex}.actionId`,
      selectedPreset?.actionId
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.presetId`,
      selectedPreset?.id
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.presetName`,
      selectedPreset?.primaryName
    );
    // central temperature

    setValue(
      `cookingParameters.${instructionArrayIndex}.centralTemperatureInC`,
      selectedPreset?.centralTemperatureInC || ""
    );

    // external temperature

    setValue(
      `cookingParameters.${instructionArrayIndex}.externalTemperatureInC`,
      selectedPreset?.externalTemperatureInC || ""
    );

    // power

    setValue(
      `cookingParameters.${instructionArrayIndex}.powerInLevel`,
      selectedPreset?.powerInLevel || ""
    );

    // duration/timer
    setValue(
      `cookingParameters.${instructionArrayIndex}.durationInMins`,
      selectedPreset?.timeInMinutes || ""
    );

    // setting manualHeatLevel
    const manualHeatLevel = settingManualHeatLevel(selectedPreset || {});

    setValue(
      `cookingParameters.${instructionArrayIndex}.manualHeatLevel`,
      manualHeatLevel
    );

    // Setting modeSets

    setValue(
      `cookingParameters.${instructionArrayIndex}.controlModeFirmwareId`,
      foundModeSet?.firmwareId
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.controlModeFirmwareName`,
      foundModeSet?.firmwareName
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.semiScreenId`,
      Number(foundModeSet?.semiScreenId)
    );

    setValue(
      `cookingParameters.${instructionArrayIndex}.controlModeId`,
      foundModeSet?.id
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.isCentralProbeTemperatureRequired`,
      foundModeSet?.isCentralProbeTemperatureRequired
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.isExternalProbeTemperatureRequired`,
      foundModeSet?.isExternalProbeTemperatureRequired
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.isPowerRequired`,
      foundModeSet?.isPowerRequired
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.isTimeRequired`,
      foundModeSet?.isTimeRequired
    );
  } else {
    setValue(`recipeSections.${recipeSectionsIndex}.isProbeRequired`, false);
    setValue(`metadata.isProbeRequired`, false);

    clearErrors(`cookingParameters.${instructionArrayIndex}`);
    // Removing set Presets
    setValue(`cookingParameters.${instructionArrayIndex}.actionId`, "");
    setValue(`cookingParameters.${instructionArrayIndex}.presetId`, presetId);
    setValue(
      `cookingParameters.${instructionArrayIndex}.presetName`,
      presetName
    );

    // Removing Set Presets Parameters

    setValue(
      `cookingParameters.${instructionArrayIndex}.centralTemperatureInC`,
      0
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.externalTemperatureInC`,
      0
    );
    setValue(`cookingParameters.${instructionArrayIndex}.powerInLevel`, 0);
    setValue(`cookingParameters.${instructionArrayIndex}.durationInMins`, 0);

    // Removing modeSets
    setValue(
      `cookingParameters.${instructionArrayIndex}.controlModeFirmwareId`,
      controlModeFirmwareId
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.controlModeFirmwareName`,
      controlModeFirmwareName
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.semiScreenId`,
      semiScreenId
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.controlModeId`,
      controlModeId
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.isCentralProbeTemperatureRequired`,
      isCentralProbeTemperatureRequired
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.isExternalProbeTemperatureRequired`,
      isExternalProbeTemperatureRequired
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.isPowerRequired`,
      isPowerRequired
    );
    setValue(
      `cookingParameters.${instructionArrayIndex}.isTimeRequired`,
      isTimeRequired
    );
  }
};

const ModifiedAutoCompleteWithCookingActions = (props) => {
  const { setCookingAction, control, name, options, multiple } = props;

  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option,
  });

  const handleAutocompleteChange = (evt, cookingAction) => {
    setCookingAction(cookingAction);

    field.onChange(cookingAction);
  };

  return (
    <Autocomplete
      size={props.size ? props.size : "small"}
      multiple={multiple || false}
      disablePortal
      disableUnderline
      label={field.name}
      id={field.name}
      onBlur={field.onBlur}
      onChange={handleAutocompleteChange}
      // onChange={field.onChange}
      name={field.name}
      ref={field.ref}
      freeSolo
      value={field.value}
      options={options}
      filterOptions={filterOptions}
      ListboxProps={{
        style: stylesObject.ListboxPropsStyles,
      }}
      renderInput={(params) => (
        <TextField
          disableUnderline
          {...params}
          fullWidth
          variant={props.variant ? props.variant : "standard"}
          sx={stylesObject.muiTextFieldStyle}
          label={props.label}
          placeholder={
            props.placeholder ? props.placeholder : "Choose from dropdown"
          }
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default ModifiedAutoCompleteWithCookingActions;
