/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import * as ReactMobiledoc from "react-mobiledoc-editor";
import { Editor } from "mobiledoc-kit";
import { render as renderDOM } from "mobiledoc-dom-renderer";

const MOBILEDOC_CONFIG = {
  atoms: [],
  cards: [
    {
      name: "ingredients",
      type: "dom",
      render: ({ env, options, payload }) => {
        const { onEdit } = options;
        const { ingredients } = payload;

        const element = document.createElement("div");
        element.className = "ingredients";

        const label = document.createElement("h3");
        label.innerText = "Ingredients";
        element.appendChild(label);

        const list = document.createElement("ul");
        ingredients.forEach((ingredient) => {
          const listItem = document.createElement("li");
          listItem.innerText = ingredient;
          list.appendChild(listItem);
        });
        element.appendChild(list);

        if (env.isInEditor) {
          element.addEventListener("click", () => {
            onEdit(payload);
          });
        }

        return element;
      },
      edit: ({ env, options, payload }) => {
        // Implement the edit functionality for the ingredients card.
      },
    },
    // Add similar configurations for the 'tools' and 'instructions' cards.
  ],
  markups: [],
  unknownCardHandler: () => {},
};

const MobileDocRecipeEditor = () => {
  const [mobiledoc, setMobileDoc] = useState(Editor.blankMobileDoc());

  const handleEditCard = (cardName, payload) => {
    // Implement the logic to edit the card's content.
  };

  const toolbarOptions = {
    // Add buttons to insert custom blocks/cards.
    buttons: [
      "bold",
      "italic",
      {
        label: "Ingredients",
        action: (editor) => {
          editor.insertCard("ingredients", { ingredients: [] });
        },
      },
      {
        label: "Tools",
        action: (editor) => {
          editor.insertCard("tools", { tools: [] });
        },
      },
      {
        label: "Instructions",
        action: (editor) => {
          editor.insertCard("instructions", { steps: [] });
        },
      },
    ],
  };

  return (
    <ReactMobiledoc.Container
      mobiledoc={mobiledoc}
      onChange={setMobileDoc}
      options={{
        ...MOBILEDOC_CONFIG,
        toolbar: toolbarOptions,
        cardOptions: {
          onEdit: handleEditCard,
        },
      }}
    />
  );
};

export default MobileDocRecipeEditor;
