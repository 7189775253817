/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Switch,
  Grid,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import {
  AppBarContainer,
  ArrowBack,
  SaveButton,
} from "./components/app-bar-styles";

const AppBarComponent = (props) => {
  const { editState, setOpenBackModal } = props;
  const { setValue, formState, getValues } = useFormContext();
  const metaData = getValues("metadata");
  const [isVerified, setIsVerified] = useState(
    getValues("metadata").isVerifiedByKlynk
  );

  const navigate = useNavigate();
  const isDirty = formState.isDirty;

  const onBackHandler = () => {
    if (isDirty) {
      setOpenBackModal(true);
    } else {
      navigate(-1);
    }
  };
  const onIsVerifiedByKlynkChange = (e) => {
    setIsVerified(!isVerified);
    setValue("metadata.isVerifiedByKlynk", e.target.checked);
  };
  useEffect(() => {
    setIsVerified(getValues("metadata").isVerifiedByKlynk);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("metadata").isVerifiedByKlynk]);

  return (
    <AppBarContainer position="fixed">
      <Toolbar>
        <ArrowBack onClick={onBackHandler} />
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Smart Recipe Writer
        </Typography>
        {editState && (
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              mr: 2,
            }}
          >
            <Typography variant="h7" mr={1}>
              Is verified by Klynk ?
            </Typography>
            <Switch
              checked={isVerified}
              onChange={onIsVerifiedByKlynkChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        )}

        {(!editState || (editState && metaData?.isDraftRecipe)) && (
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            onClick={() => {
              setValue("metadata.isDraftRecipe", true);
            }}
            sx={{
              border: "2px solid #EF4823",
              mr: 2,
              p: "6px 10px",
            }}
          >
            <Typography
              variant="body2"
              color="primary"
              sx={{ textTransform: "capitalize", fontSize: "14px" }}
            >
              {editState && metaData?.isDraftRecipe
                ? "Update Draft"
                : "Save as Draft"}
            </Typography>
          </Button>
        )}

        <SaveButton
          type="submit"
          variant="contained"
          onClick={() => {
            setValue("metadata.isDraftRecipe", false);
          }}
        >
          <Typography
            variant="subtext2"
            sx={{
              textTransform: "capitalize",
              color: "#fff",
            }}
          >
            {editState && metaData?.isDraftRecipe
              ? "Publish Recipe"
              : editState
              ? "Update Recipe"
              : "Publish Recipe"}
          </Typography>
        </SaveButton>
      </Toolbar>
    </AppBarContainer>
  );
};

export default AppBarComponent;
