import React from "react";

import { Paper, CircularProgress, Box } from "@mui/material";

export default function ImageElement({
  image,
  index,
  handleImageSelection,
  imageToVariableMapping,
}) {
  return (
    <Paper style={{ height: 150, padding: 2 }} elevation={2}>
      {image.downloadURL ? (
        <Box>
          <img
            src={image.downloadURL}
            alt={`Upload Preview ${index + 1}`}
            style={{
              objectFit: "cover",
              height: "150px",
              width: "100%",
              border:
                imageToVariableMapping[image.downloadURL] && "5px solid  green",
            }}
            onClick={() => handleImageSelection(image.downloadURL)}
          />
        </Box>
      ) : (
        <Box p={4}>
          <CircularProgress />
        </Box>
      )}
    </Paper>
  );
}
