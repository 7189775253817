import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  Grid,
  Typography,
  createFilterOptions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useController, useFormContext } from "react-hook-form";
import { courseName, dietsName, nonVegetarian, vegetarian } from "../constants";

const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const styles = {
  renderTags: {
    border: "2px solid #EF4823",
    borderRadius: "4px",
    padding: "5px 10px",
    display: "flex",
    alignItems: "center",
    columnGap: 1,
    background: "#FFECE4",
    marginBottom: "10px",
  },
  parentStyles: {
    "MuiAutocomplete-listbox": {
      background: "yellow",
      border: "2px solid red",
    },
  },
};

const ModifiedAutoCompleteWithTags = (props) => {
  const { control, name, options, setIsCourseModelOpen, setIsDietSelection } =
    props;

  const uniqueOptions = [
    ...new Set(
      options?.map((item) => capitalizeFirstLetter(item?.name?.toLowerCase()))
    ),
  ];

  const { setValue } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });
  const [currentTagvalue, setCurrentTagValue] = useState([]);
  const [values, setValues] = useState(field.value);

  const onDelete = (id) => {
    const filterValues = values.filter((item, index) => index !== id);
    setValues(filterValues);
    setValue(name, filterValues);
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option,
  });

  const OnTagChangeHandler = (e, tagValue) => {
    const isCourseField = name === courseName;
    const isDietField = name === dietsName;
    const hasNonVeg = values.includes(nonVegetarian);
    const hasVeg = values.includes(vegetarian);
    const newTagHasVeg = tagValue.includes(vegetarian);
    const newTagHasNonVeg = tagValue.includes(nonVegetarian);

    if (isCourseField && values?.length > 2) {
      setIsCourseModelOpen(true);
      return;
    }

    if (isDietField) {
      if (hasNonVeg && newTagHasVeg) {
        setCurrentTagValue(tagValue);
        setIsDietSelection(true);
        return;
      }

      if (hasVeg && newTagHasNonVeg) {
        setCurrentTagValue(tagValue);
        setIsDietSelection(true);
        return;
      }
    }

    setValues(tagValue);
    field.onChange(tagValue);
  };

  useEffect(() => {
    setValues(field.value);
  }, [field.value]);

  useEffect(() => {
    const isCourseField = name === courseName;
    const isDietField = name === dietsName;
    const hasNonVeg = values.includes(nonVegetarian);
    const hasVeg = values.includes(vegetarian);
    const newTagHasVeg = currentTagvalue.includes(vegetarian);
    const newTagHasNonVeg = currentTagvalue.includes(nonVegetarian);

    if (isCourseField && values?.length > 3) return;

    if (isDietField) {
      if ((hasNonVeg && newTagHasVeg) || (hasVeg && newTagHasNonVeg)) return;
    }

    setValues(field.value);
  }, [field.value, currentTagvalue]);

  return (
    <Box>
      <Autocomplete
        autoHighlight={true}
        ListboxProps={{
          style: {
            maxHeight: "20vh",
            color: "#1C1C1C",
            fontFamily: "Mukta Mahee",
            fontSize: "14px",
            fontWeight: 500,
            background: "#F7F7F7",
            position: "bottom-start",
          },
        }}
        multiple
        disableClearable
        size="small"
        options={uniqueOptions}
        filterOptions={filterOptions}
        value={values}
        onChange={OnTagChangeHandler}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="select entities"
            error={props.error}
            sx={{
              ".MuiOutlinedInput-root": {
                fontFamily: "Mukta Mahee",
                fontSize: "16px",
                fontWeight: 400,
                color: "#393939",
              },
            }}
          />
        )}
      />
      <Box sx={{ flexGrow: 1, mt: 1 }}>
        <Grid
          container
          columnGap={1}
          sx={{ maxHeight: 100, overflowY: "auto" }}
        >
          {values?.map((item, index) => (
            <Grid item key={index} sx={styles.renderTags}>
              <Typography
                variant="body4"
                sx={{
                  fontWeight: 500,
                  color: "#EF4823",
                  textTransform: "capitalize",
                }}
              >
                {item}
              </Typography>
              <CloseIcon
                color="primary"
                sx={{ fontSize: "16px" }}
                onClick={() => onDelete(index)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ModifiedAutoCompleteWithTags;
