/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// ControlledAutoComplete is a component that allows the user to enter a list of options that they can choose from.
// It has an onChange prop that allows the user to change the value of the component, and a value prop that allows the user to set the value of the component.
// The component also has a getOptionLabel prop that allows the user to customize the label of the options that they can choose from.
import React from "react";
import { useController, Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import PropTypes from "prop-types";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const capitalizeOption = (option, collectionLookupKey) => {
  if (typeof option === "string") {
    return capitalizeFirstLetter(option);
  }
  if (option && option[collectionLookupKey]) {
    return {
      ...option,
      [collectionLookupKey]: capitalizeFirstLetter(option[collectionLookupKey]),
    };
  }
  return option;
};

const ControlledAutoComplete = ({
  name,
  control,
  options = [],
  label,
  multiple = false,
  collectionLookupKey = "name",
  size = "small",
}) => {
  const { field } = useController({
    name,
    control,
    //   rules: { required: true },
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...restField } }) => (
        <Autocomplete
          {...restField}
          size={size}
          disablePortal
          id={field.name}
          freeSolo={multiple ? undefined : false}
          options={options}
          multiple={multiple}
          value={value || (multiple ? [] : null)}
          onChange={(_, newValue) => {
            const capitalizedValue = multiple
              ? newValue.map((v) => capitalizeOption(v, collectionLookupKey))
              : capitalizeOption(newValue, collectionLookupKey);
            onChange(capitalizedValue);
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string")
              return capitalizeFirstLetter(option);
            return option && option[collectionLookupKey]
              ? capitalizeFirstLetter(option[collectionLookupKey])
              : "";
          }}
          isOptionEqualToValue={(option, value) =>
            option[collectionLookupKey] === value[collectionLookupKey]
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Choose from the list"
              fullWidth
              label={label}
            />
          )}
        />
      )}
    />
  );
};

ControlledAutoComplete.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  collectionLookupKey: PropTypes.string,
  size: PropTypes.string,
};

export default ControlledAutoComplete;
