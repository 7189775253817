/* eslint-disable no-unused-vars */
// This is a form which will generate a recipe using ChatGPT.
import { Typography, Box, Grid, Button, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import firebase from "../../config/firebase-config";
import ReactJson from "react-json-view";
import ControlledFields from "../../library/controlled-fields-old";

const CreateRecipeUsingChatGPT = () => {
  const [recipeGenerationInProgress, setRecipeGenerationInProgress] =
    useState(false);
  const [chatGptResponse, setChatGptResponse] = useState({});

  const generateRecipeCloudFunction = firebase
    .functions()
    .useEmulator("localhost", 5001)
    .httpsCallable("generateRecipeUsingChatGPT");

  // Will send the name of the recipe, ingredients, tools and skill level and the time available which are entered by the user
  const generateRecipe = async (data) => {
    let ingredients = data.ingredients.split(",");
    let tools = data.tools.split(",");
    let packet = {
      ...data,
      ingredients,
      tools,
    };
    setRecipeGenerationInProgress(true);
    const response = await generateRecipeCloudFunction(data);
    setRecipeGenerationInProgress(false);
    setChatGptResponse(response);
    // setChatGptResponse(packet)
  };
  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      ingredients: "",
      tools: "",
      skillLevel: "",
      timeAvailable: 15,
    },
  });

  return (
    <Box padding={2}>
      <Box padding={2}>
        <form onSubmit={handleSubmit(generateRecipe)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledFields.TextField
                name="ingredients"
                control={control}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledFields.TextField
                name="tools"
                control={control}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledFields.TextField
                name="skillLevel"
                control={control}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledFields.TextField
                name="timeAvailable"
                control={control}
                fullWidth
              />
            </Grid>
            <Grid items xs={12}>
              <Button variant="outlined" type="submit">
                Generate
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      {recipeGenerationInProgress && (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}
      <Box padding={2}>
        <Typography variant="h6">Generated Recipe</Typography>
        <Box>
          <ReactJson src={chatGptResponse} />
        </Box>
      </Box>
    </Box>
  );
};

export default CreateRecipeUsingChatGPT;
