/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import * as Yup from "yup";
import { string, object, array } from "yup";
import { Box, Button, Typography } from "@mui/material";
import { nameValidation } from "../units/units-model";
import dummyImage from "../../.././src/assets/Undetected-ingredient-image.png";

// Validation for ingredient name
export const IngredientsnameValidation = string()
  .required("Name is required")
  .matches(/^[a-zA-Z\s]+$/, {
    message: "No special characters and numbers are allowed",
    excludeEmptyString: true,
  })
  .max(60, "Maximum 60 characters are allowed");

export const thumbnailUrlValidation = Yup.string()
  .url("Invalid URL format")
  .required("Thumbnail URL is required");

const ingredientsModel = {
  name: "Ingredients",
  model: "ingredients",
  route: "ingredients",
  collectionName: "ingredients",
  defaultObject: {
    id: null,
    name: null,
    thumbnailUrl: null,
    imageUrl: null,
    aliases: null,
    defaultUnit: null,
    mediaUrl: null,
    otherUnits: [],
    defaultPrepStyle: [],
    otherPrepStyles: [],
    foodCategory: null,
    aisle: null,
    fdcId: null,
    fdcName: null,
    ndbNumber: null,
    createdAt: null,
    updatedAt: null,
  },
  fields: [
    {
      name: "thumbnailUrl",
      dataType: "String",
      inputFieldType: "image-uploader",
      label: "Thumbnail",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 75,
      validation: thumbnailUrlValidation,
      listLayoutComponent: (props) => {
        const thumbnailUrl = props.row?.thumbnailUrl || dummyImage;
        return (
          <img
            src={thumbnailUrl}
            alt={props.row?.name || "Placeholder"}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = dummyImage;
            }}
          />
        );
      },
      collectionName: "ingredients",
    },  
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text",
      label: "Name",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: IngredientsnameValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{name}</span>
          </div>
        );
      },
    },
    {
      name: "imageUrl",
      dataType: "String",
      inputFieldType: "image-uploader",
      label: "Image URL",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 200,
      listLayoutComponent: (props) => {
        const thumbnailUrl = props.row?.thumbnailUrl || dummyImage;
        return (
          <img
            src={thumbnailUrl}
            alt={props.row?.name || "Placeholder"}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = dummyImage;
            }}
          />
        );
      },
    },
    {
      name: "aliases",
      dataType: "String",
      inputFieldType: "aliases-text-modified",
      label: "Aliases (CSV)",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: false,
      listLayoutWidth: 200,
    },
    {
      name: "defaultUnit",
      dataType: "String",
      inputFieldType: "select-from-collection",
      label: "Default Unit",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      collectionName: "units",
      keyInCollection: "name",
      validation: Yup.string().required("Default Unit is required"),
    },
    {
      name: "otherUnits",
      dataType: "String",
      inputFieldType: "multi-select-from-collection",
      label: "Other Units",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      collectionName: "units",
      keyInCollection: "name",
      listLayoutWidth: 250,
      multiple: true,
      listLayoutComponent: (props) => {
        const otherUnits = props.row?.otherUnits;
        const displayUnits = Array.isArray(otherUnits)
          ? otherUnits.join(", ")
          : "";

        return (
          <div
            style={{
              fontSize: "0.7em",
              color: "grey",
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{displayUnits}</span>
          </div>
        );
      },
    },
    {
      name: "defaultPrepStyle",
      dataType: "Object",
      inputFieldType: "media-select-from-collection",
      label: "Default Preparation Style",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 300,
      collectionName: "prepStyles",
      keyInCollection: "name",
      listLayoutComponent: (props) => {
        const defaultPrepStyle = props.row?.defaultPrepStyle || {};
        const option = defaultPrepStyle.option || "";
        const mediaUrl = defaultPrepStyle.mediaUrl;
        const fileType = defaultPrepStyle.fileType;

        // const handleVideoClick = (url) => {
        //   window.open(url, "_blank");
        // };

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography variant="body2" sx={{ mr: 2 }}>
                {option}
              </Typography>
              {mediaUrl && (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {fileType === "image" ? (
                    <img
                      src={mediaUrl}
                      alt={option}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        borderRadius: "4px",
                      }}
                    />
                  ) : (
                    <>
                      {/* <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleVideoClick(mediaUrl)}
                        sx={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        View Video
                      </Button> */}
                      <Box
                        sx={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "4px",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <video
                          src={mediaUrl}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          controls
                          muted
                        />
                      </Box>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      name: "otherPrepStyles",
      dataType: "Object",
      inputFieldType: "multi-select-from-collection",
      label: "Other Preparation Styles",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 250,
      collectionName: "prepStyles",
      keyInCollection: "name",
      multiple: true,
      listLayoutComponent: (props) => {
        const otherPrepStyles = props.row?.otherPrepStyles;
        const displayPrepStyles = Array.isArray(otherPrepStyles)
          ? otherPrepStyles.join(", ")
          : "";

        return (
          <div
            style={{
              fontSize: "0.7em",
              color: "grey",
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{displayPrepStyles}</span>
          </div>
        );
      },
    },
    {
      name: "foodCategory",
      dataType: "Array",
      inputFieldType: "food-category-multi-select-from-collection",
      label: "Food Category",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 250,
      collectionName: "foodCategories",
      keyInCollection: "name",
      validation: Yup.string().required("Food Category is required"),
    },
    {
      name: "aisle",
      dataType: "String",
      inputFieldType: "select-from-collection",
      label: "Aisle",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      collectionName: "aisles",
      keyInCollection: "name",
      validation: nameValidation,
    },
    {
      name: "fdcName",
      dataType: "String",
      inputFieldType: "fdcName-select-from-collection",
      label: "FDC Name",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: false,
      listLayoutWidth: 150,
      collectionName: "fdcFoods",
      keyInCollection: "name",
    },
    {
      name: "fdcId",
      dataType: "String",
      inputFieldType: "autoupdate",
      label: "FDC ID",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 3,
      showInListLayout: false,
      listLayoutWidth: 150,
      derivedFrom: "fdcName",
      derivedFromCollection: "fdcFoods",
      collectionLookupKey: "name",
      collectionReturnKey: "id",
    },
    {
      name: "ndbNumber",
      dataType: "String",
      inputFieldType: "autoupdate",
      label: "NDB Number",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 3,
      showInListLayout: false,
      listLayoutWidth: 150,
      derivedFrom: "fdcName",
      derivedFromCollection: "fdcFoods",
      collectionLookupKey: "name",
      collectionReturnKey: "ndbNumber",
    },
    {
      name: "createdAt",
      dataType: "Date",
      inputFieldType: "date",
      label: "Created At",
      required: false,
      showInEditLayout: false,
      showInListLayout: false,
      listLayoutWidth: 150,
    },
    {
      name: "updatedAt",
      dataType: "Date",
      inputFieldType: "date",
      label: "Updated At",
      required: false,
      showInEditLayout: false,
      showInListLayout: false,
      listLayoutWidth: 150,
    },
  ],
  layout: [
    [{ name: "thumbnailUrl", width: 12 }],
    [
      { name: "name", width: 6 },
      { name: "aliases", width: 6 },
    ],
    [
      { name: "foodCategory", width: 6 },
      { name: "aisle", width: 6 },
    ],
    [
      { name: "defaultUnit", width: 6 },
      { name: "otherUnits", width: 6 },
    ],
    [
      { name: "defaultPrepStyle", width: 6 },
      { name: "otherPrepStyles", width: 6 },
    ],
    [
      { name: "fdcName", width: 6 },
      { name: "fdcId", width: 3 },
      { name: "ndbNumber", width: 3 },
    ],
    // [
    //   { name: "createdAt", width: 6 },
    //   { name: "updatedAt", width: 6 },
    // ],
  ],
};

export default ingredientsModel;
