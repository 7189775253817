/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import * as Yup from "yup";
import { nameValidation } from "../units/units-model";
import {
  mixed,
  string,
  number,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from "yup";

const controlModesModel = {
  name: "Control Modes",
  model: "controlModes",
  route: "controlModes",
  collectionName: "controlModes",
  defaultObject: {
    id: "",
    description: null,
    helpTextMarkDown: "", // for the help section
    name: null, // Unique title for the firmware
    firmwareName: null, // Unique title for the firmware
    firmwareId: null, // Integer mapping for the firware
    isPowerRequired: true,
    isTimeRequired: true,
    isCentralProbeTempratureRequired: false,
    isExternalProbeTempratureRequired: false,
    isExternalProbeEnabled: false,
    isControlViaExternalProbe: false,
    showInCustomPresets: true, // added this key because we have 18 control modes in which we need to show 15 control modes in dropdown
    createdAt: "",
    updatedAt: "",
  },
  fields: [
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: nameValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{name}</span>
          </div>
        );
      },
    },
    {
      name: "firmwareName",
      dataType: "String",
      inputFieldType: "noChar-noValid-text",
      label: "Firmware Name",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required("Firmware Name is required"),
    },
    {
      name: "firmwareId",
      dataType: "String",
      inputFieldType: "number",
      label: "FirmwareID",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 50,
      validation: Yup.number()
        .typeError("Id must be a number")
        .required("Firmware Id is required"),
    },
    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 300,
      listLayoutComponent: (props) => {
        const description = props.row?.description;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{description}</span>
          </div>
        );
      },
    },
    {
      name: "helpTextMarkDown",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Help Text",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: false,
      listLayoutWidth: 200,
    },
    {
      name: "isPowerRequired",
      dataType: "Boolean",
      inputFieldType: "notRequired-checkbox",
      label: "Power?",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 100,
      validation: bool().required("Power is required"),
    },
    {
      name: "isTimeRequired",
      dataType: "Boolean",
      inputFieldType: "notRequired-checkbox",
      label: "Time?",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 100,
      validation: bool().required("Time is required"),
    },
    {
      name: "isCentralProbeTempratureRequired",
      dataType: "Boolean",
      inputFieldType: "notRequired-checkbox",
      label: "Central Probe Temprature?",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 100,
      validation: bool().required("required"),
    },
    {
      name: "isExternalProbeTempratureRequired",
      dataType: "Boolean",
      inputFieldType: "notRequired-checkbox",
      label: "External Probe Temprature?",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 100,
      validation: bool().required("required"),
    },
    {
      name: "isExternalProbeEnabled",
      dataType: "Boolean",
      inputFieldType: "notRequired-checkbox",
      label: "External Probe?",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 200,
      validation: bool().required("required"),
    },
    {
      name: "isControlViaExternalProbe",
      dataType: "Boolean",
      inputFieldType: "notRequired-checkbox",
      label: "Control Via External Probe",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 200,
      validation: bool().required("required"),
    },
    {
      name: "showInCustomPresets",
      dataType: "Boolean",
      inputFieldType: "notRequired-checkbox",
      label: "Show In Custom Presets",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: false,
      listLayoutWidth: 200,
      validation: bool().required("required"),
    },
    {
      name: "createdAt",
      dataType: "String",
      inputFieldType: "text",
      label: "Created At",
      required: false,
      showInEditLayout: false,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 200,
    },
    {
      name: "updatedAt",
      dataType: "String",
      inputFieldType: "text",
      label: "Updated At",
      required: false,
      showInEditLayout: false,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 200,
    },
    {
      name: "semiScreenId",
      dataType: "String",
      inputFieldType: "text",
      label: "Semi Screen Id",
      required: false,
      showInEditLayout: false,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 200,
    },
  ],
  layout: [
    [{ name: "name", width: 6 }],
    [
      { name: "firmwareId", width: 3 },
      { name: "firmwareName", width: 3 },
    ],
    [{ name: "description", width: 12 }],
    [
      { name: "isPowerRequired", width: 6 },
      { name: "isTimeRequired", width: 6 },
    ],
    [
      { name: "isCentralProbeTempratureRequired", width: 6 },
      { name: "isExternalProbeTempratureRequired", width: 6 },
    ],
    [
      { name: "isExternalProbeEnabled", width: 6 },
      { name: "isControlViaExternalProbe", width: 6 },
    ],
    [{ name: "showInCustomPresets", width: 6 }],
  ],
  permittedActions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
    {
      name: "edit",
      label: "Edit",
      type: "edit",
      icon: "edit",
      color: "primary",
    },
    {
      name: "delete",
      label: "Delete",
      type: "delete",
      icon: "delete",
      color: "primary",
    },
  ],
};

export default controlModesModel;
