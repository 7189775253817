/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Box, Typography } from "@mui/material";
import { synonymsValidation, nameValidation } from "../units/units-model";
import dummyImage from "../../.././src/assets/Undetected-tool-image-circle.png";
import { thumbnailUrlValidation } from "../ingredients/ingredients-model";
import {
  mixed,
  string,
  number,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from "yup";

const tools = {
  name: "Tools",
  model: "tools",
  collectionName: "tools",
  defaultObject: {
    id: null,
    thumbnailUrl: null,
    name: null,
    toolCategory: null,
    description: null,
    createdAt: null,
    updatedAt: null,
    alternatives: [],
  },
  fields: [
    {
      name: "thumbnailUrl",
      dataType: "String",
      inputFieldType: "image-uploader",
      label: "Thumbnail",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: thumbnailUrlValidation,
      listLayoutComponent: (props) => {
        const thumbnailUrl = props.row?.thumbnailUrl || dummyImage;
        return (
          <img
            src={thumbnailUrl}
            alt={props.row?.name || "Placeholder"}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        );
      },
    },
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150, 
      validation: nameValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{name}</span>
          </div>
        );
      },
    }, 
    {
      name: "synonyms",
      dataType: "String",
      inputFieldType: "synonyms-text-modified",
      label: "Synonyms",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: synonymsValidation,
    },
    // {
    //   name: "category",
    //   dataType: "String",
    //   inputFieldType: "select",
    //   options: [
    //     "Hand Tool",
    //     "Small Appliance",
    //     "Large Appliance",
    //     "Cookware",
    //     "Utensils",
    //     "Bakeware",
    //   ],
    //   label: "category",
    //   showInEditLayout: true,
    //   editLayoutWidth: 6,
    //   showInListLayout: true,
    //   listLayoutWidth: 150,
    //   required: true,
    //   validation: string().required(" Category is required"),
    // },
    
    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 300, 
      listLayoutComponent: (props) => {
        const description = props.row?.description;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{description}</span>
          </div>
        );
      },
    },   
    {
      name: "alternatives",
      dataType: "Array",
      inputFieldType: "autocomplete",
      label: "Alternatives",
      showInListLayout: true,
      listLayoutWidth: 450,
      showInEditLayout: true,
      multiple: true,
      editLayoutWidth: 6,
      collectionName: "tools",
      keyInCollection: "name",
      listLayoutComponent: (props) => {
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            {props.row?.alternatives?.map((alternatives, index) => (
              <span key={alternatives.id} style={{ marginRight: "8px" }}>
                {alternatives.name}
                {index < props.row.alternatives.length - 1 && ", "}
              </span>
            ))}
          </div>
        );
      },
    }

  ],
  layout: [
    [{ name: "thumbnailUrl", width: 12 }],
    [
      { name: "name", width: 6 },
      { name: "synonyms", width: 6 },
    ],
    [{ name: "toolCategory", width: 6 }],
    [{ name: "description", width: 6 }],
    [{ name: "alternatives", width: 6 }],
  ],
  actions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
  ],
};

export default tools;