/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import AppBarComponent from "./appbar";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import Metadata from "./meta-data";
import RecipeSections from "./recipe-sections";
import Preview from "./preview";
import ReactJson from "react-json-view";
import { recipeStructureChangingFromNewToOld } from "../../utils/recipe-structure-changing-from-new-to-old";
// import ImageModel from "./image-model";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useEntity from "../entities/use-entity";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HelpSharpIcon from "@mui/icons-material/HelpSharp";
import ConvertToProModal from "./components/convert-to-pro-popup";
import StarIcon from "@mui/icons-material/Star";
import PreventBackModal from "./components/prevent-back-popup";
import { useAuth } from "../auth/auth-context";
import { storage } from "../../config/firebase-config";
import { store } from "../../redux/store/store";
import { useSelector } from "react-redux";
import ErrorIcon from "./assets/error.png";
import toast from "react-hot-toast";
import Toastify from "./components/custom-toast";

const RecipeForm = () => {
  const { user } = useAuth();
  // store && typeof store.getStore === "function"
  //   ? useSelector((state) => state.user.userDetails)
  //   : useAuth();

  const location = useLocation();
  const recipeId = uuidv4();

  const sectionId = uuidv4();
  const instructionId = uuidv4();
  const cookingParameterId = uuidv4();
  const params = useParams();

  const [recipe, setRecipe] = useState(location.state ? location.state : null);
  const { updateEntity, addEntity, entityByDifferentCollections } = useEntity();

  const validationSchema = Yup.object().shape({
    isRecipeStatus: Yup.boolean(),
    isRecipeProSelected: Yup.boolean(),
    isRikuSelected: Yup.boolean(),
    isSemiSelected: Yup.boolean(),
    metadata: Yup.object().shape({
      title: Yup.string()
        .max(25, "Max. characters")
        .required("Recipe title cannot be empty"),
      recipeDescription: Yup.string()
        .max(500, "Cannot exceed 500 characters")
        .required("Recipe description cannot be empty"),
      prepTime: Yup.string()
        .max(3, "only three characters can enter")
        .required("Preparation time cannot be empty")
        .matches(/[1-9]/, "Preparation time can only contain numbers."),
      cookTime: Yup.string()
        .max(3, "only three characters can enter")
        .required("Cook time cannot be empty ")
        .matches(/[1-9]/, "Cook time can only contain numbers."),
      servings: Yup.string()
        .max(1, "only three characters can enter")
        .required("Servings cannot be empty ")
        .matches(/[2-6]/, "Cook time can only contain numbers."),
      tools: Yup.array().min(1, "Tools are required"),
      courses: Yup.array().min(1, "Courses are required"),
      cuisines: Yup.array().min(1, "Cuisines are required"),
      diets: Yup.array().min(1, "Diets are required"),
    }),
    recipeIngredients: Yup.array().when(
      ["isRikuSelected", "isSemiSelected"],
      ([isRikuSelected, isSemiSelected], schema) => {
        return schema.of(
          Yup.object().shape({
            name: Yup.string().required("Ingredient name is required"),
            quantity: Yup.string().required("Quantity is required"),
            units: Yup.string().required("Units are required"),
            // prepStyle: Yup.string().required("Preparation style is required"),
            // loadingPosition:
            //   isRikuSelected &&
            //   Yup.string().required("Loading position is required"),
            // categoryInContextOfRiku:
            //   isRikuSelected &&
            //   Yup.string().required("Riku context is required"),
            // podType:
            //   isRikuSelected && Yup.string().required("Pod type is required"),
            // podPosition:
            //   isRikuSelected &&
            //   Yup.string().required("Ingredient Pod Position is required"),
            // schedulingLimit:
            //   isRikuSelected &&
            //   Yup.number().required("Experience (min) is required"),
            quantityPerStep:
              isSemiSelected &&
              Yup.string().required("Quantity per step is required"),
            unitsPerStep:
              isSemiSelected &&
              Yup.string().required("Units per step is required"),
          })
        );
      }
    ),
    recipeSections: Yup.array().when(
      ["isRecipeStatus"],
      ([isRecipeStatus], schema) => {
        if (isRecipeStatus) {
          return schema.of(
            Yup.object().shape({
              title: Yup.string().required("Step title is required"),
            })
          );
        }
      }
    ),
    recipeInstructions: Yup.array().when(
      ["isRecipeStatus", "isRecipeProSelected"],
      ([isRecipeStatus], schema) => {
        if (isRecipeStatus) {
          return schema.of(
            Yup.object().shape({
              title: Yup.string().required("This step instruction is required"),
            })
          );
        }
      }
    ),
    cookingParameters: Yup.array().when(
      "isRecipeProSelected",
      ([isRecipeProSelected], schema) => {
        if (isRecipeProSelected) {
          return schema.of(
            Yup.object().shape({
              action: Yup.string(),
              stirring: Yup.string().when("action", (action, schema) => {
                const notRequiredParameters = [
                  "temperature",
                  "power",
                  "pre heat",
                  "shallow fry",
                  "searing",
                  "sous vide",
                  "deep fry",
                  "",
                ];
                return !notRequiredParameters.includes(action)
                  ? schema
                  : schema.required();
              }),
              isLidOpen: Yup.string().when("stirring", (stirring, schema) => {
                return ["high", "medium", "low"].includes(stirring)
                  ? schema.required()
                  : schema;
              }),
              power: Yup.string().when("action", {
                is: "power",
                then: (schema) =>
                  schema
                    .required("Power is required")
                    .matches(/^[0-9]+$/, "Power can only contain numbers."),
              }),
              temperature: Yup.string().when("action", {
                is: "temperature",
                then: (schema) =>
                  schema
                    .required("Temperature is required")
                    .matches(/[0-9]/, "Temperature can only contain numbers."),
              }),
              probeTemperature: Yup.string().when(
                "action",
                (action, schema) => {
                  const notRequiredParameters = [
                    "probeTemperature",
                    "",
                    "sous vide",
                    "searing",
                    "deep fry",
                  ];
                  return !notRequiredParameters.includes(action)
                    ? schema
                    : schema
                        .required()
                        .matches(/^[0-9]+$/, "Probe Temperature is required.");
                }
              ),
              duration: Yup.string().when("action", (action, schema) => {
                const notRequiredParameters = [
                  "temperature",
                  "power",
                  "pre heat",
                  "",
                  "pressure cooker",
                  "flat bread",
                  "searing",
                  "deep fry",
                ];
                return !notRequiredParameters.includes(action)
                  ? schema
                  : schema
                      .required()
                      .matches(
                        /^[0-9]+$/,
                        "Duration can only contain numbers."
                      );
              }),
            })
          );
        }
      }
    ),
  });

  const generateDefaultValue = () => {
    return {
      recipeId: recipeId,
      isRecipeStatus: false,
      isRecipeProSelected: false,
      isRikuSelected: false,
      isSemiSelected: false,
      author: {
        name: user?.displayName,
        id: user?.uid || user?.id,
        email: user?.email,
        photoURL: user?.photoURL,
      },
      metadata: {
        title: "",
        recipeDescription: "",
        servings: "",
        difficultyLevel: "",
        isVerifiedByKlynk: false,
        prepTime: 0,
        cookTime: 0,
        restTime: 0,
        productReleases: [],
        courses: [],
        tools: [],
        diets: [],
        cuisines: [],
        tags: [],
        allergies: [],
        profileUrl: "",
        prepImage: "",
        schedulingLimit: null,
        notes: "",
        recipeUrl: "",
        imageUrl: "",
        recipeServeDescription: "",
        id: recipeId,
        recipeIngredientsLength: 0,
      },
      recipeIngredients: [
        {
          id: uuidv4(),
          ingredientId: "",
          isScaleIngredient: false,
          name: "",
          quantity: "",
          quantityPerStep: "",
          ingredient: "",
          ingredientType: "",
          ingredientImage: "",
          prepStyle: "",
          units: "",
          unitPerStep: "",
          loopingUnit: "",
          loopingQuantity: "",
          schedulingLimit: "",
          isOutput: false,
          isRecipeGenerated: false,
          isUserGenerated: false,
          loadingPosition: "",
          podType: "",
          podPosition: [],
          categoryInContextOfRiku: "",
          recipeId: recipeId,
          sectionId: sectionId,
          instructionId: "",
        },
      ],
      recipeSections: [
        {
          id: sectionId,
          title: "",
          sectionType: "manual",
          sectionIndex: 0,
          isLoopSection: false,
          loopInstruction: "",
          recipeId: recipeId,
          isProbeRequired: false,
        },
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          id: instructionId,
          action: "",
          actions: [],
          ingredients: [],
          mentionsTitle: "",
          recipeId: recipeId,
          sectionId: sectionId,
          selectedActions: [],
          selectedIngredients: [],
          selectedModeSets: [],
          selectedTools: [],
          semiSteps: [],
          stepUrl: "",
          tip: "",
          title: "",
          tools: [],
          instructionIndex: 0,
        },
      ],
      cookingParameters: [
        {
          id: cookingParameterId,
          sectionId: sectionId,
          instructionId: instructionId,
          stirring: "",
          isLidOpen: false,
          action: "",
          power: "",
          powerInLevel: "",
          temperature: "",
          centralTemperatureInC: "",
          probeTemperature: "",
          externalTemperatureInC: "",
          weight: "",
          weightInGrams: "",
          duration: "",
          durationInMinutes: "",
          tempSensor: "",
          recipeId: recipeId,
          instructionIndex: 0,
          presetId: "",
          presetName: "",
          controlModeId: "",
          controlModeFirmwareName: "",
          controlModeFirmwareId: "",
          manualHeatLevel: "",
        },
      ],
    };
  };

  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
    defaultValues: recipe ? recipe : generateDefaultValue(),
  });

  const [formState, setFormState] = useState(false);
  const navigate = useNavigate();
  const [openConvertToProModel, setOpenConvertToProModel] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [checkingInstructionError, setCheckingInstructionError] =
    useState(false);
  const entityName = "testRecipes";
  const entityArrayName = [
    "newDefinedRecipes",
    "recipeIngredients",
    "recipeSections",
    "recipeInstructions",
    "cookingParameters",
  ];
  //    The main tab to select between recipeInformation(metaData) and recipeSections and preview tabs
  const [currentFormView, setCurrentFormView] = useState("METADATA");
  const metaData = methods.watch().metadata;
  const formErrors = methods.formState.errors;
  console.log(Object.keys(formErrors), "errors");

  const handleCurrentViewChange = (newValue, index) => {
    setCurrentFormView(newValue);
  };

  const checkKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const onSubmit = (data) => {
    // Handle form submission
    submitRecipe(data);
    setIsFormDirty(false);
  };

  // TODO:Back button unsaved changes alert
  // const handlePopstate = (event) => {
  //   // Handle the back/forward button click here
  //   console.log("clicked", event);
  // };

  // useEffect(() => {
  //   // Add the event listener when the component mounts
  //   window.addEventListener("onload", handlePopstate);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("onload", handlePopstate);
  //   };
  // }, [isFormDirty]);

  const submitRecipe = async (data) => {
    // Old format
    setIsLoading(true);
    // console.log(recipeStructureChangingFromNewToOld(data), "submitted data");
    // if (formState) {
    //   entityByDifferentCollections(
    //     entityArrayName,
    //     { ...data, recipeIngredientsLength: data?.recipeIngredients?.length },
    //     formState
    //   );
    //   await updateEntity(
    //     entityName,
    //     recipeStructureChangingFromNewToOld({
    //       ...data,
    //       recipeIngredientsLength: data?.recipeIngredients?.length,
    //     }),
    //     setRecipe
    //   );
    // navigate(-1);
    // } else {
    //   await addEntity(
    //     entityName,
    //     recipeStructureChangingFromNewToOld({
    //       ...data,
    //       recipeIngredientsLength: data?.recipeIngredients?.length,
    //     }),
    //     setRecipe
    //   );
    //   entityByDifferentCollections(
    //     entityArrayName,
    //     { ...data, recipeIngredientsLength: data?.recipeIngredients?.length },
    //     formState
    //   );
    //   navigate(-1);
    // }
  };

  useEffect(() => {
    if (location.state) {
      methods.reset(location.state);
      if (params?.recipeId?.split("&")?.[1] !== "recipe=clone") {
        setFormState(true);
        setIsLoading(false);
      } else {
        setFormState(false);
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const beforeUnloadHandler = (e) => {
      if (isFormDirty) {
        const confirmationMessage =
          "It looks like you have been editing something. " +
          "If you leave before saving, your changes will be lost.";
        (e || window.event).returnValue = confirmationMessage; // For some browsers
        return confirmationMessage; // For modern browsers
      }
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [isFormDirty]);

  useEffect(() => {
    setIsFormDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  useEffect(() => {
    let errorKeys = Object.keys(formErrors);
    if (
      errorKeys.includes("recipeInstructions") ||
      errorKeys.includes("recipeIngredients") ||
      errorKeys.includes("recipeSections") ||
      errorKeys.includes("cookingParameters")
    ) {
      setCheckingInstructionError(true);
    }
  }, [formErrors]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      toast(
        (t) => (
          <Grid container direction="column" width="50vw">
            <Grid item display="flex" alignItems="center">
              <img src={ErrorIcon} alt="" style={{ marginRight: "4px" }} />
              <Typography
                variant="body2"
                sx={{ color: "#fff", fontWeight: 700, fontSize: "14px" }}
              >
                UNABLE TO PUBLISH DUE TO MISSING FIELDS
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "#A7A7A7",
                  fontWeight: 400,
                  fontSize: "14px",
                  alignItems: "center",
                }}
              >
                Please fill in the required fields marked with an asterisk
                <span style={{ color: "#E74338", margin: "0 4px" }}>
                  *
                </span>{" "}
                under error sign
                <img src={ErrorIcon} alt="" style={{ marginLeft: "4px" }} />
              </Typography>
            </Grid>
          </Grid>
        ),
        {
          duration: 5000,
        }
      );
    }
  }, [formErrors]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <Toastify />
        <AppBarComponent
          setOpenBackModal={setOpenBackModal}
          submitRecipe={onSubmit}
          formValues={methods.watch()}
          isDirty={methods.formState.isDirty}
          setValue={methods.setValue}
          formState={formState}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <PreventBackModal
          openBackModal={openBackModal}
          setOpenBackModal={setOpenBackModal}
          submitRecipe={onSubmit}
          formValues={methods.watch()}
          isDirty={methods.formState.isDirty}
          setValue={methods.setValue}
          formState={formState}
        />

        <Grid container spacing={2} style={styles.formRoot}>
          <Grid item xs={12}>
            <Grid display="flex" justifyContent="center" mb={2}>
              <Typography
                variant="h3"
                sx={{ fontSize: "20px", fontWeight: 500 }}
              >
                {metaData?.title || "Recipe Title"}
              </Typography>
              {metaData?.isRecipeProSelected && (
                <Box
                  ml={1}
                  sx={{
                    width: 60,
                    height: 25,
                    borderRadius: "4px",
                    background: "#FAB700",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <StarIcon sx={{ color: "#fff" }} fontSize="16px" />
                  <Typography
                    variant="body2"
                    sx={{ color: "#fff", marginLeft: "3px" }}
                  >
                    PRO
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid container justifyContent="center">
              <Grid
                mt={1}
                item
                xs={8}
                sx={{
                  background: "#F1F1F1",
                  borderRadius: "22px",
                  width: "676px",
                  display: "flex",
                  height: "46px",
                  padding: "4px",
                  justifyContent: " space-around",
                }}
              >
                <Grid
                  key="1"
                  sx={{
                    width: "33%",
                    background: currentFormView === "METADATA" && "#393939",
                    borderRadius: currentFormView === "METADATA" && "19px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleCurrentViewChange("METADATA")}
                >
                  {formErrors.metadata && <img src={ErrorIcon} alt="" />}
                  <Typography
                    ml={1}
                    variant="body2"
                    sx={{
                      color: currentFormView === "METADATA" && "#fff",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Information
                  </Typography>
                </Grid>
                <Grid
                  key="2"
                  sx={{
                    width: "33%",
                    background: currentFormView === "INSTRUCTIONS" && "#393939",
                    borderRadius: currentFormView === "INSTRUCTIONS" && "19px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleCurrentViewChange("INSTRUCTIONS")}
                >
                  {checkingInstructionError && <img src={ErrorIcon} alt="" />}
                  <Typography
                    ml={1}
                    variant="body2"
                    sx={{
                      color: currentFormView === "INSTRUCTIONS" && "#fff",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Instructions
                  </Typography>
                </Grid>
                <Grid
                  key="3"
                  sx={{
                    width: "33%",
                    background: currentFormView === "PREVIEW" && "#393939",
                    borderRadius: currentFormView === "PREVIEW" && "19px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleCurrentViewChange("PREVIEW");
                  }}
                >
                  <Typography
                    mt={1}
                    variant="body2"
                    sx={{
                      color: currentFormView === "PREVIEW" && "#fff",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Preview
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ ...styles.tabContentContainer, position: "relative" }}
        >
          {currentFormView === "METADATA" && <Metadata />}
          {currentFormView === "INSTRUCTIONS" && <RecipeSections />}
          {currentFormView === "PREVIEW" && <Preview />}
          <ConvertToProModal
            openConvertToProModel={openConvertToProModel}
            setOpenConvertToProModel={setOpenConvertToProModel}
          />
          {!metaData.isRecipeProSelected && (
            <Box
              sx={{ display: "flex", position: "fixed", bottom: 20, right: 20 }}
            >
              <Button
                onClick={() => {
                  methods.setValue("metadata.isRecipeProSelected", true);
                  methods.setValue("metadata.isSemiSelected", true);
                  methods.setValue("isRecipeProSelected", true);
                  methods.setValue("isSemiSelected", true);
                }}
                variant="contained"
                color="secondary"
                sx={{ borderRadius: "32px" }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    color: "#fff",
                    textTransform: "none",
                  }}
                >
                  Convert to pro
                </Typography>
              </Button>
              <IconButton
                color="secondary"
                onClick={() => setOpenConvertToProModel(true)}
              >
                <HelpSharpIcon />
              </IconButton>
            </Box>
          )}
        </Grid>
      </form>
      {process.env.NODE_ENV !== "production" && (
        <ReactJson
          src={methods.watch()}
          theme="monokai"
          style={{ marginTop: 20 }}
          expand={false}
        />
      )}
    </FormProvider>
  );
};

export default RecipeForm;

const styles = {
  formRoot: {
    display: "flex",
    marginTop: 90,
    width: "80vw",
    marginLeft: "auto",
    marginRight: "auto",
  },
  tabsContainer: { width: "20vw", background: "#f5f5f5" },
  tabContentContainer: {
    display: "flex",
    width: "80vw",
    padding: 10,
    overflow: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
};
