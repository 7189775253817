import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Typography, Box, Drawer, Button } from "@mui/material";
import { userLogsCollection } from "../../utils/constants";
import { columns } from "./user-logs-columns";
import { useLogsHook } from "./use-logs-hook";
import ReactJson from "react-json-view";

const drawerStyle = {
  width: "50%", //
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: "50%",
    boxSizing: "border-box",
  },
};

const UserLogs = () => {
  const { getEntities } = useLogsHook();
  const [rowData, setRowData] = useState([]);
  const [diffrenceInValue, setDiffrenceInValue] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  const refreshData = async () => {
    await getEntities(userLogsCollection, setRowData);
  };
  const onSelectChange = (value) => {
    const filteredRow = rowData.find((item) => item.id === value[0]);
    let parsedData = {};
    if (filteredRow?.diiferenceOfRecord) {
      parsedData = JSON.parse(filteredRow?.diiferenceOfRecord);
    } else if (filteredRow?.newRecord) {
      parsedData = JSON.parse(filteredRow?.newRecord);
    }
    setDiffrenceInValue(parsedData);
    setDrawerOpen(true);
  };

  useEffect(() => {
    refreshData();
    return () => {
      setRowData([]);
    };
  }, [userLogsCollection]);

  return (
    <Box padding={2}>
      <Grid container pb={4}>
        <Grid item xs={3}>
          <Typography variant="h5" onClick={() => setDrawerOpen(true)}>
            User Logs
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ height: "80vh", width: "100%" }}>
        <DataGrid
          rows={rowData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          // checkboxSelection
          // disableRowSelectionOnClick
          disableMultipleRowSelection={true}
          onSelectionModelChange={onSelectChange}
        />
      </Box>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={drawerStyle}
      >
        <Box padding={4}>
          <Typography variant="h6">Logs Information</Typography>
          <Box style={{ width: "80%" }}>
            <ReactJson src={diffrenceInValue} theme={"monokai"} />
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default UserLogs;
