/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import { nameValidation } from "../units/units-model";
import { thumbnailUrlValidation } from "../ingredients/ingredients-model";
import dummyImage from "../../.././src/assets/Undetected-ingredient-image.png";
import {
  mixed,
  string,
  number,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from "yup";

const tags = {
  name: "Tags",
  model: "tags",
  collectionName: "tags",
  defaultObject: {
    id: null,
    name: null,
    thumbnailUrl: null,
    isKlynkUniversalTag: true,
    userGenerated: false,
    createdByUserId: null,
    createdAt: null,
    updatedAt: null,
  },
  fields: [
    {
      name: "thumbnailUrl",
      dataType: "String",
      inputFieldType: "image-uploader",
      label: "Thumbnail",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: thumbnailUrlValidation,
      listLayoutComponent: (props) => {
        const thumbnailUrl = props.row?.thumbnailUrl || dummyImage;
        return (
          <img
            src={thumbnailUrl}
            alt={props.row?.name || "Placeholder"}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        );
      },
    },
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150, 
      validation: nameValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{name}</span>
          </div>
        );
      },
    }, 
    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 300, 
      listLayoutComponent: (props) => {
        const description = props.row?.description;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{description}</span>
          </div>
        );
      },
    }, 
    {
      name: "isKlynkUniversalTag",
      dataType: "Boolean",
      inputFieldType: "checkbox",
      label: "Is Klynk Universal Tag ?",
      required: false,
      showInListLayout: true,
      listLayoutWidth: 150,
      showInEditLayout: true,
      editLayoutWidth: 6,
    },
    {
      name: "userGenerated",
      dataType: "Boolean",
      inputFieldType: "checkbox",
      label: "User Generated ?",
      required: false,
      showInListLayout: true,
      listLayoutWidth: 150,
      showInEditLayout: true,
      editLayoutWidth: 6,
    },
    // {
    //   name: "createdByUserId",
    //   dataType: "Object",
    //   inputFieldType: "autocomplete",
    //   label: "Created By",
    //   required: false,
    //   privateField: true,
    //   collectionName: "users",
    //   showInEditLayout: true,
    //   editLayoutWidth: 6,
    // },
  ],
  layout: [
    [{ name: "thumbnailUrl", width: 12 }],
    [{ name: "name", width: 6 }],
    [{ name: "description", width: 12 }],
    [{ name: "isKlynkUniversalTag", width: 6 }],
    [{ name: "userGenerated", width: 6 }],
    // [{ name: "createdByUserId", width: 6 }],
  ],
  actions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
  ],
};

export default tags;
