/* eslint-disable no-unused-vars */
import React from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const AppBarComponent = (props) => {
  const {
    formValues,
    submitRecipe,
    isDirty,
    setValue,
    formState,
    setOpenBackModal,
  } = props;
  const navigate = useNavigate();
  const onBackHandler = () => {
    if (isDirty) {
      setOpenBackModal(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#FFF",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
          height: "60px",
        }}
      >
        <Toolbar>
          <ArrowBackIcon
            sx={{ marginRight: "10px", color: "#000", cursor: "pointer" }}
            onClick={onBackHandler}
          />
          <Typography variant="h2" sx={{ flexGrow: 1 }}>
            Recipe creation tool
          </Typography>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => {
              setValue("isRecipeStatus", true);
              // submitRecipe(formValues);
            }}
            sx={{ borderRadius: "4px" }}
          >
            <Typography
              variant="body1"
              sx={{
                textTransform: "capitalize",
                px: 1,
                color: "#fff",
                borderRadius: "4px",
              }}
            >
              {formState ? "Update Published Recipe" : "Publish Recipe"}
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default AppBarComponent;
